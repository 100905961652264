import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GalagamesContext } from "../../Context/GalaraceContext";
// import coin from "../../assets/images/coin2.png";
// import foxHead from "../../assets/images/meta_mask_fox.png";
import "./LoginButton.css";

const LoginButton = ({ register }) => {
  const { pathname } = useLocation();

  const { user, openWalletModal, closeWalletModal, logOut } =
    useContext(GalagamesContext);
  const [checkDevice, setCheckDevice] = useState("");
  const navigate = useNavigate();
  const [texts, setText] = useState("");
  const [url, setUrl] = useState("");


  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();

    if (register == 'login') {
      setText("Login with Wallet")
      setUrl("https://metamask.app.link/dapp/http://testnet.galarace.ai")
    }
    else if (register == 'code') {
      setText('REGISTER TO GET YOUR AFFILIATE CODE')
      setUrl("https://metamask.app.link/dapp/http://testnet.galarace.ai")
    }
    // else if (register == 'pay') {
    //     setText('Connect Wallet to Buy')
    //     setUrl(`https://metamask.app.link/dapp/http://testnet.galarace.ai/${Id}`)
    // }
    else if (register == 'busdgame') {
      setText('Login with Wallet')
      setUrl(`https://metamask.app.link/dapp/https://galarace.ai/race`)
    }
    else if (register == 'busdstake') {
      setText('STAKE USDT (BEP20) NOW!')
      setUrl(`https://metamask.app.link/dapp/https://galarace.ai/race`)
    }
    else if (register == 'grtstake') {
      setText('STAKE GRT NOW!')
      setUrl(`https://metamask.app.link/dapp/https://galarace.ai/race`)
    }
    else {
      setText("LOGIN WITH WALLET")
    }

  }, []);

  return (
    <div>
      {checkDevice === "mobileWithoutApp" && (
        <a
          href="https://metamask.app.link/dapp/https://galarace.ai/race"
          target={"_blank"}
          className="text-decoration-none"
        >
          <button className="text-uppercase button-metamask  px-5">
            {/* <img
              style={{
                marginLeft: "-15px",
                marginRight: "10px",
                // marginTop: "5px",
              }}
              src={coin}
              alt=""
            />{" "} */}
            {texts}
          </button>
        </a>
      )}
      {checkDevice === "mobileWithApp" && (
        <>
          {!user.walletAddress || user.walletAddress === "undefined" ? (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => openWalletModal()}
            >
              {/* {" "}
              <img
                style={{
                  marginLeft: "-15px",
                  marginRight: "10px",
                  // marginTop: "5px",
                }}
                src={coin}
                alt=""
              />{" "} */}
              {texts}
            </button>
          ) : (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() =>
                navigate(
                  `${pathname.includes("clone") ? "/clone/profile" : "/profile"
                  }`
                )
              }
            >
              {/* {" "}
              <img
                style={{
                  marginLeft: "-15px",
                  marginRight: "10px",
                  // marginTop: "5px",
                }}
                src={coin}
                alt=""
              />{" "} */}
              profile
            </button>
          )}
        </>
      )}
      {checkDevice === "pcWithExtention" && (
        <>
          {!user.walletAddress || user.walletAddress === "undefined" ? (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() => openWalletModal()}
            >
              {/* {" "}
              <img
                style={{
                  marginLeft: "-15px",
                  marginRight: "10px",
                  // width: "35px",
                }}
                src={coin}
                // src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                alt=""
              /> */}
              {texts}
            </button>
          ) : (
            <button
              className="text-uppercase button-metamask px-5"
              onClick={() =>
                navigate(
                  `${pathname.includes("clone") ? "/clone/profile" : "/profile"
                  }`
                )
              }
            >
              {/* {" "}
              <img
                style={{
                  marginLeft: "-15px",
                  marginRight: "10px",
                  // width: "35px",
                }}
                src={coin}
                alt=""
              />{" "} */}
              profile
            </button>
          )}
        </>
      )}
      {checkDevice === "pcWithoutExtention" && (
        <a
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          target={"_blank"}
          className="text-decoration-none"
        >
          <button className="text-uppercase button-metamask px-5">
            {/* <img
              style={{
                marginLeft: "-15px",
                marginRight: "10px",
                // marginTop: "5px",
              }}
              src={coin}
              alt=""
            />{" "} */}
            {texts}
          </button>
        </a>
      )}
    </div>
  );
};

export default LoginButton;
