import axios from 'axios';
import React, { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AfterPayModal from './AfterPayModal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdminContext } from '../../Context/AdminContext';
import "./MakePayment.css"


const MakePayment = () => {
    const { admin } = useContext(AdminContext)
    const { name, id } = useParams()
    const [data, setData] = useState()
    const [confirm, setConfirm] = useState(0)
    const [bscurl, setbscurl] = useState('')

    const location = useLocation()
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [showBsc, setShowBsc] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(admin, "admin")
    useEffect(() => {
        if (name == "login") {
            axios.get(` https://backend.galarace.ai/api/v1/login-reward/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })

        }
        else if (name == "dsl") {
            axios.get(`https://backend.galarace.ai/api/v1/admin-payment-dsl-record/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })
        }
        else if (name == "busd") {
            axios.get(`https://backend.galarace.ai/api/v1/admin-payment-busd-record/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })

        }
        else if (name == "usd") {
            axios.get(`https://backend.galarace.ai/api/v1/admin-payment-withdraw-record/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })

        }
        else if (name == "catchit") {
            axios.get(`https://backend.galarace.ai/api/v1/admin-payment-dslcatchit-record/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })

        }
        else if (name == "fortune") {
            axios.get(`https://backend.galarace.ai/api/v1/admin-payment-fortune-record/data/${id}`)
                .then(res => {
                    setData(res?.data.result)
                })

        }

    }, [])

    const claimed = (e) => {
        e.preventDefault()
        console.log(admin?.name)
        if (name == "login") {
            axios.put(`https://backend.galarace.ai/api/v1/login-reward/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
        else if (name == "dsl") {
            axios.put(`https://backend.galarace.ai/api/v1/admin-payment-dsl-record/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
        else if (name == "busd") {
            axios.put(`https://backend.galarace.ai/api/v1/admin-payment-busd-record/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
        else if (name == "usd") {
            axios.put(`https://backend.galarace.ai/api/v1/admin-payment-withdraw-record/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
        else if (name == "catchit") {
            axios.put(`https://backend.galarace.ai/api/v1/admin-payment-dslcatchit-record/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
        else if (name == "catchit") {
            axios.put(`https://backend.galarace.ai/api/v1/admin-payment-fortune-record/${id}`, {
                bsc: bscurl,
                payBy: admin?.name
            })
                .then(res => {
                    navigate("/admin/payment")
                })
        }
    }

    const onCopy = useCallback(() => {
        // setCopied(true);

        alert("Copied");
    }, []);

    return (
        <div style={{ height: '100vh' }} >
            <div className='text-center'>
                <h2 className='mb-5 text-center text-white'>Payment Details</h2>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 offset-lg-3">
                    <div className='mb-4'>
                        <div className='payment-wrapper' >
                            {/* admin?.name */}
                            <h6 style={{ fontSize: "17px" }}><span className='text-info'>Wallet Address: </span>

                                <span className='flx me-md-1 text-white' style={{ fontSize: "15px" }}>
                                    {data?.walletAddress}
                                </span>
                                <CopyToClipboard text={data?.walletAddress} onCopy={onCopy}>

                                    <span type="span"
                                        style={{ fontSize: "15px" }}
                                        className="border bg-success rounded-0 rounded ps-2 text-white">
                                        <FontAwesomeIcon icon={faCopy} className="pe-1" />
                                    </span>
                                </CopyToClipboard>


                            </h6 >
                            <h6 style={{ fontSize: "17px" }}><span className='text-info'>Email:</span> <span style={{ fontSize: "15px" }} className='text-white'>
                                {data?.email}

                            </span></h6>
                            <h6 style={{ fontSize: "17px" }}><span className='text-info'>Amount:</span> <span style={{ fontSize: "15px" }} className='text-white'>
                                {!data?.usdToken ? (data?.dslToken ? data?.dslToken + " " + "DSL Tokens" : data?.busdToken + " " + "USDT (BEP20) Tokens") : data?.usdToken + " " + "USD"}

                            </span></h6>
                            {confirm ? <Box
                                component="form"
                                onSubmit={claimed}
                                autoComplete="off"
                                className={`bsc-input w-100 mt-4`}
                            >

                                <TextField id="outlined-basic" variant="outlined"
                                    required
                                    value={bscurl} label="BSC URL" onChange={(e) => setbscurl(e.target.value)} />
                                <div className='text-center pay w-100'>
                                    <button
                                        disabled={!bscurl.includes("bscscan.com")}
                                        style={{ fontSize: '13px', padding: "10px 35px" }} class="button-18 mt-4 m-0" role="button"
                                        type='submit'
                                    // onClick={handleShow}
                                    >CONFIRM</button>
                                </div>
                            </Box> : ""}
                            <div className='text-center pay'>
                                {!confirm ? <button style={{ fontSize: '13px', padding: "10px 35px" }} class="button-18 mt-4 m-0" role="button"
                                    onClick={() => { setConfirm(1) }}
                                // onClick={handleShow}
                                >UPDATE PAYMENT</button>
                                    :
                                    ""}
                            </div>
                            <div className='text-center mt-0 mt-md-2'>
                                <button onClick={() => navigate(-1)} style={{ backgroundColor: '#dc3545', fontSize: '13px', padding: "10px 35px" }} class="button-18 mt-3 m-0" role="button">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AfterPayModal handleShow={handleShow} handleClose={handleClose} show={show} /> */}
        </div>
    );
};

export default MakePayment;