import React, { useContext, useEffect, useState } from 'react';
import { GalagamesContext } from '../../Context/GalaraceContext';
import axios from 'axios';
import { Spinner, Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Network = () => {
    const { user } = useContext(GalagamesContext);
    const [amount, setAmount] = useState([]);
    const [info, setInfo] = useState([]);
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios
            .get("https://backend.galarace.ai/api/v1/wallet-user/all")
            .then((res) => {
                console.log(res?.data?.result, "es?.data?.result");
                setInfo(res?.data?.result)
                user?.downlines?.map(resu => {

                    for (let i = 0; i < res?.data?.result?.length; i++) {

                        if (resu == res?.data?.result[i]?.email) {
                            console.log([...amount, res.data.result[i]], "resu == res?.data?.result[i]?.email")
                            amount.push(res.data.result[i])
                        }
                    }
                })
                setLoad(true)

            });
    }, [user]);

    // const getUserWithLevel = async (users) => {
    //     let result = []

    //         users?.downlines?.map(resu => {
    //                 for (let i = 0; i < info?.length; i++) {
    //                     if (resu == info[i]?.email) {
    //                         result.push(info[i])
    //                     }
    //                 }
    //             })


    //     console.log(users, result, "users",result.length)
    //     return result
    // }

    const getUserWithLevel = (users) => {
        if (!users || !users.downlines || !info) {

            return [];
        }

        const result = [];
        users.downlines.forEach((resu) => {
            const foundUser = info.find((user) => user.email === resu);
            if (foundUser) {
                result.push(foundUser);
            }
        });

        console.log(users, result, "users", result.length);
        return result;
    };

    const renderNestedRows = (users, level) => {
        return users.map((user) => (
            <>
                <tr className="border-0">
                    <td>{user?.name}</td>
                    <td>{user?.email}</td>
                    <td>{level}</td>
                </tr>
                {getUserWithLevel(user)?.length > 0 && renderNestedRows(getUserWithLevel(user), level + 1)}
            </>
        ));
    };
    return (
        <div>
            <div className="pb-5" style={{ height: "auto" }}>
                <div>
                    <h2 className="text-white text-center mt-4 mb-2 page-title">Affiliate Network</h2>
                    <div className='network-text'>
                        <h6 style={{ cursor: "pointer" }} className='text-end text-white container mb-4' onClick={() => navigate("/affiliate-chart")}><i class="fas fa-project-diagram cursor-pointer me-2"></i>(Affiliate Chart)</h6>
                    </div>
                </div>

                <div className='container mx-auto  text-light' style={{ fontFamily: "Arial" }}>
                    <div className='row'>
                        <div className='col-12'>
                            <Table responsive className="bg-white text-dark rounded">
                                <thead>
                                    <tr className="align-items-start">
                                        <th className="busd-th">User Name</th>
                                        <th className="busd-th">Email</th>
                                        <th className="busd-th">Level</th>

                                    </tr>
                                </thead>
                                <tbody className="busd-th">
                                    <tr className="border-0 ">
                                        <td>{user?.name}</td>
                                        <td>{user?.email}</td>
                                        <td>Main</td>
                                    </tr>
                                    {load ? amount.length > 0 ? (
                                        amount?.map((res, index) => (
                                            <>
                                                <tr className="border-0 ">
                                                    <td>{res?.name}</td>
                                                    <td>{res?.email}</td>
                                                    <td>1</td>
                                                </tr>
                                                {getUserWithLevel(res)?.length > 0 && renderNestedRows(getUserWithLevel(res), 2)}
                                            </>
                                        ))
                                    ) : (
                                        <tr className="align-items-center"><th className="busd-th text-center" colSpan={4}>No data found</th></tr>
                                    ) : <Spinner></Spinner>}

                                    {/* {
                                        getUserWithLevel("res").length > 0 && getUserWithLevel("res")?.map(result => <tr className="border-0 ">
                                            <td>{result?.name}</td>
                                            <td>{result?.email}</td>
                                            <td>3</td>
                                        </tr>)
                                    } */}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Network;