import React, { useEffect, useState } from "react";
import "./BuySpoints.css";
// import earning from '../../assets/images/s39-earning.jpg'
import { Button, ButtonGroup } from "@mui/material";
import { Table } from "react-bootstrap";
import "./Earnings.css";

const Earnings = () => {
  const [show, setShow] = useState(false);

  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTabText = tabItems.find((item, index) => index === tabIndex);

  return (
    <div>
      <div className="container" style={{ height: "70vh" }}>
        <h2 className="homeProjectTitle text-center page-title my-4 mb-2 ">
          EARNINGS
        </h2>
        <p
          style={{ fontSize: "14px" }}
          className="text-white text-center mb-3 mb-md-4 mb-lg-4"
        >
          As Premium Member
        </p>

        <div className="w-100">
          <div className="px-3">
            <div className="" style={{ marginTop: "5px" }}>
              <Table className="bg-white text-dark rounded">
                <thead>
                  <tr className="align-items-start">
                    <th>Transaction ID</th>
                    <th>Earning Date</th>
                    <th>{tabIndex == 4 ? "Won" : "GRT"}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-0">
                    <td>{getTabText?.transactionID}</td>
                    <td>{getTabText?.date}</td>
                    <td>{getTabText?.transactions}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile 

     
        <div className="mt-5 d-flex align-items-center justify-content-between px-0 px-lg-5 flex-wrap">
          <div style={{ minWidth: "130px" }} className="bw">
            <button onClick={() => { setShow(show => !show) }} className="btn btn-warning mt-2 d-block w-100">Direct Sponsor</button>

            <div className={`${show ? "d-block" : "d-none"}  text-white  mt-4 px-0 px-lg-5`}>
              <p>1. Details of Earnings</p>
              <p>2. Transactions</p>
            </div>
          </div>

          <button style={{ minWidth: "130px" }} className="btn btn-success mt-2 bw">Passive Income</button>
          <button style={{ minWidth: "130px" }} className="btn btn-danger mt-2 bw">Unilevel</button>
          <button style={{ minWidth: "130px" }} className="btn btn-info mt-2 bw">Group Sales</button>
          <button style={{ minWidth: "130px" }} className="btn btn-primary mt-2 bw">Leaderboard</button>
        </div>

      </div > */}
    </div>
  );
};

export default Earnings;

const tabItems = [
  {
    name: "Direct Sponsor",
    transactionID: "11236457",
    date: "15 May 2023",
    transactions: "0.00",
    details: "Details of Direct Sponsor",
  },
  {
    name: "Passive income",
    transactionID: "11236457",
    date: "15 May 2023",
    transactions: "0.00",
    details: "Details of Passive income",
  },
  {
    name: "Unilevel",
    transactionID: "11236457",
    date: "15 May 2023",
    transactions: "0.00",
    details: "Details of Unilevel",
  },
  {
    name: "Group Sales",
    transactionID: "11236457",
    date: "15 May 2023",
    transactions: "0.00",
    details: "Details of Group Sales",
  },
  {
    name: "LeaderBoard",
    details: "Details of LeaderBoard",
  },
];
