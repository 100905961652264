import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnityContext } from 'react-unity-webgl';
import swal from 'sweetalert';
import Game3D from '../Game3D/Game3D';
import Button from '@mui/material/Button';
import Fullscreen2 from "react-fullscreen-crossbrowser";
import axios from 'axios';

//  the dog object
const dogsImg = [
    {
        img: "https://i.ibb.co/RNgCqsp/skyblue-male.png",
        probability: 0,
        strength: 75,
        gender: "Male Sky blue",
        track: "1",
    },
    {
        img: "https://i.ibb.co/vv7f4SL/skyblue-female.png",
        probability: 0,
        strength: 80,
        gender: "Female Sky blue",
        track: "2",
    },
    {
        img: "https://i.ibb.co/yR920bL/red-male.png",
        probability: 0,
        strength: 82,
        gender: "Male Red",
        track: "3",
    },
    {
        img: "https://i.ibb.co/VLLvS5f/red-female.png",
        probability: 0,
        strength: 75,
        gender: "Female Red",
        track: "4",
    },
    {
        img: "https://i.ibb.co/4pL7Wp9/orange-male.png",
        probability: 0,
        strength: 70,
        gender: "Male Yellow",
        track: "5",
    },
    {
        img: "https://i.ibb.co/P4fL15q/orange-female.png",
        probability: 0,
        strength: 71,
        gender: "Female Yellow",
        track: "6",
    },
    {
        img: "https://i.ibb.co/Vw0G5N8/navyblue-male.png",
        probability: 0,
        strength: 79,
        gender: "Male Navy",
        track: "7",
    },
    {
        img: "https://i.ibb.co/rZG1V0s/navyblue-female.png",
        probability: 0,
        strength: 78,
        gender: "Female Navy",
        track: "8",
    },
    {
        img: "https://i.ibb.co/wMHtJKy/magenta-male.png",
        probability: 0,
        strength: 76,
        gender: "Male Magenta",
        track: "9",
    },
    {
        img: "https://i.ibb.co/NZ51yD4/magenta-female.png",
        probability: 0,
        strength: 71,
        gender: "Female Magenta",
        track: "10",
    },
    {
        img: "https://i.ibb.co/mTzs5zN/lime-male.png",
        probability: 0,
        strength: 83,
        gender: "Male Lime",
        track: "11",
    },
    {
        img: "https://i.ibb.co/VND8pfj/lime-female.png",
        probability: 0,
        strength: 71,
        gender: "Female Lime",
        track: "12",
    },
    {
        img: "https://i.ibb.co/KyzjLsq/grey-male.png",
        probability: 0,
        strength: 74,
        gender: "Male White",
        track: "13",
    },
    {
        img: "https://i.ibb.co/vBzh7Zt/grey-female.png",
        probability: 0,
        strength: 78,
        gender: "Female White",
        track: "14",
    },
    {
        img: "https://i.ibb.co/D8kxx8v/green-male.png",
        probability: 0,
        strength: 77,
        gender: "Male Green",
        track: "15",
    },
    {
        img: "https://i.ibb.co/g6nJ1W4/green-female.png",
        probability: 0,
        strength: 75,
        gender: "Female Green",
        track: "16",
    },
    {
        img: "https://i.ibb.co/bR9gM7G/brown-male.png",
        probability: 0,
        strength: 78,
        gender: "Male Orange",
        track: "17",
    },
    {
        img: "https://i.ibb.co/jkK6v6z/brown-female.png",
        probability: 0,
        strength: 73,
        gender: "Female Orange",
        track: "18",
    },
    {
        img: "https://i.ibb.co/KVxW5Fy/aquagreen-male.png",
        probability: 0,
        strength: 75,
        gender: "Male Aqua Green",
        track: "19",
    },
    {
        img: "https://i.ibb.co/4mcBhBF/aquagreen-female.png",
        probability: 0,
        strength: 76,
        gender: "Female Aqua Green",
        track: "20",
    },
];

const ReplaysDemo = () => {
    const [timeOuts, setTimeouts] = useState('')
    let newTimeId;
    const [myDog, setMyDog] = useState({});
    const [speed, setSpeed] = useState([]);
    const [animationHandle, setAnimationHandle] = useState(false);
    const [isFullscreenEnabled2, setIsFullscreenEnabled2] = useState(false);
    const { unityProvider, isLoaded, loadingProgression, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/Galarace Demo varient_2 1.1 - Brotli.loader.js',
        dataUrl: "./Build/Galarace Demo varient_2 1.1 - Brotli.data.unityweb",
        frameworkUrl: "./Build/Galarace Demo varient_2 1.1 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/Galarace Demo varient_2 1.1 - Brotli.wasm.unityweb",
    });
    const navigate = useNavigate();

    const handleDestrcture = () => {
        setAnimationHandle(true);
    };

    const takeBackToStartLine = () => {
        setAnimationHandle(false)
        let element = document.getElementById("uniqe");
        let element1 = document.getElementById("uniqe2");
        let element2 = document.getElementById("uniqe3");
        let element3 = document.getElementById("uniqe4");
        let element4 = document.getElementById("uniqe5");
        let element5 = document.getElementById("uniqe6");
        let element6 = document.getElementById("uniqe7");
        let element7 = document.getElementById("uniqe8");
        let element8 = document.getElementById("uniqe9");
        let element9 = document.getElementById("uniqe10");
        let element10 = document.getElementById("uniqe11");
        let element11 = document.getElementById("uniqe12");
        let element12 = document.getElementById("uniqe13");
        let element13 = document.getElementById("uniqe14");
        let element14 = document.getElementById("uniqe15");
        let element15 = document.getElementById("uniqe16");
        let element16 = document.getElementById("uniqe17");
        let element17 = document.getElementById("uniqe18");
        let element18 = document.getElementById("uniqe19");
        let element19 = document.getElementById("uniqe20");
        element.classList.remove("img_dog");
        element1.classList.remove("img_dog");
        element1.classList.remove("timer2");
        element2.classList.remove("img_dog");
        element2.classList.remove("timer3");
        element3.classList.remove("img_dog");
        element3.classList.remove("timer4");
        element4.classList.remove("img_dog");
        element4.classList.remove("timer5");
        element5.classList.remove("img_dog");
        element5.classList.remove("timer6");
        element6.classList.remove("img_dog");
        element6.classList.remove("timer7");
        element7.classList.remove("img_dog");
        element7.classList.remove("timer8");
        element8.classList.remove("img_dog");
        element8.classList.remove("timer9");
        element9.classList.remove("img_dog");
        element9.classList.remove("timer10");
        element10.classList.remove("img_dog");
        element10.classList.remove("timer11");
        element11.classList.remove("img_dog");
        element11.classList.remove("timer12");
        element12.classList.remove("img_dog");
        element12.classList.remove("timer13");
        element13.classList.remove("img_dog");
        element13.classList.remove("timer14");
        element14.classList.remove("img_dog");
        element14.classList.remove("timer15");
        element15.classList.remove("img_dog");
        element15.classList.remove("timer16");
        element16.classList.remove("img_dog");
        element16.classList.remove("timer17");
        element17.classList.remove("img_dog");
        element17.classList.remove("timer18");
        element18.classList.remove("img_dog");
        element18.classList.remove("timer19");
        element19.classList.remove("img_dog");
        element19.classList.remove("timer20");

        void element.offsetWidth;
        void element1.offsetWidth;
        void element2.offsetWidth;
        void element3.offsetWidth;
        void element4.offsetWidth;
        void element5.offsetWidth;
        void element6.offsetWidth;
        void element7.offsetWidth;
        void element8.offsetWidth;
        void element9.offsetWidth;
        void element10.offsetWidth;
        void element11.offsetWidth;
        void element12.offsetWidth;
        void element13.offsetWidth;
        void element14.offsetWidth;
        void element15.offsetWidth;
        void element16.offsetWidth;
        void element17.offsetWidth;
        void element18.offsetWidth;
        void element19.offsetWidth;
    };

    const restartButton = () => {
        takeBackToStartLine();
        let element = document.getElementById("uniqe");
        let element1 = document.getElementById("uniqe2");
        let element2 = document.getElementById("uniqe3");
        let element3 = document.getElementById("uniqe4");
        let element4 = document.getElementById("uniqe5");
        let element5 = document.getElementById("uniqe6");
        let element6 = document.getElementById("uniqe7");
        let element7 = document.getElementById("uniqe8");
        let element8 = document.getElementById("uniqe9");
        let element9 = document.getElementById("uniqe10");
        let element10 = document.getElementById("uniqe11");
        let element11 = document.getElementById("uniqe12");
        let element12 = document.getElementById("uniqe13");
        let element13 = document.getElementById("uniqe14");
        let element14 = document.getElementById("uniqe15");
        let element15 = document.getElementById("uniqe16");
        let element16 = document.getElementById("uniqe17");
        let element17 = document.getElementById("uniqe18");
        let element18 = document.getElementById("uniqe19");
        let element19 = document.getElementById("uniqe20");

        element.classList.add("img_dog");
        element1.classList.add("img_dog");
        element1.classList.add("timer2");
        element2.classList.add("img_dog");
        element2.classList.add("timer3");
        element3.classList.add("img_dog");
        element3.classList.add("timer4");
        element4.classList.add("img_dog");
        element4.classList.add("timer5");
        element5.classList.add("img_dog");
        element5.classList.add("timer6");
        element6.classList.add("img_dog");
        element6.classList.add("timer7");
        element7.classList.add("img_dog");
        element7.classList.add("timer8");
        element8.classList.add("img_dog");
        element8.classList.add("timer9");
        element9.classList.add("img_dog");
        element9.classList.add("timer10");
        element10.classList.add("img_dog");
        element10.classList.add("timer11");
        element11.classList.add("img_dog");
        element11.classList.add("timer12");
        element12.classList.add("img_dog");
        element12.classList.add("timer13");
        element13.classList.add("img_dog");
        element13.classList.add("timer14");
        element14.classList.add("img_dog");
        element14.classList.add("timer15");
        element15.classList.add("img_dog");
        element15.classList.add("timer16");
        element16.classList.add("img_dog");
        element16.classList.add("timer17");
        element17.classList.add("img_dog");
        element17.classList.add("timer18");
        element18.classList.add("img_dog");
        element18.classList.add("timer19");
        element19.classList.add("img_dog");
        element19.classList.add("timer20");
    };

    useEffect(() => {
        axios
            .get(
                "https://backend.galarace.ai/api/v1/latestraceBUSD/demo2",  //apitestnet
                {
                    headers: {
                        authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
                    },
                }
            )
            .then((res) => {
                const allrace = res.data.data[0].race
                const trackTimeArray = [];
                allrace.forEach((entry) => {
                    const trackNumber = parseInt(entry.track);
                    trackTimeArray[trackNumber - 1] = entry.time;
                    // console.log(trackNumber - 1, "all race");
                });
                console.log(trackTimeArray, "all race");
                setSpeed(trackTimeArray)
                // setSpeed(allrace.map(times => times.time))
                // console.log(allrace.map(times => times.time), "all race")
                setMyDog(res.data.data[0].selectedDog)
            });
    }, [])


    // useEffect(() => {
    //     console.log("in the page")
    //     return () => {
    //         console.log("outside the page")
    //         myCleanupFunction();
    //     };
    // }, []);

    // const myCleanupFunction = () => {
    //     sendMessage("GameManager", "QuitGame")
    // };

    const raceReplay = () => {
        newTimeId=setTimeout(() => {
            swal({
                title: `REPLAY`,
                text: "Do you want to replay again!",
                icon: "warning",
                buttons: ["Yes", "No"],
                className: "your-footer-class",
                dangerMode: true,
            }).then(function (isConfirm) {
                if (!isConfirm) {
                    // navigate("/horsechamps",{state:{name:'replay'}}); 
                    sendMessage("GameManager", "WebReStartBtnClickedForTMP", 12);
                    sendMessage("GameManager", "SwitchCamContinuously");
                    restartButton()
                    setTimeout(() => {
                        // restartButton()
                        handleDestrcture()
                    }, 5600);
                    raceReplay()
                }
            });

        }, 48000)
    }
    useEffect(() => {

        if (isLoaded == true) {
            sendMessage("GameManager", "WebReStartBtnClickedForTMP", 12);
            sendMessage("GameManager", "SwitchCamContinuously");
            restartButton()
            setTimeout(() => {
                handleDestrcture()
            }, 6600);
            // sendMessage("GameManager" , "WebReplay" , 2);
            raceReplay();
        }

    }, [isLoaded])

    useEffect(() => {
        console.log("in the page")
        return () => {
            console.log("outside the page")
            if (unityProvider) {
                sendMessage("GameManager", "QuitGame")
                clearTimeout(newTimeId)
            }
        };
    }, [isLoaded, unityProvider]);

    return (
        <>
            <div className='container replay-container overflow-hidden'>
                <div style={{ paddingTop: "40px", position: 'relative' }} className="replays text-center">
                    {/* <h5 onClick={() => {
                    navigate("/racing", { state: { name: 'replay', autoOpen: true } });
                    sendMessage("GameManager", "QuitGame"); clearTimeout(timeOuts)
                }} className='text-white mt-2' style={{ position: 'absolute', right: 0, cursor: 'pointer' }}>X</h5> */}
                    <h1 className='text-white text-center' style={{ fontSize: "2rem" }}>REPLAY</h1>
                    <div>
                        <div style={{ margin: "0 auto" }}>
                            <Fullscreen2
                                enabled={isFullscreenEnabled2}
                                onChange={(isFullscreenEnabled2) =>
                                    setIsFullscreenEnabled2(isFullscreenEnabled2)
                                }
                                className="full-one"
                            >
                                <div
                                    className="race_area col mb-1"
                                    style={{ margin: "0" }}
                                >
                                    {/* <i onClick={() => setIsFullscreenEnabled2(true)} class="fas fa-expand"></i> */}
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe"
                                            className="img_dog start-position "
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[0]}s`,
                                                WebkitAnimationDuration: `${speed[0]}s`,
                                            }}
                                        >
                                            <img
                                                src={dogsImg[0].img}
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[0].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe2"
                                            className="img_dog start-position timer2"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[1]}s`,
                                                WebkitAnimationDuration: `${speed[1]}s`,
                                            }}
                                        >
                                            <img
                                                src={dogsImg[1].img}
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[1].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe3"
                                            className="img_dog start-position timer3"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[2]}s`,
                                                WebkitAnimationDuration: `${speed[2]}s`,
                                            }}
                                        >
                                            <img
                                                src={dogsImg[2].img}
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[2].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe4"
                                            className="img_dog start-position timer4"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[3]}s`,
                                                WebkitAnimationDuration: `${speed[3]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/VLLvS5f/red-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[3].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe5"
                                            className="img_dog start-position"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[4]}s`,
                                                WebkitAnimationDuration: `${speed[4]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/4pL7Wp9/orange-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[4].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe6"
                                            className="img_dog start-position"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[5]}s`,
                                                WebkitAnimationDuration: `${speed[5]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/P4fL15q/orange-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[5].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe7"
                                            className="img_dog start-position  timer7"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[6]}s`,
                                                WebkitAnimationDuration: `${speed[6]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/Vw0G5N8/navyblue-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[6].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe8"
                                            className="img_dog start-position timer8"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[7]}s`,
                                                WebkitAnimationDuration: `${speed[7]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/rZG1V0s/navyblue-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[7].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe9"
                                            className="img_dog start-position timer9"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[8]}s`,
                                                WebkitAnimationDuration: `${speed[8]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/wMHtJKy/magenta-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[8].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe10"
                                            className="img_dog start-position timer10"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[9]}s`,
                                                WebkitAnimationDuration: `${speed[9]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/NZ51yD4/magenta-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[9].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe11"
                                            className="img_dog start-position timer11"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[10]}s`,
                                                WebkitAnimationDuration: `${speed[10]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/mTzs5zN/lime-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[10].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe12"
                                            className="img_dog start-position timer12"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[11]}s`,
                                                WebkitAnimationDuration: `${speed[11]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/VND8pfj/lime-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[11].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe13"
                                            className="img_dog start-position timer13"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[12]}s`,
                                                WebkitAnimationDuration: `${speed[12]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/KyzjLsq/grey-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[12].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe14"
                                            className="img_dog start-position timer14"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[13]}s`,
                                                WebkitAnimationDuration: `${speed[13]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/vBzh7Zt/grey-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[13].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe15"
                                            className="img_dog start-position timer15"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[14]}s`,
                                                WebkitAnimationDuration: `${speed[14]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/D8kxx8v/green-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[14].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe16"
                                            className="img_dog start-position timer16"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[15]}s`,
                                                WebkitAnimationDuration: `${speed[15]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/g6nJ1W4/green-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[15].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe17"
                                            className="img_dog start-position timer17"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[16]}s`,
                                                WebkitAnimationDuration: `${speed[16]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/bR9gM7G/brown-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[16].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe18"
                                            className="img_dog start-position timer18"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[17]}s`,
                                                WebkitAnimationDuration: `${speed[17]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/jkK6v6z/brown-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[17].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe19"
                                            className="img_dog start-position timer19"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[18]}s`,
                                                WebkitAnimationDuration: `${speed[18]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/4mcBhBF/aquagreen-female.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[18].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="single_item">
                                        <div className="track"></div>
                                        <div
                                            id="uniqe20"
                                            className="img_dog start-position timer20"
                                            style={{
                                                animationPlayState: animationHandle
                                                    ? "running"
                                                    : "paused",
                                                animationDuration: `${speed[19]}s`,
                                                WebkitAnimationDuration: `${speed[19]}s`,
                                            }}
                                        >
                                            <img
                                                src="https://i.ibb.co/KVxW5Fy/aquagreen-male.png"
                                                alt="dog"
                                                className={
                                                    myDog.img == dogsImg[19].img
                                                        ? "dog selected-dog"
                                                        : "dog"
                                                }
                                            />
                                        </div>
                                    </div>

                                    <span className="race-line start-line"></span>
                                    <span className="race-line end-line"></span>
                                </div>
                            </Fullscreen2>
                        </div>
                        {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                        <Game3D

                            unityProvider={unityProvider}
                            isLoaded={isLoaded}
                            loadingProgression={loadingProgression}
                            sendMessage={sendMessage}
                        ></Game3D>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Button
                        onClick={() => {
                            navigate("/galarace-demo", { state: { name: 'replay', autoOpen: true } });
                            sendMessage("GameManager", "QuitGame"); clearTimeout(newTimeId)
                        }}
                        className="btn-main bg-danger lead text-white"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ReplaysDemo;