import "./App.css";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import Router from "./Routes/Router";
import WalletModal from "./Components/WalletModal/WalletModal";

function App() {
  return (
    <div className="App">
      <WalletModal />
      <RouterProvider router={Router} />
    </div>
  );
}

export default App;
