import React, { useContext, useEffect, useState } from "react";
import swal from "sweetalert"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Race.css";
import { GalagamesContext } from "../../Context/GalaraceContext";
import BusdLoginModal from "./BusdLoginModal";
import horseWithJok from "../../Assets/Images/horse-with-jok.png"
import busdLogo from "../../Assets/Images/black-binance-usd-busd-logo.png"
import horseRaceWithJok from "../../Assets/Images/horse-race-with-jok.png"
import horseRaceWithJok1 from "../../Assets/Images/horce-with-jok-racing.png"
import axios from "axios";
import MetaData from "../../Components/MetaData/MetaData";

const SelectWin = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const title = "busd"
  const { openWalletModal, user } = useContext(GalagamesContext);
  // useEffect(() => {
  //   if (!user?.email && user?.walletAddress) {
  //     navigate("/profile");
  //   }
  // }, [user]);

  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const winGala = () => {

    console.log('HELLO THERE ')

    const wrapper = document.createElement("div");
    wrapper.innerHTML = `
    <p class='text-start text-break text-warning fs-6 pt-2'>In this game, you can stake your NFT and win USDT (BEP20). How to win?</p>
    <ol class="text-start fs-6">
      <li>Login with wallet</li>
      <li>Stake your NFT from your wallet</li>
      <li>Choose Horse</li>
      <li>Start Race</li>
      <li>Win USDT (BEP20)</li>
     </ol>
     <p class="text-start text-warning fs-6">Do you want to proceed?</p>
    `;


    swal({
      // icon: "warning",
      content: wrapper,
      buttons: ["Yes", "No"],
      className: "win-busd-swal",
      dangerMode: true,
    }).then(function (isConfirm) {
      if (!isConfirm) {
      } else {
      }
    });
  }

  const winBusd = () => {

    console.log('HELLO THERE ')

    const wrapper = document.createElement("div");
    wrapper.innerHTML = `
    <p class='text-start text-break text-warning fs-6 pt-2'>In this game, you can stake USDT (BEP20) and win USDT (BEP20) 20 Times. How to win?</p>
    <ol class="text-start fs-6">
      <li>Login with wallet</li>
      <li>Stake USDT (BEP20) from your wallet </li>
      <li>Choose Horse</li>
      <li>Start Race</li>
      <li>Win USDT (BEP20)</li>
     </ol>
     <p class="text-start text-warning fs-6">Do you want to proceed?</p>
    `;


    swal({
      // icon: "warning",
      content: wrapper,
      buttons: ["Yes", "No"],
      className: "win-busd-swal",
      dangerMode: true,
    }).then(function (isConfirm) {
      if (!isConfirm) {
        navigate("/win_busd_busd_stacking")
      } else {

      }
    });
  }

  const getPlaceTimeZone = async () => {
    // const response = await fetch('https://api.ipify.org?format=json');
    const data = axios.get('https://freegeoip.app/json/')
    const timeZone = data.data.country_code;
    return timeZone;
  };

  useEffect(() => {

  }, [])


  return (
    <div className="container">
      <MetaData pageTitle={"Gala Race | Purchase NFTs from Gala race"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
      <div>
        <h3 className="text-white text-center mt-4 page-title">Race </h3>
      </div>
      <p onClick={() => navigate("/win_busd_demo")} style={{ cursor: "pointer" }} className="text-white text-center mb-0">Please click here for DEMO</p>
      <div className="text-center text-white mt-3 mb-0">
        <p> <span onClick={() => navigate("/results")} style={{ cursor: "pointer" }}>Results</span> | <span onClick={() => navigate('/race-channel', { state: { title: { title } } })} style={{ cursor: "pointer" }}>Race Channel</span> </p>
      </div>
      <div>
        {/* <div className="d-flex justify-content-center">
          <div className="text-white pt-4 win-btns">
            <Button
              className="money-button2 mb-0 text-white"
              style={{ backgroundColor: "#36698c", color: "white" }}
              onClick={() => { user?.email ? user?.walletAddress ? navigate("/win_busd_nft_stacking") : openWalletModal() : navigate("/sign-in", { state: { goBack: true } }) }}
            >
              WIN USDT (BEP20) (NFT STAKING)
            </Button>
          </div>
        </div> */}

        <div className="text-center mt-4">
          <Button
            className="money-button2 mb-0 text-white"
            style={{ backgroundColor: "#36698c", color: "white" }}
            onClick={() => navigate("/win_busd_grt_stacking")}
          >
            WIN GRT
          </Button>
        </div>

        {/* <div className="text-center mt-4">
          <Button
            className="money-button2 mb-0 text-white"
            style={{ backgroundColor: "#36698c", color: "white" }}
            onClick={() => { navigate("/win_busd_busd_stacking") }}

          >
            WIN USDT (BEP20) (USDT (BEP20) STAKING)
          </Button>
        </div> */}
        <div className="text-center mt-4">
          <button
            className="btn btn-danger px-4 back-button"
            onClick={() => navigate(-1)}
          >
            <i class="fas fa-arrow-left me-1"></i> Back
          </button>
        </div>
        {/* <div className="text-center mt-4" style={{ position: "relative" }}>
          <Button
            className="galarace-btn"
            style={{ backgroundColor: "#36698c", color: "white" }}
            onClick={()=> winGala()}
            // onClick={() => {
            //   user?.walletAddress
            //     ? navigate("/horsechamps/gamenft")
            //     : setOpen(true);
            // }}
          >
            Win GRT (NFT Staking)
          </Button>
          <img 
          //   onClick={() => {
          //   user?.walletAddress
          //     ? navigate("/horsechamps/gamenft")
          //     : setOpen(true);
          // }}
           src="/race.png" alt="Race" className="img-fluid" />
        </div> */}
        {/* <div className="text-center mt-4" style={{ position: "relative" }}>
          <Button
            className="win-grt-button"
            style={{ backgroundColor: "#606C5D", color: "white" }}
            onClick={() => { user?.email ? user?.walletAddress ? navigate("/win_busd_nft_stacking") : openWalletModal() : navigate("/sign-in", { state: { goBack: true } }) }}
          >
            WIN USDT (BEP20) (NFT STAKING)
          </Button>
          <p className="text-center text-white select-win-btn">You can stake the Galarace NFT to win USDT (BEP20).</p>
          <img
            src={horseRaceWithJok1} alt="Race" className="img-fluid" />
        </div> */}
        <div className="text-center mt-4" style={{ position: "relative" }}>
          <Button
            className="win-grt-button"
            style={{ backgroundColor: "#116A7B", color: "white" }}
            onClick={() => navigate("/win_busd_grt_stacking")}
          >
            WIN GRT
          </Button>
          <p className="text-center text-white select-win-btn">You can stake the GRT you get when you buy our memberships to win GRT.</p>
          <img
            src={horseRaceWithJok} alt="Race" className="img-fluid" />
        </div>
        {/* <div className="text-center mt-4" style={{ position: "relative" }}>
          <div className="win-busd-button-container">
            <Button
              className="win-busd-button"
              onClick={() => winBusd()}
            >
              WIN USDT (BEP20) (USDT (BEP20) STAKING)
            </Button>
            <img width={100} src={busdLogo} alt="" style={{ marginTop: "50px" }} />
          </div>
          <p className="text-center text-white select-win-btn" style={{ top: "36.5%" }}>You can stake USDT (BEP20) to win USDT (BEP20). No need membership.</p>
          <img
            onClick={() => {
              // user?.walletAddress
              //   ? navigate("/horsechamps/gamenft")
              //   : setOpen(true);
            }}
            src={horseWithJok} alt="Race" className="img-fluid" />
        </div> */}
        <BusdLoginModal handleClose={handleClose} open={open}></BusdLoginModal>
      </div>
    </div>
  );
};

export default SelectWin;
