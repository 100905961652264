import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaSearch, FaTrash } from "react-icons/fa";
import DeleteUser from "./DeleteUser";
import { Tooltip } from "@mui/material";
import Pagination from "../../Components/Pagination/Pagination";
import axios from "axios";

const Users = () => {
  const [getEmail, setGetEmail] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([])
  const navigate = useNavigate();
  const [openDialog, setOpendialog] = useState(null);

  useEffect(() => {
    // setLoading(true);
    axios
      .get("https://backend.galarace.ai/api/v1/wallet-user/all")
      .then((res) => {
        setAllUsers(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);


  console.log('Getting all users', getEmail)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const userEmail = allUsers.filter((item) => item.email?.includes(getEmail));
    setFilterUsers(userEmail);
  }, [allUsers, getEmail])

  // pagination code
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(5);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = filterUsers.slice(indexOfFirstRecord, indexOfLastRecord);
  // const nPages = Math.ceil(filterUsers.length / recordsPerPage)

    // Set the number of items per page
    const itemsPerPage = 10;
    // Use the useState hook to store the current page and an array of data for each page
    const [currentPage, setCurrentPage] = useState(1);
    const [pagedData, setPagedData] = useState([]);

    // Use the useEffect hook to paginate the data when the component mounts or the data changes
    React.useEffect(() => {
        // Calculate the number of pages needed
        const numPages = Math.ceil(allUsers.length / itemsPerPage);

        // Create an array of arrays, with each inner array containing the items for a single page
        const pages = Array.from({ length: numPages }, (_, i) =>
        allUsers.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
        );

        // Set the paged data to the appropriate page
        setPagedData(pages[currentPage - 1]);
    }, [allUsers, currentPage, itemsPerPage]);

    // Create a function to handle changing the page
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
  

  return (
    <>
      <h5 className="dashboard-title">USERS</h5>

      {/* <Search handleSearch={handleSearch} /> */}
      <div className="mb-5">
        {/* <FaSearch className="" style={{marginRight: '-20px', zIndex: '10'}}/> */}
        <input onChange={e => setGetEmail(e.target.value.toLowerCase())} value={getEmail} className="w-100 py-2 rounded ps-3 border-0" type="search" name="" id="" placeholder="Search by Email" />
      </div>
      <div className="tableNormal rounded py-3" style={{ background: '#272D47' }}>
        <div className="tableNormal ">
          <Table responsive="sm" className="text-white productDataTable">
            <thead>
              <tr>
                {/* <th className="text-left d-md-block d-none">USER ID</th> */}
                <th className="text-left productHidden">USER ID</th>
                <th className="text-start productHidden">WALLET ADDRESS</th>
                <th className="text-start ">EMAIL</th>
                <th className="text-end">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {!filterUsers ? allUsers : pagedData?.map((user) =>
                <tr className="tableRow" key={user?.USER_ID}>
                  <td className="text-left text-capitalize productHidden">
                    {user?.myReferralCode ? (
                      <div>{user?.myReferralCode}</div>
                    ) : (
                      <div>User id</div>
                    )}
                  </td>
                  <td className="text-left productHidden">

                    {user?.walletAddress ? (
                      <div>{user?.walletAddress}</div>
                    ) : (
                      <div>WalletAddress</div>
                    )}
                  </td>
                  <td className="text-left  ">
                    {user?.email ? (
                      <div>{user?.email}</div>
                    ) : (
                      <div>Email Address</div>
                    )}
                  </td>
                  <td className="action">
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="actionDiv text-left">
                        <Tooltip title="View User Details" placement="top">
                          <Link to={`/admin/userdetails/${user?._id}`}
                            className="editBtn py-2"
                          >
                            <FaEye color="#fff" size={20} />
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete User" placement="top">
                          <button
                            onClick={() => setOpendialog(user)}
                            className="deleteBtn"
                          >
                            <FaTrash color="#fff" size={15} />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </td>
                </tr>
              )
              }
              {/* {content} */}
            </tbody>
            {
              openDialog &&
              <DeleteUser openDialog={openDialog} setOpendialog={setOpendialog} ></DeleteUser>
            }
          </Table>

          <div className='text-center pt-4'>
                {/* Only show the "prev" button if we're not on the first page */}
                {currentPage > 1 && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage - 1)}>
                        Prev
                    </button>
                )}

                {/* Show the page numbers */}
                {Array.from(
                    { length: Math.ceil(allUsers.length / itemsPerPage) },
                    (_, i) => (
                        <button
                            className='btn btn-primary me-1'
                            key={i + 1}
                            onClick={() => handlePageChange(i + 1)}
                            disabled={i + 1 === currentPage}
                        >
                            {i + 1}
                        </button>
                    )
                )}

                {/* Only show the "next" button if we're not on the last page */}
                {currentPage < Math.ceil(allUsers.length / itemsPerPage) && (
                    <button
                        className='btn btn-primary me-1'
                        onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                    </button>
                )}
            </div>
          {/* <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          /> */}
        </div>
      </div>
    </>
  );
};

export default Users;
