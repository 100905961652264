import React from "react";
import "./Memberships.css";
import { useNavigate } from "react-router-dom";
import MetaData from "../../Components/MetaData/MetaData";

const Memberships = () => {
  const navigate = useNavigate();

  const packages = [
    { num: 1, name: "BEGINNER", invest: 50, earn: 150, returns: "300" },
    { num: 2, name: "PROFESSIONAL", invest: 100, earn: 300, returns: "300" },
    { num: 3, name: "MASTER", invest: 500, earn: 1500, returns: "300" },
    { num: 4, name: "GRAND MASTER", invest: 1000, earn: 3000, returns: "300" },
  ];

  return (
    <div className="container">
        <MetaData pageTitle={"Gala Race | Stake the NFT to win"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
      <h2 className="text-white text-center mt-4 mb-5 page-title">
        MEMBERSHIPS
      </h2>

      <div className="packages-container row gy-3 gx-3">
        {packages.map((eachPackage) => (
          <div className="col-md-6 col-lg-6 col-12">
            <div className="each-package text-center">
              <h4 className="package-header">{eachPackage.name}</h4>
              <h1 className="package-detail">
                Invest USD {eachPackage.invest} and <br />
                earn USD {eachPackage.earn} <br /> <span className="return-amount">{eachPackage.returns}%
                returns</span>
              </h1>
              <div className="border-bottom-pack-details"></div>
              <button
                onClick={() => navigate(`${eachPackage.num}`)}
                className="find-more-button"
              >
                FIND OUT MORE
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Memberships;
