import React, { useContext, useEffect, useState } from 'react';
import { Contract, ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import swal from 'sweetalert';
import RewardsContract from './RewardsContractBUSD';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { GalagamesContext } from '../../../Context/GalaraceContext';


const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");


function ClaimRewardsBUSD() {
    const { openWalletModal, user } = useContext(GalagamesContext);
    const [rewardAmount, setRewardAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();
    const id = ''
    const [nftId, setnftId] = useState([]);
    const locataion = useLocation()
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert("Copied!")
    }


    const [AllData, setAllData] = React.useState([])

    useEffect(() => {
        fetch('https://backend.galarace.ai/api/v1/latestraceBUSD', {
            headers: { "authorization": `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr` }
        })
            .then(res => res.json())
            .then(data => {
                setAllData(data?.data[0]);
                setRewardAmount(AllData?.race?.find(res => res.track == AllData?.selectedDog?.track).prize)
            })
        setnftId(locataion?.state?.nftId)
    }, [AllData])


    async function getAccount() {
        const provider = new ethers.providers.Web3Provider(web3.currentProvider);
        const accounts = await provider.listAccounts();
        return accounts[0];
    }

    const account = getAccount();

    async function handleClaim() {

        // await axios.post(`https://backend.galarace.ai/api/v1/payment-busd/${user?.walletAddress}`, { prizeAmount: rewardAmount })
        //     .then(async (res) => {
        //         if (res.status == 200) {
        // const Message = RewardsContract.address2;
        // const provider = new ethers.providers.Web3Provider(web3.currentProvider);
        // const signer = provider.getSigner();
        // const contract = new Contract(RewardsContract.address, RewardsContract.abi, signer);

        try {
            // const result = await contract.claimReward(rewardAmount, Message, { from: account });
            // console.log(result);
            axios.post('https://backend.galarace.ai/api/v1/admin-payment-busd-record', { walletAddress: user?.walletAddress, busdToken: rewardAmount, email: user?.email, claim: false }).then(res => {
                // axios.put(`https://backend.galarace.ai/api/v1/payment-busd-record/${id}`)
                axios.put(`https://backend.galarace.ai/api/v1/verifymint/${nftId}`, {
                    experiencePoints: 0,
                    bid: rewardAmount,
                    prizeAmount: 0

                }, {
                    headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
                }).then(() => {
                    axios
                        .put(
                            `https://backend.galarace.ai/api/v1/wallet-user/updatetoken/${user?.walletAddress}`,
                            { busdWon: rewardAmount }
                        )
                        .then(() => {
                            setSuccess(`You have successfully claimed ${rewardAmount} Gala Tokens.`);

                            const wrapper = document.createElement("div");
                            wrapper.innerHTML = `
                    <p style="color: white;">You have successfully claimed ${rewardAmount} Gala Tokens.</p>
                   
                    <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
                `
                            swal({
                                content: wrapper,
                                icon: "success",
                                button: "OK!",
                                className: "modal_class_success",
                            })
                                .then((willDelete) => {
                                    if (willDelete) {
                                        navigate('/race');
                                    }
                                });
                        });


                })
            })
            //                 setSuccess(`You have successfully claimed ${rewardAmount} Gala Tokens.`);

            //                 const wrapper = document.createElement("div");
            //                 wrapper.innerHTML = `
            //     <p style="color: white;">You have successfully claimed ${rewardAmount} Gala Tokens.</p>

            //     <p style="color: white;">You will receive it within 48 hours once our admin checks</p>
            // `
            //                 wrapper.innerHTML = `
            //     <p style="color: white;">You have successfully claimed ${rewardAmount} Gala Tokens.</p>
            //     <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 16px;">
            //         <p style="color: #007bff; margin: 0;"><a style="color: #007bff;" href="https://bscscan.com/token/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56" target="_blank">0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56</a></p>
            //     </div>
            //     <p style="color: white;">Copy the BUSD token address and import on Your Wallet to see the claimed BUSD token.</p>
            // `
            // swal({
            //     content: wrapper,
            //     icon: "success",
            //     button: "OK!",
            //     className: "modal_class_success",
            // })
            //     .then((willDelete) => {
            //         if (willDelete) {
            //             navigate('/');
            //         }
            //     });




        } catch (err) {
            setError(err.message);
        }
        // }
        // }).catch(error => {
        //     swal({
        //         title: "Attention",
        //         text: error.response.data.message,
        //         icon: "warning",
        //         button: "OK!",
        //         className: "modal_class_success",
        //     });
        // });




    }

    // const successText = (text) => {

    // }

    return (
        <div style={{ height: "80vh", paddingTop: '16px', display: 'flex', justifyContent: 'center', }}>
            <div>
                <div>
                    <h1 className="text-warning text-center text-uppercase mt-3" style={{ fontSize: "1.75rem" }}>Claim Your Winnings</h1>

                </div>

                {error && <p>{error}</p>}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '8px', marginTop: '36px' }}>
                    <p style={{ margin: '0', fontWeight: '600', color: 'white', fontSize: '20px' }}>You have won {rewardAmount} Gala Tokens</p>


                    {
                        (!user?.walletAddress || user?.walletAddress === "undefined") ?
                            <div className='menuTextButtonWallet'><button style={{ fontSize: '13px' }} class="button-metamask" role="button" onClick={openWalletModal}>
                                {/* <img className='me-2' width='18px' src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg" alt="" /> */}
                                LOGIN WITH WALLET</button>
                            </div>
                            :
                            <button className='button-metamask my-3' disabled={loading}
                                onClick={handleClaim}
                            >
                                Claim Your Gala Tokens
                            </button>
                    }

                    {/* <button onClick={() => successText('Hello World!')} className='btn btn-danger btn-sm'>Button</button> */}
                    <p style={{ color: 'white', textAlign: "center" }}>For more details about Gala Tokens,<br />Please <a href="https://backend.dsl.sg/public/dsl-whitepaper.pdf" target="_blank">click here.</a></p>
                    <div className='text-center mt-0'>
                        <button onClick={() => navigate(-1)} style={{ fontSize: '13px', padding: "10px 35px" }} class="button-18 mt-2 m-0 text-white bg-danger btn" role="button">Back</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ClaimRewardsBUSD;
