import React, { useContext, useState } from "react";
import "./Navbar.css";
import galaraceLogo from "../../Assets/Images/galarace-logo-header.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Offcanvas from "react-bootstrap/Offcanvas";
import closeIcon from "../../Assets/Images/close-icon.png";
import { Link, useNavigate } from "react-router-dom";
import LoginButton from "../LoginButton/LoginButton";
import { GalagamesContext } from "../../Context/GalaraceContext";
import axios from "axios";
import { useEffect } from "react";

import { NavLink } from "react-router-dom/dist";

const Navbar = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const navigate = useNavigate();
  const { user, setUser, setCurrentAccount, getBUSDTokenBalance } = useContext(GalagamesContext);

  const [accountddOpen, setAccountddOpen] = useState(false);

  useEffect(() => {
    user?.walletAddress && getBUSDTokenBalance()
  }, [user?.walletAddress])

  const logOut = async () => {
    axios.post(`https://backend.galarace.ai/api/v1/transaction/create`, {
      email: user?.email,
      walletAddress: user?.walletAddress,
      action: "Signed out"
    }).then(res => {
      setCurrentAccount(null);
      setUser({});
      localStorage.removeItem("galagames");
      navigate("/");
    })

  };


  return (
    <div className="navbar-container">
      <div className="navbar-main d-flex align-items-center justify-content-between">
        <img
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="navbar-logo"
          src={galaraceLogo}
          alt=""
        />

        <div className="">
          {/* Nav items for large */}
          <div className="large-nav-items-container d-none d-md-none d-lg-flex">
 
            <li>
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/race"}>RACE</NavLink>
            </li>
            <li>
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/race-channel"}>RACE CHANNEL</NavLink>
            </li>
            <li>
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/results"}>RESULTS</NavLink>
            </li>

            <li>
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/leader-board"}>LEADER BOARD</NavLink>
            </li>
            <li >
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/nfts"}>NFTs</NavLink>
            </li>
            <li>
              <NavLink style={({ isActive }) => ({
                color: isActive ? 'orange' : '#fff',
              })} to={"/memberships"}>MEMBERSHIPS</NavLink>
            </li>
            {/* <li>
              <NavLink to={"/earnings"}>EARNINGS</NavLink>
            </li> */}
            {/* {user?._id && <li>
               <NavLink to={"/dashboard"}>ACCOUNT</NavLink>
            </li>} */}

            {user?._id && (
              <div className="position-relative">
                <li
                  className="account-dropdown-label"
                  onClick={() => setAccountddOpen(!accountddOpen)}
                >
                  ACCOUNT
                  <i
                    class={`ms-1 text-white fa-solid fa-angle-${accountddOpen ? "up" : "down"
                      }`}
                  ></i>
                </li>

                {accountddOpen && (
                  <div className="sub-menu-items-container-large-dev">
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/dashboard"}>Dashboard</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/transactions"}>Transactions</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/grt"}>GRT</NavLink>
                    </li>
                    {/* <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/busd"}>USDT (BEP20)</NavLink>
                    </li> */}
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink to={"/check-nft"}>Check NFTs</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/network"}>Affiliate Network</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setAccountddOpen(!accountddOpen);
                      }}
                    >
                      <NavLink style={({ isActive }) => ({
                        color: isActive ? 'orange' : '#fff',
                      })} to={"/percentage"}>Affiliate Transactions</NavLink>
                    </li>
                  </div>
                )}
              </div>
            )}

            {user?.email ? (
              <>
                <li>
                  <button className="sign-in-button" onClick={logOut}>
                    SIGN OUT
                  </button>
                </li>
              </>
            ) : (
              <>
                {/* <li>
              <button className="sign-in-button" onClick={()=> navigate("/sign-up")}>
                SIGN UP
              </button>
            </li> */}
                <li>
                  <button
                    style={{ backgroundColor: "orange", color: "white" }}
                    className="sign-in-button"
                    onClick={() => navigate("/sign-in")}
                  >
                    GET STARTED
                  </button>
                </li>
              </>
            )}
          </div>

          {/* toggle button for small and medium */}
          <button
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="navbar-hamburger-button d-block d-md-block d-lg-none"
          >
            <MenuRoundedIcon htmlColor="black" fontSize="medium" />
          </button>
        </div>
      </div>

      {/* MENU OFF CANVAS FOR SMALL */}
      <Offcanvas
        show={showOffCanvas}
        onHide={() => setShowOffCanvas(!showOffCanvas)}
        placement="end"
        className="menu-offcanvas"
        onClick={() => setShowOffCanvas(!showOffCanvas)}
      >
        <Offcanvas.Header className="menu-offcanvas-header">
          <button
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="bg-transparent border-0 d-block ms-auto"
          >
            <img style={{ width: "20px" }} src={closeIcon} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="menu-offcanvas-body pt-0">
      
          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/race"}>Race</NavLink>
          </li>
          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/race-channel"}>Race Channel</NavLink>
          </li>
          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/results"}>Results</NavLink>
          </li>

          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/leader-board"}>Leader board</NavLink>
          </li>
          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/nfts"}>NFTs</NavLink>
          </li>
          <li
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="each-menu-item"
          >
            <NavLink style={({ isActive }) => ({
              color: isActive ? 'orange' : '#fff',
            })} to={"/memberships"}>Memberships</NavLink>
          </li>
          {/* <li className="each-menu-item">
            <NavLink to={"/earnings"}>Earnings</NavLink>
          </li> */}

          {user?.email && (
            <div>
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  setAccountddOpen(!accountddOpen);
                }}
                className="each-menu-item text-white"
              >
                Account{" "}
                <i
                  class={`text-white fa-solid fa-angle-${accountddOpen ? "up" : "down"
                    }`}
                ></i>
              </li>

              {accountddOpen && (
                <div className="sub-menu-items-container">
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/dashboard"}>Dashboard</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/transactions"}>Transactions</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/grt"}>GRT</NavLink>
                  </li>
                  {/* <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/busd"}>USDT (BEP20)</NavLink>
                  </li> */}
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/check-nft"}>Check NFTs</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/network"}>Affiliate Network</NavLink>
                  </li>
                  <li
                    onClick={() => {
                      setShowOffCanvas(!showOffCanvas);
                      setAccountddOpen(!accountddOpen);
                    }}
                    className="each-menu-item"
                  >
                    <NavLink style={({ isActive }) => ({
                      color: isActive ? 'orange' : '#fff',
                    })} to={"/percentage"}>Affiliate Transactions</NavLink>
                  </li>
                </div>
              )}
            </div>
          )}

          {user?.email ? (
            <>
              <li className="each-menu-item">
                <button className="sign-in-button" onClick={logOut}>
                  SIGN OUT
                </button>
              </li>
            </>
          ) : (
            <>
              {/* <li className="each-menu-item">
                <button
                  className="sign-in-button"
                  onClick={() => navigate("/sign-up")}
                >
                  SIGN UP
                </button>
              </li> */}
              <li className="each-menu-item">
                <button
                  style={{ backgroundColor: "orange", color: "white" }}
                  className="sign-in-button"
                  onClick={() => navigate("/sign-in")}
                >
                  SIGN IN
                </button>
              </li>
            </>
          )}

          {/* <li className="each-menu-item">
            <button className="sign-in-button" onClick={()=> navigate("/sign-up")}>
              SIGN UP
            </button>
          </li>
          <li className="each-menu-item">
            <button className="sign-in-button" onClick={()=> navigate("/sign-in")}>
                SIGN IN
            </button>
          </li> */}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Navbar;
