import React from "react";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Main from "../Components/Main/Main";
import RaceChannel from "../Pages/RaceChannel/RaceChannel";
import Results from "../Pages/Results/Results";
import Race from "../Pages/Race/Race";
import NFTs from "../Pages/NFTs/NFTs";

import Earnings from "../Pages/Earnings/Earnings";
import Profile from "../Pages/Profile/Profile";
import AllRaces from "../Pages/AllRace/AllRaces";
import AllRaceReplay from "../Pages/AllRace/AllRaceReplay";
import SelectWin from "../Pages/Race/SelectWin";
import RacingPage from "../Pages/RacingBusd/Racing";
import GameNFT from "../Pages/RacingBusd/GameNFT/GameNFT";
import Replays from "../Pages/RacingBusd/Replays/Replays";
import NFTDetails from "../Pages/NFTs/NFTDetails";
import LeaderBoard from "../Pages/LeaderBoard/LeaderBoard";
import ClaimRewardsBUSD from "../Pages/RacingBusd/Claim/ClaimRewardsBUSD";
import CloneUserLogin from "../Pages/Authentication/CloneUserLogin";
import CloneForgetPassword from "../Pages/Authentication/CloneForgetPassword";
import CloneUserSingUp from "../Pages/Authentication/CloneUserSingUp";
import NotFound from "../Pages/NotFound/NotFound";
import Prizes from "../Pages/Prizes/Prizes";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import Payment from "../Pages/Payment/Payment";
import BecomePremium from "../Pages/BecomePremium/BecomePremium";
import Memberships from "../Pages/Memberships/Memberships";
import PackageDetails from "../Pages/PackageDetails/PackageDetails";
import Transactions from "../Pages/Transactions/Transactions";
import GRT from "../Pages/GRT/GRT";
import YourEarnings from "../Pages/YourEarnings/YourEarnings";
import BuyNow from "../Pages/BuyNow/BuyNow";
import BUSD from "../Pages/BUSD/BUSD";
import WinBusdDemo from "../Pages/WinBusdDemo/WinBusdDemo";
import WinBusdNFTStacking from "../Pages/WinBusdNFTStacking/WinBusdNFTStacking";
import WinBusdGRTStacking from "../Pages/WinBusdGRTStacking/WinBusdGRTStacking";
import WinBusdBUSDStacking from "../Pages/WinBusdBUSDStacking/WinBusdBUSDStacking";
import RacingDemo1 from "../Pages/RacingBusd/RacingDemo1";
import RacingDemo2 from "../Pages/RacingBusd/RacingDemo2";
import ReplaysDemo from "../Pages/RacingBusd/Replays/ReplaysDemo";
import RacingBUSD1 from "../Pages/RacingBusd/RacingBusd1";
import ClaimRewardStakeBUSD from "../Pages/RacingBusd/Claim/ClaimRewardsStakeBUSD";
import MintedNft from "../Pages/MintedNft/MintedNft";
import RacingGRT from "../Pages/RacingBusd/RacingGRT";
import ReplaysGrt from "../Pages/RacingBusd/Replays/ReplaysGrt";
import ReplaysBusd1 from "../Pages/RacingBusd/Replays/ReplaysBusd1";
import MysteryGift from "../Pages/MysteryGift/MysteryGift";
import CheckNfts from "../Pages/CheckNfts/CheckNfts";
import Network from "../Pages/Network/Network";
import NetworkChart from "../Pages/Network/NetworkChart";
import AdminResetPassword from "../Pages/AdminLogin/AdminResetPassword";
import AdminForgotPassword from "../Pages/AdminLogin/AdminForgotPassword";
import AdminLogin from "../Pages/AdminLogin/AdminLogin";
import RequireAdmin from "../Pages/AdminLogin/RequireAdmin";
import AdminDashboard from "../Pages/AdminDashboard/AdminDashboard";
import Admins from "../Pages/Admins/Admins";
import Users from "../Pages/Users/Users";
import UserDetails from "../Pages/Users/UserDetails";
import AdminProfileUpdate from "../Pages/AdminProfileUpdate/AdminProfileUpdate";
import AdminPayment from "../Pages/AdminPayment/AdminPayment";
import AdminGRT from "../Pages/AdminGRT/AdminGRT";
import MakePayment from "../Pages/AdminPayment/MakePayment";
import CloneOtp from "../Pages/AdminLogin/CloneOtp";
import AllBanners from "../Pages/AddBanner/AllBanners";
import AdminMenership from "../Pages/AdminMembership/AdminMembership";
import GrtHistory from "../Pages/AdminGRT/GrtHistory";
import GameRewards from "../Pages/GameRewards/GameRewards";
import Percentage from "../Pages/Network/Percentage";
import ClaimRewardStakeGRT from "../Pages/RacingBusd/Claim/ClaimRewardsStakeGRT";

const time = new Date();
time.setSeconds(time.getSeconds() + 180);

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/race-channel",
        element: <RaceChannel />,
      },
      {
        path: "/replaysbusd",
        element: <Replays />,
      },
      {
        path: "/replaysgrt",
        element: <ReplaysGrt />,
      },
      {
        path: "/replaysbusd1",
        element: <ReplaysBusd1 />,
      },
      {
        path: "/replaysdemo",
        element: <ReplaysDemo />,
      },
      {
        path: "/mysterygift",
        element: <MysteryGift />,
      },
      {
        path: "/check-nft",
        element: <CheckNfts />,
      },
      // {
      //   path: "/results",
      //   element: <Results />,
      // },
      {
        path: "/results",
        element: <AllRaces />,
      },

      {
        path: "/racereplay",
        element: <AllRaceReplay />,
      },
      {
        path: "/race",
        element: <SelectWin />,
      },
      {
        path: "/win_busd_demo",
        element: <WinBusdDemo />,
      },
      {
        path: "/galarace-demo",
        element: <RacingDemo1 />,
      },
      {
        path: "/galarace-demo1",
        element: <RacingDemo2 />,
      },
      {
        path: "/galarace-busd",
        element: <RacingBUSD1 />,
      },
    
      {
        path: "/claimstakebusd",
        element: <ClaimRewardStakeBUSD />,
      },
      {
        path: "/claimstakegrt",
        element: <ClaimRewardStakeGRT />,
      },
      {
        path: "/win_busd_nft_stacking",
        element: <GameNFT />,
      },
      {
        path: "/horsechamps/gamenft",
        element: <GameNFT />,
      },
      {
        path: "/horsechamps",
        element: <RacingPage />,
      },
      {
        path: "/win_busd_grt_stacking",
        element: <WinBusdGRTStacking />,
      },
      {
        path: "/galarace-grt",
        element: <RacingGRT />,
      },
      {
        path: "/win_busd_busd_stacking",
        element: <WinBusdBUSDStacking />,
      },
  
      {
        path: "/claimbusd",
        element: <ClaimRewardsBUSD />,
      },
      {
        path: "/nfts",
        element: <NFTs />,
      },
      {
        path: "/percentage",
        element: <Percentage />,
      },
      {
        path: "/nft/:mealnId",
        element: <NFTDetails expiryTimestamp={time} />,
      },
      {
        path: "/mintednft",
        element: <MintedNft />,
      },
      {
        path: "/network",
        element: <Network />,
      },
      {
        path: "/affiliate-chart",
        element: <NetworkChart />,
      },
      {
        path: "/leader-board",
        element: <LeaderBoard />,
      },
      {
        path: "/prizes",
        element: <Prizes />,
      },
      {
        path: "/earnings",
        element: <Earnings />,
      },
      {
        path: "/dashboard",
        element: <Profile />,
      },
      // {
      //   path: "/premium_member_usd100",
      //   element: <Payment />,
      // },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/your_earnings",
        element: <YourEarnings />,
      },
      {
        path: "/grt",
        element: <GRT />,
      },
      {
        path: "/busd",
        element: <BUSD />,
      },
      {
        path: "/premium_member_usd100",
        element: <BecomePremium />,
      },
      {
        path: "/memberships",
        element: <Memberships />,
      },
      {
        path: "/memberships/:packNum",
        element: <PackageDetails />,
      },
      {
        path: "/buy_now/:packNum",
        element: <BuyNow />,
      },
      {
        path: "/sign-in",
        element: <CloneUserLogin />,
      },
      {
        path: "/sign-up",
        element: <CloneUserSingUp />,
      },
      {
        path: "/:code",
        element: <CloneUserSingUp />,
      },
      {
        path: "/forgetpassword",
        element: <CloneForgetPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      // ADMIN 
      {
        path: "/login",
        element: <AdminLogin />,
      },
      {
        path: "/admin/otp/:token",
        element: <CloneOtp />,
      },
      {
        path: "/admin-forgot-password",
        element: <AdminForgotPassword />,
      },
      {
        path: "/admin-reset-password/:token",
        element: <AdminResetPassword />,
      },

      {
        path:"/admin",
        element: (
        // <RequireAdmin>
          <AdminDashboard />
        // </RequireAdmin>
        ),
        children: [
          {
            path: "admins",
            element: <Admins />,
          },
          {
            path: "updateadmin/:id",
            element: <AdminProfileUpdate />,
          },
          {
            path: "users",
            element: <Users />,
          },
          {
            path: "userdetails/:id",
            element: <UserDetails />,
          },
          {
            path: "payment",
            element: <AdminPayment />,
          },
          {
            path: "banner",
            element: <AllBanners />,
          },
          {
            path: "game-rewards",
            element: <GameRewards />,
          },
          {
            path: "membership",
            element: <AdminMenership />,
          },
          {
            path:"payment/:name/:id",
            element:<MakePayment />,
          },
          {
            path: "grt",
            element: <AdminGRT />,
          },
          {
            path: "grt/history",
            element: <GrtHistory />,
          },
        ]
      }
    ],
  },
]);

export default Router;
