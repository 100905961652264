import React, { useState } from "react";
import faqImage from "../../../Assets/Images/faq-image.png";
import faqGirl from "../../../Assets/Images/faq-girl.jpeg";

import "./FAQ.css";

const FAQ = () => {
  const [openQue, setOpenQue] = useState(null);
  console.log(openQue, "OPEN QUE");

  const ques = [
    {
      queNo: 1,
      que: "What is Galarace.ai?",
      ans: "Galarace.ai combines Blockchain, Smart Contract, Digital Payment Token and NFTs. You can earn GRT in our platform.",
    },
    {
      queNo: 2,
      que: "What NFTs Galarace.ai provides?",
      ans: "Galarace.ai provides racing horses as NFTs. These NFTs can be staked in races. Winners can win GRT.",
    },
    {
      queNo: 3,
      que: "How to stake?",
      ans: "You can stake your NFT before each race.",
    },
    {
      queNo: 5,
      que: "What can I win?",
      ans: "You can win GRT.",
    },
    {
      queNo: 6,
      que: "How to become next millionaire?",
      ans: "Purchase our NFTs and start earning in multiple ways.",
    },
  ];

  return (
    <div className="faq-container">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-md-7 col-12 faq-img-container text-center">
            <img className="img-fluid" src={faqGirl} alt="" />
          </div>
          <div className="col-lg-7 col-md-5 col-12 faq-q-container">
            <h1 className="text-white faq-header">FAQ</h1>

            <div className="que-container">
              {ques.map((eachQue) => (
                <div className="each-question">
                  <div className="question-part">
                    <h5 className="mb-0">{eachQue?.que}</h5>
                    <button
                      onClick={() => {
                        if (openQue === eachQue.queNo) {
                          setOpenQue(null);
                        } else {
                          setOpenQue(eachQue.queNo);
                        }
                      }}
                      className="que-toggle-button"
                    >
                      <i class="fa-sharp fa-solid fa-plus"></i>
                    </button>
                  </div>
                  <div
                    className={`ans ${openQue === eachQue?.queNo ? "d-block" : "d-none"
                      }`}
                  >
                    <p>{eachQue?.ans}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
