import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import "./cloneusersauth.css";
import { GalagamesContext } from "../../Context/GalaraceContext";

const CloneUserLogin = () => {
  console.log('IN THE USER LOGIN COMPONENT')
  const [email, setEmail] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
const location=useLocation()
  const { token, setToken, setUser, user } = useContext(GalagamesContext);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (S39user?._id) {
  //     navigate("/users/dashboard", { replace: true });
  //   }
  // }, [S39user, token]);

  const handleLogin = async (e) => {
    e.preventDefault();

    const password = e.target.password.value;

    await axios
      .post("https://backend.galarace.ai/api/v1/wallet-user/signin", {
        email,
        password,

      })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data.token);
          localStorage.setItem("galagames", res.data.token);
          setUser(res.data.user);
          axios.post(`https://backend.galarace.ai/api/v1/transaction/create`, {
            email: res.data.user?.email,
            walletAddress: res.data.user?.walletAddress,
            action: "Signed in"
          })
          
          if(location?.state?.goBack){
            navigate(-1);
          }
          else{
            navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        swal({
          title: "Attention",
          text: `${error.response?.data?.message}`,
          icon: "warning",
          button: "Sign Up",
          className: "modal_class_success",
        }).then(function (isConfirm) {
          if (isConfirm) {
            navigate("/sign-up");

          }
        });
      });
  };

  return (
    <div className="handleTheLoginBody">
      <div className="container mx-auto">
        <h2 className="text-center text-warning mb-3">Sign In</h2>

        <div className="pt-5 forCard w-md-50 p-5 rounded mx-auto ">
          <p className="text-center fs-5">Sign In and claim your 10 GRTs worth USD 10.<br/>(Terms and Conditions apply)</p>
          <div className="mt-4 pt-2">
            <form onSubmit={handleLogin}>
              <InputGroup>
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-dark border-0 text-white"
                >
                  <FaEnvelope />
                </InputGroup.Text>
                <Form.Control
                  style={{ textTransform: "lowercase" }}
                  className="inputBackground"
                  placeholder="Email"
                  aria-label="Username"
                  type="email"
                  required
                  aria-describedby="basic-addon1"
                  name="email"
                  onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                />
              </InputGroup>
              <br />
              {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
              <InputGroup className="mb-3 mt-3">
                <InputGroup.Text className="bg-dark border-0 text-white">
                  <FaLock />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className="inputBackground"
                  placeholder="password"
                  type={visiblePassword ? "text" : "password"}
                  required
                  name="password"
                />
                <InputGroup.Text
                  className="bg-dark text-center border-0 cursor-pointer text-white"
                  role="button"
                  type="button"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                >
                  {visiblePassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Link
                  className="text-decoration-none forgetPass"
                  to="/forgetpassword"
                // to="/clone/forgetpassword"
                >
                  <p>Forgot password?</p>
                </Link>
              </Form.Group>
              <div className="mx-auto text-center">
                <Button
                  style={{ backgroundColor: "#f74545" }}
                  className="button-34 ps-5 pe-5 pt-2 pb-2"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
              <Form.Group className="mt-2 d-flex text-center justify-content-center" controlId="formBasicCheckbox">
                <p>Doesn't have an account?</p>
                <Link className=" forgetPass ms-1" to="/sign-up">
                  Sign up
                </Link>
                {/* <Link className=" forgetPass ms-1" to="/clone/sign-in">
                  Sign In
                </Link> */}
              </Form.Group>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloneUserLogin;
