import React, { useContext, useEffect, useState } from "react";

import { Table } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { GalagamesContext } from "../../Context/GalaraceContext";




const Percentage = () => {
  const navigate = useNavigate();
  const { user } = useContext(GalagamesContext);
  const [page1, setPage1] = useState(1);

  // console.log(saveAsDraft);
  var newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, '0');
  let mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = newDate.getFullYear();
  // let hh = newDate.getHours();
  // let min = newDate.getMinutes();
  // let ss = newDate.getSeconds();
  newDate = dd + '/' + mm + '/' + yyyy ;
  // console.log(saveAsDraft);

  var newDateTime = new Date();
  let ddt = String(newDateTime.getDate()).padStart(2, '0');
  let mmt = String(newDateTime.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyyt = newDateTime.getFullYear();
  let hht = newDateTime.getHours();
  let mint = newDateTime.getMinutes();
  let sst = newDateTime.getSeconds();
  newDateTime = ddt + '/' + mmt + '/' + yyyyt ;




  const [stackData, setStackData] = React.useState([]);
  const [stackData11, setStackData11] = React.useState([]);
  const [totalData, setTotalData] = React.useState({
    total: 0.0,
    daily: 0.0,
    balance: 0.0,
  });
  const [category, setCategory] = useState("All");
  useEffect(() => {
    window.scrollTo(0, 0);
    const getStackData = async () => {
      try {
        const res = await axios.get(
          `https://backend.galarace.ai/api/v1/percentage`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("user")}`,
            },
          }
        );
        if (category == "All") {
          setStackData(res?.data?.result.filter(res=>res?.user==user._id));
          console.log(res?.data?.result,"percentage")
          // getStakeStoreData();
        }
        else {
          setStackData(res?.data?.result);
          // setStackData(res?.data?.stacked?.filter(res => res?.tokenId == category));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getStackData();

  }, [category]);

  const getStakeStoreData = async () => {
    try {
      const res = await axios.get(
        `https://backend.superdapps.net/api/v1/stake-store/${user.email}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("user")}`,
          },
        }
      );
      if (category == "All") {
        setStackData11(res?.data?.stakeStoreData);
        console.log(res?.data?.stakeStoreData)
      }
      else {
        setStackData11(res?.data?.stakeStoreData?.filter(res => res?.tokenId == category));
   
      }
      // Handle the response data
    
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

  
    // Call the function to fetch the stake store data
    getStakeStoreData();
  }, [category]);


  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  function getTimeFromDateString(dateString) {
    const dateParts = dateString.split(" "); // Split the date and time

// Extract date and time parts
const date = dateParts[0]; // "16-10-2023"
const time = dateParts[1]; // "15:11:33"

// Parse date and time into a Date object
const [day, month, year] = date.split("-").map(Number);
const [hour, minute, second] = time.split(":").map(Number);

const fullDate = new Date(year, month - 1, day, hour, minute, second);
    const dates = new Date(fullDate);
    console.log(date,"date")
    const hours = dates.getHours().toString().padStart(2, '0');
    const minutes = dates.getMinutes().toString().padStart(2, '0');
    const seconds = dates.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  const data1 = stackData;
  const itemsPerPage1 = 10;
  const pageCount1 = Math.ceil(data1.length / itemsPerPage1);

  const handleChange1 = (event, value) => {
      window.scrollTo(0, 0);
      setPage1(value);
  };

  const getPageData = () => {
      const start = (page1 - 1) * itemsPerPage1;
      const end = start + itemsPerPage1;
      return data1.slice(start, end);
  };

  return (
    <div style={{ minHeight: "100vh", marginTop: "-86px", paddingTop: "86px" }} className="background-gradient">
   <div>
                <h2 className="text-white text-center mt-4 mb-2 page-title">    Affiliate Transactions</h2>
                {/* <h6 className='text-end text-white container mb-4'><i class="fas fa-project-diagram cursor-pointer"></i></h6> */}
            </div>


      <div className="container px-md-4 pb-3">
        {stackData?.length >0 ? (
          <div>
            <Table
            responsive
              className="text-white px-3 transaction-table align-items-start mb-0"
              bordered
            >
              <thead>
                <tr>
                  {/* <th className="d-none d-md-block">No.</th> */}
                  <th>Transaction ID</th>
                  <th>
                    Date, <span className="">Time</span>{" "}
                  </th>
                  <th>Referrer Email</th>
                  <th>level</th>
                 

                  <th>Total S-Points</th>
                  <th>Percentage</th>
                  <th>Received S-Points</th>
                
                </tr>
              </thead>
              <tbody>
                {getPageData()?.map((data) => (
                  <tr data={data} key={data._id} className="tableRow">
                    {/* <td className="d-none d-md-block">1</td> */}
                        <td>{data?.transactionId?.slice(0, 6).toUpperCase()}</td>
                        <td>
                      {/* {moment(data?.createdAt).format("DD-MM-YYYY HH:mm:ss")} */}
                      {new Date(data?.createdAt).toLocaleDateString(undefined,{ 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true 
      })}
                      {/* {newDate} {getTimeFromDateString(data?.date)}                    */}
                       </td>
                    <td>{data?.referreEmail}</td>
                    <td>{((data?.value/data?.price)*100)==10&&"1"}
                    {((data?.value/data?.price)*100)==5&&"2"}
                    {((data?.value/data?.price)*100)==3&&"3"}</td>
                    

                    <td>{data?.price}</td>
                    <td>{(data?.value/data?.price)*100}%</td>
                    <td>{data?.value}</td>
                   
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <p className="text-light text-center">No transactions to show</p>
        )}
      </div>
    
                        <div className="pagination-btn mx-auto text-center ">
                            <Pagination
                                count={pageCount1}
                                page={page1}
                                onChange={handleChange1}
                            />
                        </div>
                  
    </div>
  );
};

export default Percentage;
