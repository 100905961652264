import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import AllRace from "./AllRace";
import MetaData from "../../Components/MetaData/MetaData";

const AllRaces = () => {
  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container py-3">
      <MetaData pageTitle={"Gala Race | Invest on NFT"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="text-white text-center mt-4 page-title">Results</h2>
        </div>
        <div className="col-12 mt-md-4 mt-lg-4 mt-0">
          {/* <Tabs
            defaultActiveKey="home"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" className="mt-4" title={`GALA`}> */}
          <AllRace title={"busd"}></AllRace>
          {/* </Tab>
            
          </Tabs> */}
        </div>
      </div>
    </div>
  );
};
export default AllRaces;
