// import { Button } from '@mui/material';
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { WebSocialShare, defineCustomElement } from 'web-social-share';
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import swal from "sweetalert";
import Swal from "sweetalert2";
// import { S39GlobalContext } from "../../contexts/S39GlobalContext";
// import EmailVerificationModal from "./EmailVerificationModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Button } from "react-bootstrap";
import { GalagamesContext } from "../../Context/GalaraceContext";
import EmailVerifyModal from "../../Components/EmailVerifyModal/EmailVerifyModal";
import EmailVerificationModal from "./EmailVerificationModal";
import LoginButton from "../../Components/LoginButton/LoginButton";
import { Rating } from "@mui/material";
import animatedStar from "../../Assets/Images/animated-star.gif";
import starIcon from "../../Assets/Images/star-icon.png";
import moneyExchangeIcon from "../../Assets/Images/money-exchange-icon.png";

const Profile = ({ expiryTimestamp }) => {
  const {
    user,
    openWalletModal,
    getBalanceTestnet,
    closeWalletModal,
    logOut,
    setUserRefetch,
    userRefetch,
    busdBalance,
    getBUSDTokenBalance
  } = useContext(GalagamesContext);
  console.log("user from profile", user);
  const locations = useLocation().state;
  const [openEmail, setOpenEmail] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [CurrentFile, setCurrentFile] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailOtpVerify, setEmailOtpVerify] = useState(false);
  const [infoOpen, setInfoOpen] = useState();
  const [infoOpen1, setInfoOpen1] = useState();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { pathname } = useLocation();

  // Copy Text
  const [copyTextAffiliateCode, setCopyTextAffiliateCode] = useState("");
  const [copyTextAffiliateLink, setCopyTextAffiliateLink] = useState("");
  const [copyTextWalletAddress, setCopyTextWalletAddress] = useState("");
  const [tabIndex, setTabIndex] = useState(locations?.name || 0);

  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
    user?.walletAddress && getBUSDTokenBalance()
  }, []);

  useEffect(() => {
    user?.walletAddress && getBalanceTestnet();
    setCopyTextWalletAddress(user?.walletAddress ? user?.walletAddress : "");
    setCopyTextAffiliateCode(user?.myReferralCode ? user?.myReferralCode : "");
    // setCopyTextAffiliateLink(
    //   window.location.origin || user?.myReferralCode
    //     ? window.location.origin + "/" + user?.myReferralCode
    //     : ""
    // );
    setCopyTextAffiliateLink(
      window.location.origin || user?.myReferralCode
        ? window.location.origin + "/" + user?.myReferralCode
        : ""
    );
    // if (!user?.email || !user.email === "undefined") {
    //     // swal({
    //     //     text: "Please update your email before proceeding further. You stand to win attractive prizes monthly.",
    //     //     icon: "warning",
    //     //     button: "OK",
    //     //     dangerMode: true,
    //     //     className: "modal_class_success swal-text swal-footer",
    //     // });

    //     const wrapper = document.createElement("div");
    //     wrapper.innerHTML = `<p class='text-break text-white fs-5'>Please update your email before proceeding further. You stand to win attractive prizes monthly.</p>`;

    //     Swal.fire(
    //         {
    //             html: wrapper,
    //             icon: "warning",
    //             customClass: "modal_class_success",
    //         }
    //     )
    // }
  }, [user]);

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  const Logout = () => {
    axios.post(`https://backend.galarace.ai/api/v1/transaction/create`, {
      email: user?.email,
      walletAddress: user?.walletAddress,
      action: "Signed out"
    }).then(res => {
      logOut();
      navigate(`${pathname.includes("clone") ? "/clone" : "/"}`);
      // setOpen(false);
      closeWalletModal();
    })


    swal({
      // title: "S",
      text: "You are successfully logged out.",
      icon: "success",
      button: "OK!",
      className: "modal_class_success",
    });
  };

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  //image update
  const handleUpdateImage = async () => {
    const ImageFormData = new FormData();
    ImageFormData.append("image", CurrentFile);

    console.log(CurrentFile, "SELECTED IMG");

    if (CurrentFile) {
      await axios
        .put(
          "https://backend.galarace.ai/api/v1/wallet-user/",
          ImageFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("galagames")}`,
            },
          }
        )
        .then((res) => {
          console.log("update");
          if (res.status === 200) {
            swal({
              title: "Successful",
              text: "Image updated successfully",
              icon: "success",
              button: "OK",
              className: "modal_class_success",
            });
            setPreviewImage("");
            setCurrentFile("");
            setUserRefetch(!userRefetch);
          }
        });
    }
  };

  const handleUpdateFullName = async () => {
    const fullNameFormData = new FormData();
    fullNameFormData.append("name", fullName);
    if (fullName) {
      await axios
        .put(
          "https://backend.galarace.ai/api/v1/wallet-user/",
          fullNameFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("galagames")}`,
            },
          }
        )
        .then((res) => {
          console.log("update");
          if (res.status === 200) {
            swal({
              title: "Successful",
              text: "Name updated successfully",
              icon: "success",
              button: "OK",
              className: "modal_class_success",
            });
            setFullName("");
            setUserRefetch(!userRefetch);
          }
        });
    }
  };
  const handleUpdateEmail = async () => {
    const emailFormData = new FormData();
    emailFormData.append("email", email);
    if (email.length > 0 && email.includes("@" && ".")) {
      await axios
        .put(
          "https://backend.galarace.ai/api/v1/wallet-user/",
          emailFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("galagames")}`,
            },
          }
        )
        .then((res) => {
          console.log("update");
          if (res.status === 200) {
            setEmailOtpVerify(false);
            setOpenEmail(false);
            // swal({
            //     title: "Successful",
            //     text: "Email updated successfully",
            //     icon: "success",
            //     button: "OK",
            //     className: "modal_class_success",
            // });
            setEmail("");

            setUserRefetch(!userRefetch);
          }
        });
    }
  };

  const handleVerifyEmail = async () => {
    // check if email is valid

    if (email.length > 0 && email.includes("@" && ".")) {
      // setLoading(true);
      // setEmailVerify(true);
      console.log(email);
      await axios
        .post("https://backend.galarace.ai/api/v1/email/send", { email })
        .then((res) => {
          // console.log(res, '--data results gotten~');
          if (res.status === 200) {
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setOpenEmail(true);
            restarting(180);
            // setEmailOtpVerify(res.data.otp);
          }
        })
        .catch((err) => {
          // alert(err.response.data.message);
          console.dir(err);
          // setEmailVerify(false);
          // swal({
          //     title: "Attention",
          //     text: err.response.data.message,
          //     icon: "warning",
          //     button: "OK!",
          //     className: "modal_class_success",
          // });

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white fs-5'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      // swal({
      //     title: "Attention",
      //     text: "Please enter a valid email address",
      //     icon: "warning",
      //     button: "OK!",
      //     className: "modal_class_success",
      // });

      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  // ********************************************** walletAddress
  const copyToClipboardAffiliateCode = () => {
    copy(copyTextAffiliateCode);

    if (copyTextAffiliateCode !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>You have copied "${copyTextAffiliateCode}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  // ********************************************** Affiliate Link
  const copyToClipboardAffiliateLink = () => {
    copy(copyTextAffiliateLink);

    if (copyTextAffiliateLink !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>You have copied "${copyTextAffiliateLink}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  // ********************************************** walletAddress

  const copyToClipboardWalletAddress = () => {
    copy(copyTextWalletAddress);

    if (copyTextWalletAddress !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>You have copied "${copyTextWalletAddress}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };


  const share = {
    displayNames: true,
    config: [{
          facebook: {
            socialShareUrl: 'https://peterpeterparker.io'
          }
        },{
          twitter: {
            socialShareUrl: 'https://peterpeterparker.io'
          }
    }]
};

  return (
    <div className="bg-darkblue text-white" style={{ overflow: "hidden" }}>
      <div className="container pb-5 pt-4">
        <h2 className="text-white text-center mt-4 page-title">Dashboard</h2>
        <div className="d-flex justify-content-between align-items-center d-lg-none mb-2">
          <h4>PROFILE</h4>
          {/* <Button variant="contained" size="small" onClick={() => navigate("/mineraltoken")} style={{ marginTop: "-12px", }}>MINE MINERAL TOKENS</Button> */}
        </div>
        <div className="row g-3 g-lg-5">
          <div className="col-lg-12 col-12">
            <div className="row align-items-center pt-2 pt-lg-5 text-center pb-2 pb-lg-0 d-lg-flex d-none">
              <div className="col-lg-4 col-12">
                <div className="pb-2">
                  <p className="mb-1">Your Sponsor Code:</p>
                  <input
                    className="text-black border-0 rounded p-1"
                    value={user?.myReferralCode}
                    type="text"
                  />
                </div>
              </div>
              {/* <div className="col-lg-4 col-12">
                <div className="pb-2 d-flex align-items-end justify-content-center">

                  <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                    <p className="mb-0">Your USDT (BEP20):</p>
                    <input
                      className="text-black border-0 rounded p-1"
                      value={busdBalance == 0 || busdBalance == undefined ? "00.0000" : busdBalance}
                      type="text"
                    />
                    {/* <div className="position-relative">
                      {/* <i onClick={()=> setInfoOpen1(!infoOpen1)} style={{cursor:"pointer"}} class="text-white fa-solid fa-circle-info"></i>  

                      {infoOpen1 && (
                        <div className="gtr-info-container">
                          <p>
                            How can you use your USDT (BEP20)? <br />
                            1. Use for Play to Earn Games <br />
                            2. Use for Membership Fees (30%) <br />
                            3. Sell to Non-Members <br />
                            4. Transfer to Members
                          </p>
                        </div>
                      )}
                    </div> 

                  </div>
                  {/* <button
                    onClick={() => navigate("/busd")}
                    className="transfer-button transfer-btn-lg"
                  >
                    <img src={moneyExchangeIcon} alt="" />
                  </button> 
                </div>
              </div> */}
              <div className="col-lg-4 col-12">
                <div className="pb-2 d-flex align-items-end justify-content-center">

                  <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                    <p className="mb-0">Your GRT:</p>
                    <input
                      className="text-black border-0 rounded p-1"
                      value={user?.grtBalance == 0 || user?.grtBalance == undefined ? "00.0000" : user?.grtBalance.toFixed(2)}
                      type="text"
                    />
                    <div className="position-relative">
                      {/* <i onClick={()=> setInfoOpen(!infoOpen)} style={{cursor:"pointer"}} class="text-white fa-solid fa-circle-info"></i>  */}

                      {infoOpen && (
                        <div className="gtr-info-container">
                          <p>
                            How can you use your GRT? <br />
                            1. Use for Play to Earn Games <br />
                            2. Use for Membership Fees (30%) <br />
                            3. Sell to Non-Members <br />
                            4. Transfer to Members
                          </p>
                        </div>
                      )}
                    </div>

                  </div>
                  <button className="transfer-button transfer-btn-lg">
                    <img
                      onClick={() => navigate("/grt")}
                      src={moneyExchangeIcon}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 md-mb-5 border-md-0 ">
            {/* <div className="pb-2">
           <p className="mb-1">Your BUSD:</p> 
           <input className="text-black border-0 rounded p-1" value={"00.0000"} type="text" />
          </div> */}

            <div className="">
              <div id="login" className="mb-30 ">
                <div className="">
                  <div className="profile-and-membership">
                    {!user?.image && !previewImage && (
                      <img
                        src={"/default.jpg"}
                        className="profile-image d-flex justify-content-start"
                        alt=""
                      />
                    )}
                    {user?.image && !previewImage && (
                      <img
                        src={user?.image}
                        className="profile-image d-flex justify-content-start"
                        alt=""
                      />
                    )}
                    {previewImage && (
                      <img
                        src={previewImage}
                        className="profile-image d-flex justify-content-start"
                        alt=""
                      />
                    )}

                    <div className="membership-status-box">
                      <p className="membership-status">MEMBERSHIP STATUS</p>
                      <div className="d-flex align-items-center gap-2 mb-2">
                        <div className="star-container">
                          <img
                            className="img-fluid"
                            src={starIcon}
                            alt="star-icon"
                          />
                          <div></div>
                        </div>
                        <span>{"01"}</span>
                      </div>
                      <p className="become-premium">
                        <Link to={"/memberships"}>
                          Become our premium member and gain 2 or more stars.
                        </Link>
                      </p>
                      <p className="get-all-stars">
                        Get all your stars for mystery gifts.
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label className="">
                      Update your profile image<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        className="w-100 border py-2 cursor-pointer"
                        onChange={selectFile}
                        type="file"
                        accept="image/*"
                        name="image"
                        id="image"
                      />
                      {CurrentFile ? (
                        <button
                          disabled={!previewImage || !CurrentFile}
                          onClick={() => handleUpdateImage()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor:
                              !previewImage || !CurrentFile
                                ? "rgb(151, 145, 145)"
                                : "#FF5421",
                          }}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          disabled={!previewImage || !CurrentFile}
                          onClick={() => handleUpdateImage()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor:
                              !previewImage || !CurrentFile
                                ? "rgb(151, 145, 145)"
                                : "#FF5421",
                          }}
                        >
                          {user?.image ? "Updated" : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <label className="">
                      Update your Full Name<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        style={{ color: "black" }}
                        id="fname"
                        name="name"
                        className="w-100 form-control-mod ps-2 profile-inputs"
                        placeholder="Please update your Full Name."
                        defaultValue={user?.name ? user?.name : ""}
                        onChange={(e) => setFullName(e.target.value)}
                        type="text"
                      />
                      {fullName ? (
                        <button
                          disabled={!fullName}
                          onClick={() => handleUpdateFullName()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor: !fullName
                              ? "rgb(151, 145, 145)"
                              : "#FF5421",
                          }}
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          disabled={!fullName}
                          onClick={() => handleUpdateFullName()}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor: !fullName
                              ? "rgb(151, 145, 145)"
                              : "#FF5421",
                          }}
                        >
                          {user?.name ? "Updated" : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className=" mt-3">
                    <label className="">
                      Update your email/username<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        style={{ color: "black" }}
                        id="email"
                        name="email"
                        className="w-100 ps-2"
                        placeholder="Please update your email/username"
                        defaultValue={user?.email ? user?.email : ""}
                        type="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {email &&
                        email.match(
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                        ) ? (
                        <button
                          disabled={
                            !email ||
                            !email.match(
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                            )
                          }
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor: !email
                              ? "rgb(151, 145, 145)"
                              : "#FF5421",
                          }}
                          onClick={() => handleVerifyEmail()}
                        >
                          Verify
                        </button>
                      ) : (
                        <button
                          disabled={!email}
                          className="border-0 px-4 p-2 w-25 d-flex justify-content-center"
                          style={{
                            color: "#ffffff",
                            backgroundColor: !email
                              ? "rgb(151, 145, 145)"
                              : "#FF5421",
                          }}
                        >
                          {user?.email ? "Updated" : "Update"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center pt-3 pt-lg-0 d-lg-none d-flex">
              <div className="col-lg-4 col-12">
                <div className="pb-2">
                  <p className="mb-1">Your Sponsor Code:</p>
                  <input
                    className="text-black border-0 rounded p-1"
                    value={user?.myReferralCode}
                    type="text"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                {/* <div className="pb-2">
                  <p className="mb-0">Your USDT (BEP20):</p>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      className="text-black border-0 rounded p-1"
                      value={busdBalance == 0 || busdBalance == undefined ? "00.0000" : busdBalance}
                      type="text"
                    />
                    {/* <div className="position-relative">
                      {/* <i onClick={()=> setInfoOpen1(!infoOpen1)} style={{cursor:"pointer"}} class="text-white fa-solid fa-circle-info"></i> 

                      {infoOpen1 && (
                        <div className="gtr-info-container">
                          <p>
                            How can you use your USDT (BEP20)? <br />
                            1. Use for Play to Earn Games <br />
                            2. Use for Membership Fees (30%) <br />
                            3. Sell to Non-Members <br />
                            4. Transfer to Members
                          </p>
                        </div>
                      )}
                    </div> */}
                    {/* <button
                      onClick={() => navigate("/busd")}
                      className="transfer-button"
                    >
                      <img src={moneyExchangeIcon} alt="" />
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-4 col-12">
                <div className="pb-2">
                  <p className="mb-0">Your GRT:</p>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      className="text-black border-0 rounded p-1"
                      value={user?.grtBalance == 0 || user?.grtBalance == undefined ? "00.0000" : user?.grtBalance.toFixed(2)}
                      type="text"
                    />
                    <div className="position-relative">
                      {/* <i onClick={()=> setInfoOpen(!infoOpen)} style={{cursor:"pointer"}} class="text-white fa-solid fa-circle-info"></i>  */}

                      {infoOpen && (
                        <div className="gtr-info-container">
                          <p>
                            How can you use your GRT? <br />
                            1. Use for Play to Earn Games <br />
                            2. Use for Membership Fees (30%) <br />
                            3. Sell to Non-Members <br />
                            4. Transfer to Members
                          </p>
                        </div>
                      )}
                    </div>
                    <button className="transfer-button">
                      <img
                        onClick={() => navigate("/grt")}
                        src={moneyExchangeIcon}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 border-md-0 pb-2 pt-1 pb-lg-0 pt-lg-0">
            {/* <div className='ms-auto text-end d-none d-lg-block'>
                            <Button variant="contained" onClick={() => navigate("/mineraltoken")}>MINE MINERAL TOKENS</Button>
                        </div> */}
            <div className="">
              <div id="login" className="form-group mb-30 ">
                <div className="">
                  {/* <div className=" mb-3">
                                        <p className=''>Findexx User:<span className='text-success ps-1'>Yes</span></p>
                                        
                                    </div> */}
                  <div className=" mb-3">
                    <label className="">
                      Your Wallet Address<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        id="walletAddress"
                        name="walletAddress"
                        placeholder="Please login to see your wallet"
                        defaultValue={user?.walletAddress}
                        className="form-control text-dark rounded-start rounded-0"
                        disabled
                      />
                      {user?.walletAddress ? (
                        <button
                          className=""
                          style={{
                            backgroundColor: "#15407f",
                            border: "1px solid #15407F",
                          }}
                          type="button"
                          onClick={copyToClipboardWalletAddress}
                        >
                          <FaRegCopy size={32} color="#fff" className="p-1" />
                        </button>
                      ) : (
                        <div className="profile-wallet-login">
                          <LoginButton />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" mb-3 ">
                    <label className="">
                      Membership ID<span>*</span>
                    </label>
                    <input
                      style={{ color: "black" }}
                      className="w-100 form-control-mod ps-2 py-2"
                      placeholder="Membership ID"
                      value={user?.myReferralCode}
                      readOnly
                    />
                  </div>
                  <button
                    onClick={() => navigate("/your_earnings")}
                    className="your-earnings-button"
                  >
                    YOUR EARNINGS
                  </button>
                  <div className=" mb-3">
                    <label className="">
                      Your Affiliate Code<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        name="affiliateCode"
                        defaultValue={user?.myReferralCode}
                        className="form-control profileInput text-dark rounded-start rounded-0"
                        disabled
                      />
                      <button
                        className=""
                        style={{
                          backgroundColor: "#15407f",
                          border: "1px solid #15407F",
                        }}
                        type="button"
                        onClick={copyToClipboardAffiliateCode}
                      >
                        <FaRegCopy size={32} color="#fff" className="p-1" />
                      </button>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <label className="">
                      Your Affiliate Link<span>*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        id="AffiliateLink"
                        name="AffiliateLink"
                        value={
                          window.location.origin + "/" + user.myReferralCode
                        }
                        className="form-control text-dark rounded-start rounded-0"
                        disabled
                      />
                      <button
                        className=""
                        style={{
                          backgroundColor: "#15407f",
                          border: "1px solid #15407F",
                        }}
                        type="button"
                        onClick={copyToClipboardAffiliateLink}
                      >
                        <FaRegCopy size={32} color="#fff" className="p-1" />
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div>
                        <span className="pt-2  text-start mb-3">
                          Share Affiliate Link
                        </span>
                        <div className="d-flex gap-2 mb-3">
                          <TwitterShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <TwitterIcon size={40} round={true} />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <LinkedinIcon size={40} round={true} />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <WhatsappIcon size={40} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <FacebookIcon size={40} round={true} />
                          </FacebookShareButton>
                          <PinterestShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <PinterestIcon size={40} round={true} />
                          </PinterestShareButton>
                          <TelegramShareButton
                            url={
                              window.location.origin +
                              "/" +
                              user?.myReferralCode
                            }
                            title={`Get 10% discount at galarace.ai when you use my code.`}
                          >
                            <TelegramIcon size={40} round={true} />
                          </TelegramShareButton>
                        </div>
                      </div>
                    </div>
                    <p className="text-start">
                      Please share the affiliate code and start earning.
                    </p>
                    <web-social-share show={true} share={share}>share</web-social-share>
                    <div className="d-flex justify-content-start">
                      {/* <Button onClick={() => navigate("/mineraltoken")} className='btn rounded-0 border-0 me-2 mine-mineral' style={{background: "#15407F",}}>MINE MINERAL TOKENS</Button> */}
                      <Button
                        onClick={() => user?.email ? navigate("/") : navigate(-1)}
                        className="btn rounded-0 border-0 me-2 w-50"
                        style={{ background: "#15407F" }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        onClick={Logout}
                        className="btn rounded-0 border-0 w-50"
                        style={{ background: "#FF5421" }}
                      >
                        LOGOUT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailVerificationModal
        handleUpdateEmail={handleUpdateEmail}
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        email={email}
        seconds={seconds}
        emailOtpVerify={emailOtpVerify}
        setEmailOtpVerify={setEmailOtpVerify}
        open={openEmail}
        setOpenEmail={setOpenEmail}
      />
    </div>
  );
};

export default Profile;
