import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Paper } from '@mui/material';
import swal from 'sweetalert';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { GalagamesContext } from '../../Context/GalaraceContext';
import LoginButton from '../../Components/LoginButton/LoginButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)`

  max-width:780px!important;
  background:linear-gradient(to right bottom, #4e4c4c, #4e4c4c);
  color:white;

  @media screen and (max-width: 768px) {
text-align:center;
  }
`;

export default function BusdLoginModal({ open, handleClose }) {
    const { openWalletModal, user } = React.useContext(GalagamesContext);
    const navigate = useNavigate()
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                PaperComponent={StyledPaper}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle className='border-white border-bottom fw-light money-title text-center'>{""}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className='text-white pt-4'>
                        <p className='mb-0 fw-lighter'>Please Login to win USDT (BEP20).</p>
                        {
                            (!user?.walletAddress || user?.walletAddress === "undefined") ?
                                <p onClick={handleClose} className='my-3 text-center'> <LoginButton register={'busdgame'}></LoginButton></p>
                                :
                                <div class="text-center">
                                    <button onClick={() => { navigate('/horsechampsbusd/gamenft') }} style={{ fontSize: '13px' }} class="button-18 mb-3 px-4" role="button" >Check NFT for Game</button>
                                </div>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='justify-content-center'>
                    <Button onClick={handleClose} className='text-white bg-danger fw-bold border-danger border-1 border'>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}