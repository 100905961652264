import React from 'react';
import notFoundImg from "../../Assets/Images/404-image.png"
import "./NotFound.css"
import { useNavigate } from 'react-router-dom';

const NotFound = () => {

    const navigate = useNavigate()

    return (        
      <div className='not-found-container'>
        <div className='container d-flex align-items-center justify-content-center not-found-main'>
            <div>
                <div className='not-found-img-container'>
                    <img src={notFoundImg} className='img-fluid' alt="not found img"/>
                </div>
                <div className='text-white text-center mt-3'>
                    <p>Looks like, the page you're looking for is not exists.</p>
                    <p>Click the button to go back to home.</p>
                    <button onClick={()=>navigate("/")} className='home-button'>HOME</button>
                </div>
            </div>
        </div>
    </div>
    );
};

export default NotFound;