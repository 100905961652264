import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import "./NFTs.css";
import QRCode from "qrcode";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
// import EmailVerifyModal from "./EmailVerifyModal";
import Select from "react-select";
import { useTimer } from "react-timer-hook";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";

// import ReleatedNft from "./ReleatedNft";
// import CheckLogin from "../../Shared/CheckLogin";
import { Link } from "react-router-dom";
import { GalagamesContext } from "../../Context/GalaraceContext";
import LoginButton from "../../Components/LoginButton/LoginButton";
import Galarace04 from "../../Assets/NFTS/Galarace-04.png";
import { nfts } from "./nftsApi";

const selectOptions = [
  {
    value: "busd",
    label: "USDT (BEP20)",
    image: "/busd.jpeg",
  },
  // {
  //   value: "usdsc",
  //   label: "USDSC",
  //   image: "https://i.ibb.co/p1Vfjp0/usdsc.png",

  // },
  // {
  //   value: "dsl",
  //   label: "DSL",
  //   image: "/dsl.jpg",
  // },
];

const NFTDetails = ({ expiryTimestamp }) => {
  const [selectedOption, setSelectedOption] = useState({
    value: "busd",
    label: "USDT (BEP20)",
    image: "/busd.jpeg",
  });

  const { mealnId } = useParams();
  const [disableAfterActivation, setDisableAfterActivation] = useState(false);
  const [allAvailable, setAllAvailable] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [isClickedMint, setIsClickedMint] = useState(false);
  const [dateCount, setDateCount] = useState("");
  const [targetCount, setTargetCount] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [email1, setEmail] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [isError, setError] = useState(false);
  const [isDetails, setDetails] = useState({});
  const [otp, setOtp] = useState("");
  const [isSouvenir, setSouvenir] = useState([]);
  const [token, setToken] = useState("bnb");
  const [bnbToken, setBnbToken] = useState();
  const [dslToken, setDslToken] = useState();
  const [s39Token, setS39Token] = useState();
  const [nftData, setNftData] = useState();
  const [otpVerify, setOtpVerify] = useState();
  const [matchMint, setMatchMint] = useState("");
  const navigate = useNavigate();
  const [automint, setAutomint] = useState("");
  const [onsubDisable, setOnsubDisable] = useState(false);
  const [gotRefCode, setGotRefCode] = useState(false);
  const [gotFreeCode, setGotFreeCode] = useState(false);
  const [src, setSrc] = useState("");
  const [random, setRandom] = useState();
  const [sendMail, setSendMail] = useState("");
  const [latestNft, setLatestNft] = useState("");
  const [dataUrl, setDataUrl] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [likes, setLikes] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [affiliateWalletAddress, setAffiliateWalletAddress] = useState("");
  const [strTimeToAmPm, setStrTimeToAmPm] = useState("");
  const [endTimeToAmPm, setEndTimeToAmPm] = useState("");
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loadLike, setLoadLike] = useState(false);

  const {
    user,
    setRequestLoading,
    openWalletModal,
    mintTicketNFTTestnetBNB,
    mintTicketNFTTestnetUSDSC,
    mintTicketNFTTestnetDSL,
    mintAddressTestnet,
    signBuyFunction,
    mintTitleNFTTestnetS39,
    mintTitleNFTTestnetQuest,
    S39tokenAddressTestnet,
    QuesttokenAddressTestnet,
    USDSCtokenAddressTestnet,
    DSLtokenAddressTestnet,
    getBalanceTestnet,
    openWalletModalProfile,
    getApprove,
    mintTokenNft,
    busdBalance,
    getBUSDTokenBalance,
    userRefetch,
    setUserRefetch,
    getApproveTest,
    mintTokenNftTest,
    // contractAddressNftTokenMinting,
  } = useContext(GalagamesContext);
  const contractAddressNftTokenMinting = "0x6407698cfB5D8CFF1A30eF7c888b7483BD191F15"
  const { ethereum } = window;

  //  scroll to top
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getBalanceData = async () => {
    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length) {
      console.log(
        "🚀 ~ file: NFTDetails.js:130 ~ getBalanceData ~ accounts:",
        accounts
      );
      getBUSDTokenBalance();
    } else {
      // openWalletModal();
    }
  };
  useEffect(() => {
    getBalanceData();
  }, [user]);

  // QR code functionality
  useEffect(() => {
    const val = Math.floor(10000 + Math.random() * 900000000000);
    const staticValRan = "4816" + val;
    setRandom(staticValRan);
    getBalanceTestnet();
  }, []);

  useEffect(() => {
    QRCode.toDataURL(random?.toString()).then(setSrc);
  }, [random]);

  useEffect(() => {
    axios
      .get("https://backend.galarace.ai/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setAllAvailable(res.data);
      });
  }, []);

  //get minted nft data
  useEffect(() => {
    axios
      .get("https://backend.galarace.ai/api/v1/mint/mint-nft", {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then((res) => {
        setLatestNft(res.data[0].certificate);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`https://backend.galarace.ai/api/nft/${mealnId}`, {
  //       headers: {
  //         authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
  //       },
  //     })
  //     .then((res) => {
  //       setDetails(res.data?.nft);
  //       console.log(res.data?.nft, "nnft");
  //       setDateCount(res.data?.nft?.startDate);
  //       setTargetCount(res.data?.nft?.purchaseDate);
  //       const convertTime24to12 = (time24h) => {
  //         let time = time24h
  //           .toString()
  //           .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time24h];

  //         if (time.length > 1) {
  //           time = time.slice(1, -1);
  //           time[5] = +time[0] < 12 ? " am" : " pm";
  //           time[0] = +time[0] % 12 || 12;
  //         }
  //         return time.join("");
  //       };
  //       const st = convertTime24to12(res.data?.nft?.startTime);
  //       const et = convertTime24to12(res.data?.nft?.endTime);
  //       setStrTimeToAmPm(st);
  //       setEndTimeToAmPm(et);
  //     });
  // }, [mealnId]);

  useEffect(() => {
    const filtering = nfts?.find((items) => items.id == mealnId);
    console.log(nfts, filtering, "my nft");
    setDetails(filtering);
    // const todayDate = new Date();
    // axios
    //   .get("https://backend.galarace.ai/api/nft/all", {
    //     headers: {
    //       authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
    //     },
    //   })
    //   .then((res) => {
    //     setNftData(res.data.nft);
    //     const filtering = res.data.nft.filter((items) => items._id != mealnId);
    //     setSouvenir(filtering?.reverse());
    //   });
    setNftData(nfts);

    // setSouvenir(filtering?.reverse());
  }, [nftData, mealnId]);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=BNB", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setBnbToken(res.data.message);
        // setBnbToken(282.130);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=DSL", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setDslToken(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://dslegends.org/api/get-asset-price.php?asset=S39", {
        headers: {
          Tokenkey: `f02063004b60270f693bfefcbd8a37e91273a4290fdcc9e4ea7b0f531a9d9e64`,
        },
      })
      .then((res) => {
        setS39Token(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  const filtering = allAvailable.filter(
    (title) => title.name == isDetails.name
  );

  const availableNftOrigin =
    parseInt(isDetails?.totalNfts) - parseInt(filtering.length);

  // Re-send OTP functionality
  const { seconds, minutes, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };

  const { pathname } = useLocation();

  const [checkDevice, setCheckDevice] = useState("");

  useEffect(() => {
    const detecting = async () => {
      if (window.innerWidth < 1000 && typeof window.ethereum === "undefined") {
        console.log("mobile");
        setCheckDevice(() => "mobileWithoutApp");
      } else if (
        window.innerWidth < 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        setCheckDevice(() => "mobileWithApp");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum !== "undefined"
      ) {
        console.log("pc");
        setCheckDevice(() => "pcWithExtention");
      } else if (
        window.innerWidth > 1000 &&
        typeof window.ethereum === "undefined"
      ) {
        setCheckDevice(() => "pcWithoutExtention");
      }
    };

    detecting();
  }, []);

  const handleVerifyEmail = async (e) => {
    // check if email is valid
    setDisableAfterActivation(true);
    if (email1.length > 0 && email1.includes("@" && ".")) {
      // setLoading(true);
      setEmailVerify(true);
      await axios
        .post(
          "https://backend.galarace.ai/api/v1/verifymint/mail",
          {
            email: email1,
          },
          {
            headers: {
              authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // alert(res.data.message);
            setSendMail(res.data.email);
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setOtpVerify(res.data.otp);

            setTimeout(() => {
              setDisableAfterActivation(false);
            }, 120000);
          }
          setOpenEmail(true);
        })
        .catch((err) => {
          // alert(err.response.data.message);
          setEmailVerify(false);
          swal({
            title: "Attention",
            text: err.response.data.message,
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      swal({
        title: "Attention",
        text: "Please enter a valid email address",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  };

  const onsubAutoMint = async (e) => {
    e.preventDefault();

    const automintcode = e.target.automintcode.value;
    const email = e.target.email.value;
    // const otp = e.target.verificationCode;

    if (otp == otpVerify) {
      await axios
        .get(
          `https://backend.dsl.sg/api/v1/paymentverifyemail/dsldata/${email}`
        )
        .then((res) => {
          if (res.status === 200) {
            setMatchMint(res.data[0]);
            const user = res.data.filter((userpro) => userpro.email == email);
            if (user) {
              if (res.data[0]?.otp === automintcode) {
                swal({
                  title: "Success",
                  text: "Auto mint successful",
                  icon: "success",
                  button: "OK!",
                  className: "modal_class_success",
                });
                e.target.reset();
                setOtp("");
                setAutomint("");
                setOnsubDisable(true);
              } else {
                swal({
                  title: "Attention",
                  text: "Auto mint unsuccessful",
                  icon: "warning",
                  button: "OK!",
                  className: "modal_class_success",
                });
              }
            } else {
              swal({
                title: "Attention",
                text: "User not found",
                icon: "warning",
                button: "OK!",
                className: "modal_class_success",
              });
            }
          }
        });
    } else {
      swal({
        title: "Attention",
        text: "Auto mint unsuccessful",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    }
  };

  // Referal Code Discount
  const discountReferal = (10 / 100) * isDetails?.price;
  const disRefTwoDec = discountReferal.toFixed(2);

  // Calculation
  let totalUSD;

  if (!gotRefCode) {
    totalUSD = isDetails?.price;
  } else {
    totalUSD = isDetails?.price - disRefTwoDec;
  }

  const usdPerUSD = 0.72;
  const rsPerUSD = 80.97;
  const usd = totalUSD;
  const rs = totalUSD * rsPerUSD;

  // BNB Price 304.07
  const bnb = usd / (bnbToken ? bnbToken : 1);
  const bnbTwoDec = bnb.toFixed(4);

  // DSL Price
  // const dsl = usd / dslToken;
  const dsl = usd / (dslToken ? dslToken : 0.0108856926);
  const dslTwoDec = dsl.toFixed(4);

  // USDSC Price
  const usdsc = parseFloat(usd).toFixed(4);

  // Discount (30%)
  const discountUSD = (30 / 100) * totalUSD;
  const disUSDTwoDec = discountUSD.toFixed(2);

  // RS Discount
  const discountRs = (30 / 100) * rs;
  const disRsTwoDec = discountRs.toFixed(2);

  // USD Discount
  const discountUsd = (30 / 100) * usd;
  const disUsdTwoDec = discountUsd.toFixed(2);

  // Calculation without discounts
  const allUSDCost = isDetails?.price;

  const usdPerUSD01 = 0.72;
  const usd01 = isDetails?.price;

  // BNB Price 304.07
  const bnb01 = usd01 / (bnbToken ? bnbToken : 1);
  const bnbTwoDec01 = bnb01.toFixed(6);

  // DSL Price
  const dsl01 = usd01 / (dslToken ? dslToken : 0.0108856926);
  const dslTwoDec01 = dsl01.toFixed(4);

  // USDSC Price
  const usdsc01 = parseFloat(usd01).toFixed(4);

  // Saved prices calculation
  const savedBNB = bnbTwoDec01 - bnbTwoDec;
  const savedDSL = dslTwoDec01 - dslTwoDec;
  const savedUSDSC = usdsc01 - usdsc;
  const savedBNB4Digit = savedBNB.toFixed(6);
  const savedDSL4Digit = savedDSL.toFixed(4);
  const savedUSDSC4Digit = savedUSDSC.toFixed(4);

  let newDate = new Date();
  let dd = String(newDate.getDate()).padStart(2, "0");
  let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let min = newDate.getMinutes();
  let ss = newDate.getSeconds();
  newDate = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;
  const nftId = random?.toString();

  const postDataAfterMint = async (e) => {
    const perkStatus = false;

    const data = {
      NFTID: nftId,
      NFTWebsite: "https://celebrity.sg",
      NFTType: isDetails.type,
      NFTDetails: isDetails.description,
      NFTPerks: isDetails.perkNft,
      NFTPerksStatus: perkStatus,
      NFTCreated: newDate,
    };

    await axios
      .post("https://backend.dsl.sg/api/v1/nftdetails", data, {})
      .then((res) => {
        if (res.status === 200) {
          console.log("Successfully data passed");
        }
      })
      .catch((err) => {
        // alert(err.response.data.message);
        swal({
          title: "Attention",
          text: err.response.data.message,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  let celebrityTemplate = useRef();

  useEffect(() => {
    const dataUrlNft = celebrityTemplate.current;
    // console.log(dataUrlNft)

    setDataUrl(dataUrlNft);
  }, [isDetails]);

  /// send full details to user

  const handleSubmit = (TokeNID, dataUrlCelebrity, mintHashs) => {
    const NFTID = TokeNID;
    const address = mintAddressTestnet;
    const type = isDetails.type;
    const name = isDetails.name;
    const details = isDetails.name;
    const price = isDetails.price;
    const available = availableNftOrigin
      ? availableNftOrigin
      : isDetails.availableNft;
    const startTime = isDetails.startTime;
    const perkNft = isDetails.perkNft;
    const briefDetails = isDetails.briefDetails;
    const endTime = isDetails.endTime;
    const venue = isDetails.venue;
    const image = isDetails.image;
    const experiencePoints = isDetails.price;
    const date = isDetails.startDate;
    const endDate = isDetails.purchaseDate;
    const email = user?.email;
    const Qrcode = nftId;
    const QrcodeImg = dataUrlCelebrity;
    const walletAddress = user.walletAddress;
    const mintHash = mintHashs;

    var timeStamp = new Date();
    let dd = String(timeStamp.getDate()).padStart(2, "0");
    let mm = String(timeStamp.getMonth() + 1).padStart(2, "0");
    let yyyy = timeStamp.getFullYear();

    let hhFir = timeStamp.getHours();
    let hh;
    if (hhFir < 10) {
      hh = `0${timeStamp.getHours()}`;
    } else {
      hh = timeStamp.getHours();
    }

    let minFir = timeStamp.getMinutes();
    let min;
    if (minFir < 10) {
      min = `0${timeStamp.getMinutes()}`;
    } else {
      min = timeStamp.getMinutes();
    }

    let sfirst = timeStamp.getSeconds();
    let ss;
    if (sfirst < 10) {
      ss = `0${timeStamp.getSeconds()}`;
    } else {
      ss = timeStamp.getSeconds();
    }

    timeStamp = dd + "/" + mm + "/" + yyyy + "  " + hh + ":" + min + ":" + ss;

    console.log("44444");

    axios
      .post(
        "https://backend.galarace.ai/api/v1/verifymint/send-user",
        {
          NFTID,
          perkNft,
          Qrcode,
          QrcodeImg,
          address,
          briefDetails,
          details,
          type,
          date,
          name,
          image,
          price,
          venue,
          email,
          available,
          walletAddress,
          mintHash,
          experiencePoints,
        },
        {
          headers: {
            authorization: `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //===============//// MINTED NFT FUNCTION////===================//

  const errorRepresentationFunction = (Status) => {
    return swal({
      title: "Attention",
      text: Status.message,
      icon: Status.status,
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  const checkBalance = async () => {
    let busdBalanceNumber = parseInt(busdBalance);
    let NftPriceAmountNumber = parseInt(isDetails.price);
    console.log("🚀 ~ can proceed:", NftPriceAmountNumber, busdBalanceNumber);
    if ((busdBalanceNumber) => NftPriceAmountNumber) {
      mintCelebrityNft(
        bnbTwoDec,
        contractAddressNftTokenMinting,
        affiliateWalletAddress,
        mealnId
      );
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>You don't have enough funds to mint Nft.</p>`;
      swal({
        title: "Warning",
        content: wrapper,
        icon: "warning",
        button: "OK",
        className: "modal_class_success",
      });
    }
  };

  const mintCelebrityNft = async (
    priceByToken,
    tokenAddress,
    affiliateWalletAddress,
    mealnId
  ) => {
    if (!user?.email) {
      return swal({
        text: "Before minting please update your profile. We will send the details to you.",
        icon: "warning",
        button: true,
        dangerMode: true,
        className: "modal_class_success",
      }).then((willDelete) => {
        if (willDelete) {
          navigate(`/dashboard`);
        } else {
          console.log("ok");
        }
      });
    }

    setIsClickedMint(true);
    setRequestLoading(true);

    const data = new FormData();
    data.append("Id", isDetails.id);
    data.append("price", isDetails.price);
    data.append("tokenAddress", tokenAddress);
    data.append("refAddress", affiliateWalletAddress);
    data.append("nonce", uuidv4());
    data.append("name", isDetails.name);
    data.append("image", isDetails.image);
    data.append(
      "description",
      `Buy ${isDetails?.name} NFT to stake and win USDT (BEP20) in our PLAY TO
    EARN Blockchain Games.`
    );
    data.append("type", "horse");
    data.append("date", "09/05/2023");
    // data.append("venue", isDetails.venue);
    // data.append("token", isDetails.token);

    await axios
      .post("https://backend.galarace.ai/api/v1/mint/uri-json-nft", data, {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then(async (res) => {
        let mintObject;

        if (res.status === 200) {
          // console.log("status", res.data);
          const data1 = await signBuyFunction(
            mealnId,
            ethers.utils.parseEther(priceByToken),
            tokenAddress,
            affiliateWalletAddress,
            res.data.uri
          );
          console.log("🚀 ~ file: NFTDetails.js:774 ~ .then ~ data1:", data1);

          // if (token === "bnb") {
          //   Obj = await mintNft(data1, mealnId);
          // } else if (token === "usdsc") {
          //   console.log("enter usdsc");
          //   Obj = await mintTicketNFTTestnetUSDSC(data1);
          // } else if (token === "dsl") {
          //   Obj = await mintTicketNFTTestnetDSL(data1);
          // }

          // if (mealnId == "11") {
          //   Obj = await mintNft(data1, 0, true);
          // }
          // else {
          //   Obj = await mintNft(data1, mealnId, false);
          // }

          if (mealnId == "11") {
            let approve = await getApproveTest(3, isDetails.price.toString());
            if (approve.return) {
              mintObject = await mintTokenNftTest(data1, 1);
              if (!mintObject.return) {
                console.log(
                  "🚀 ~ file: NFTDetails.js:791 ~ .then ~ mintObject:",
                  mintObject
                );
                setRequestLoading(false);
                errorRepresentationFunction(mintObject);
                return false;
              }
            } else {
              console.log("approve ==>", approve.message);
              setRequestLoading(false);
              errorRepresentationFunction(approve);
              return false;
            }
          } else {
            let approve = await getApproveTest(3, isDetails.price.toString());
            if (approve.return) {
              var _id = parseInt(mealnId) + 1;
              mintObject = await mintTokenNftTest(data1, _id.toString());
              console.log(
                "🚀 ~ file: NFTDetails.js:804 ~ .then ~ mintObject:",
                mintObject
              );
              if (!mintObject.return) {
                setRequestLoading(false);
                errorRepresentationFunction(mintObject);
                return false;
              }
            } else {
              console.log("approve ==>", approve.message);
              setRequestLoading(false);
              errorRepresentationFunction(approve);
              return false;
            }
          }

          const data2 = {
            name: isDetails.name,
            price: isDetails.price,
            image: isDetails.image,
          };

          setTokenId(mintObject.data.IdNumber);

          await axios
            .post("https://backend.galarace.ai/api/v1/mint/save-nft", data2, {
              headers: {
                authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                setRequestLoading(false);
                axios.post(
                  `https://backend.galarace.ai/api/v1/transaction/create`,
                  {
                    email: user?.email,
                    walletAddress: user?.walletAddress,
                    action: "NFT bought",
                  }
                );
                axios.post("https://backend.galarace.ai/api/v1/makepayment", {
                  walletAddress: user?.walletAddress,
                  paymentName: "busd",
                  email: user?.email,
                  paymentAmount: isDetails.price,
                  transactionId: "4x0146s5",
                  remarks: "NFT bought",
                });
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `
                    <a href=${mintObject.data.mint_hash} target="_any" className="link_hash">${mintObject.data.mint_hash}</a>
                    <br/>
                    <p style="color: yellow;">You have successfully minted.</p>
                    <p>Use the following information to import the NFT to your wallet</p>
                    <p className="address">Contract Address: <br/> <span style="color: yellow;">${contractAddressNftTokenMinting}</span></p>
                    <p>Token ID: <br/> <span style="color: yellow;">${mintObject.data.IdNumber}</span></p>
                     `;
                swal({
                  title: "Minted",
                  content: wrapper,
                  icon: "success",
                  buttons: {
                    cancel: "Cancel",
                    Minted: "Minted",
                    confirm: "Ok",
                  },
                  className: "modal_class_success",
                }).then((willDelete) => {
                  if (willDelete == null) {
                    navigate(`/mintednft`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == "Minted") {
                    navigate(`/mintednft`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == true) {
                    navigate(`/`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else {
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  }
                });
                postDataAfterMint();
                console.log(mintObject, "resres");
                handleSubmit(
                  mintObject.data.IdNumber,
                  res.data.mintnft.image,
                  mintObject.data.mint_hash
                );
              }
            })
            .catch((err) => {
              console.log(err);
              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<a href=${mintObject.data.mint_hash} target="_any" className="link_hash">${mintObject.data.mint_hash}</a> <br/> <p>You have successfully minted but error in while saving data.</p>`;
              swal({
                title: "Warning",
                content: wrapper,
                icon: "warning",
                button: "OK",
                className: "modal_class_success",
              });
            });
        }
      })
      .catch((err) => {
        console.log("whats the error", err);
        setRequestLoading(false);
        if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === 4001) {
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        }

        return swal({
          title: "Attention",
          text: "You have cancelled!",
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      });
  };

  //===============//// MINTED Free NFT FUNCTION////===================//

  const mintCelebrityNftFree = async (
    priceByToken,
    tokenAddress,
    affiliateWalletAddress,
    mealnId
  ) => {
    if (!user.email) {
      return swal({
        text: "Before minting please update your profile. We will send the details to you.",
        icon: "warning",
        button: true,
        dangerMode: true,
        className: "modal_class_success",
      }).then((willDelete) => {
        if (willDelete) {
          navigate(`/profile`);
        } else {
          console.log("ok");
        }
      });
    }

    setIsClickedMint(true);
    setRequestLoading(true);

    const data = new FormData();
    data.append("Id", isDetails._id);
    data.append("price", 0);
    data.append("tokenAddress", tokenAddress);
    data.append("refAddress", affiliateWalletAddress);
    data.append("nonce", uuidv4());
    data.append("name", isDetails.name);
    data.append("image", isDetails.image);
    data.append("description", isDetails.description);
    data.append("type", isDetails.type);
    data.append("date", isDetails.date);
    data.append("venue", isDetails.venue);
    data.append("token", isDetails.token);

    await axios
      .post("https://backend.galarace.ai/api/v1/mint/uri-json-nft", data, {
        headers: {
          authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
        },
      })
      .then(async (res) => {
        let Obj = {};

        if (res.status === 200) {
          // console.log("status", res.data);
          const data1 = await signBuyFunction(
            mealnId,
            ethers.utils.parseEther("0"),
            tokenAddress,
            affiliateWalletAddress,
            res.data.uri
          );

          if (token === "bnb") {
            Obj = await mintTicketNFTTestnetBNB(data1);
          }

          const data2 = {
            name: isDetails.name,
            price: 0,
            image: isDetails.image,
          };

          setTokenId(Obj.ID);

          await axios
            .post("https://backend.galarace.ai/api/v1/mint/save-nft", data2, {
              headers: {
                authorization: `Bearer x4abs2zDM01DMMEgt33CrKZt86atzL3CJdExpP4`,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                const IsCodeFormData = new FormData();
                IsCodeFormData.append("isUsedCode", true);
                axios
                  .put(
                    `https://backend.galarace.ai/api/v1/user/update/${user?._id}`,
                    IsCodeFormData,
                    {
                      headers: {
                        authorization: `Bearer ${localStorage.getItem(
                          "TestGptnftToken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("update");
                    if (res.status === 200) {
                      console.log(res.data);
                    }
                  });

                setRequestLoading(false);
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `
                    <a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a>
                    <br/>
                    <p style="color: yellow;">You have successfully minted.</p>
                    <p>Use the following information to import the NFT to your wallet</p>
                    <p className="address">Contract Address: <br/> <span style="color: yellow;">${contractAddressNftTokenMinting}</span></p>
                    <p>Token ID: <br/> <span style="color: yellow;">${Obj.ID}</span></p>
                     `;
                swal({
                  title: "Minted",
                  content: wrapper,
                  icon: "success",
                  buttons: {
                    cancel: "Cancel",
                    Minted: "Minted",
                    confirm: "Ok",
                  },
                  className: "modal_class_success",
                }).then((willDelete) => {
                  if (willDelete == null) {
                    navigate(`/mintednft/${Obj.ID}/${mintAddressTestnet}`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == "Minted") {
                    navigate(`/`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else if (willDelete == true) {
                    navigate(`/`);
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  } else {
                    swal({
                      title: "Success",
                      text: "Please check your email for minted NFT details.",
                      icon: "success",
                      button: "OK!",
                      className: "modal_class_success",
                    });
                  }
                });
                postDataAfterMint();
                handleSubmit(Obj.ID, res.data.ImgCelebrity, Obj.mint_hash);
              }
            })
            .catch((err) => {
              console.log(err);
              setRequestLoading(false);
              const wrapper = document.createElement("div");
              wrapper.innerHTML = `<a href=${Obj.mint_hash} target="_any" className="link_hash">${Obj.mint_hash}</a> <br/> <p>You have successfully minted but error in while saving data.</p>`;
              swal({
                title: "Warning",
                content: wrapper,
                icon: "warning",
                button: "OK",
                className: "modal_class_success",
              });
            });
        }
      })
      .catch((err) => {
        console.log("whats the error", err);
        setRequestLoading(false);
        if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === 4001) {
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
          return swal({
            title: "Failed",
            text: "Minting Failed!",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        } else if (err.code === -32603) {
          return swal({
            title: "Failed",
            text: "Insufficient Funds",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class_success",
          });
        }

        return swal({
          title: "Attention",
          text: "You have cancelled!",
          icon: "warning",
          button: "OK",
          dangerMode: true,
          className: "modal_class_success",
        });
      });
  };

  // console.log(isDetails)

  // Referal code discount
  useEffect(() => {
    axios
      .get("https://backend.galarace.ai/api/v1/wallet-user/all", {
        headers: {
          authorization: `Bearer bIq7Olx4abs2zDM01DMMEgt33fbEe54fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4`,
        },
      })
      .then((res) => {
        setAllUsers(res.data.result);
      });
  }, [loadLike]);

  const othersRefCodes = allUsers.filter(
    (i) => i?.myReferralCode !== user?.myReferralCode
  );

  const handleAffiliateCode = (e) => {
    const refCode = othersRefCodes.find(
      (i) => i?.myReferralCode === e.target.value
    );
    setAffiliateWalletAddress(refCode?.walletAddress);
    if (refCode?.myReferralCode === e.target.value) {
      setGotRefCode(true);
    } else if (e.target.value === user?.myReferralCode) {
      ownRefcode();
    } else {
      setGotRefCode(false);
    }
  };

  const handleFreeCode = (e) => {
    const refCode = e.target.value;
    if (user?.oneTimeCode === refCode && user?.isUsedCode === false) {
      setGotFreeCode(true);
    } else {
      setGotFreeCode(false);
    }
  };

  const ownRefcode = () => {
    return swal({
      title: "Attention",
      text: "You can’t use your own code.",
      icon: "warning",
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  // Select options functionality
  const handleChoosePayment = (e) => {
    setSelectedOption(e);
    setToken(e.value);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: "#fff",
    }),

    singleValue: (provided, state) => {
      return { ...provided };
    },
  };

  const handleClick = () => {
    if (!user?.walletAddress || user?.walletAddress === "undefined") {
      openWalletModal();
    } else {
      if (isClicked) {
        // setLikes(likes - 1);
        localStorage.setItem("like", likes - 1);
      } else {
        // setLikes(likes + 1);
        localStorage.setItem("like", likes + 1);
      }
      setIsClicked(!isClicked);
      // setLikes(likess);
      // console.log(likess);
    }
  };

  // like functionality
  const likeNft = (id) => {
    if (!user?.email || user?.email === "undefined") {
      navigate("/sign-in");
    } else {
      fetch("https://backend.galarace.ai/api/nft/like", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: mealnId,
          email: user?.email,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          setLoadLike(!loadLike);
          setUserRefetch(!userRefetch);
          // if (isDetails._id == result._id) {
          //   setDetails(result);
          // } else {
          //   setDetails(isDetails);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const unlikeNft = (id) => {
    if (!user?.email || user?.email === "undefined") {
      navigate("/sign-in");
    } else {
      fetch("https://backend.galarace.ai/api/nft/unlike", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54",
        },
        body: JSON.stringify({
          nftId: mealnId,
          email: user?.email,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          setLoadLike(!loadLike);
          setUserRefetch(!userRefetch);
          // if (isDetails._id == result._id) {
          //   setDetails(result);
          // } else {
          //   setDetails(isDetails);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className="d-grid justify_items_center">
        <Container
          className="row mx-auto"
          style={{ marginTop: "15px", alignItems: "flex-start" }}
        >
          <div className="col-sm-12 col-md-6 col-lg-6 pt-2 ">
            <Box className="card_top_icon mb-2 d-flex align-items-center">
              <Typography
                className="meal_details_type_title text-gradient mb-0"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <h1 style={{ fontSize: "22px" }}>GALARACE.AI NFTs</h1>
              </Typography>
              {/* <CheckLogin register={"code"}> */}
              <Box
                className="icon_love_Dtl_box icon_love_Dtl_box_none pt-1"
                onClick={() =>
                  user?.likes?.includes(mealnId.toString())
                    ? unlikeNft(mealnId)
                    : likeNft(mealnId)
                }
              >
                <i
                  className={`fa fa-heart ${user?.likes?.includes(mealnId.toString()) &&
                    "heart-icon text-danger"
                    }`}
                ></i>
                <span className="ps-1">
                  {allUsers?.filter((res) =>
                    res?.likes?.includes(mealnId.toString())
                  ).length || 0}
                </span>
              </Box>
              {/* </CheckLogin> */}
            </Box>

            <div
              className="certificateCelebrity"
              style={{ position: "relative" }}
            >
              <img
                alt="This is GALARACE.AI NFT"
                src={isDetails?.img}
                // src={Galarace04}
                className="deteilsPageImage"
              />
              <img
                src={src}
                alt="barcode"
                className="img-fluid handleBarcode"
                ref={celebrityTemplate}
              />
              {/* <img
                src="https://i.ibb.co/Pwt1fRw/9ee03415-e591-4320-bf25-af881b8c27a6.jpg"
                alt=""
                className={`img-fluid nft-watermark3 ${
                  isClickedMint ? "d-none" : ""
                }`}
              /> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-grid pt-4 pt-lg-0">
            <Box className="pt-0 fontArial" style={{ color: "white" }}>
              {/* <Typography
                className="mb-2"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Category :<br />
                </span>{" "}
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.type || "India"}
                </span>
              </Typography> */}
              <Typography variant="subtitle2" gutterBottom component="div">
                <span className="text-primary fontArial  fontExtand">
                  Name of NFT :<br />
                </span>{" "}
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.name || "Crystal Hoof"}
                </span>
              </Typography>

              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Price of NFT (USD):
                  <Tooltip
                    title="Price of NFT is the price you purchase the NFT."
                    open={showTooltip1}
                    onOpen={() => setShowTooltip1(true)}
                    onClose={() => setShowTooltip1(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip1(!showTooltip1)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />{" "}
                </span>
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.price || "2,500"}
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Value of NFT? (USD):
                  <Tooltip
                    title="Value of NFT is the value the NFT has. You can withdraw the eligible value as USDT (BEP20)."
                    open={showTooltip2}
                    onOpen={() => setShowTooltip2(true)}
                    onClose={() => setShowTooltip2(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip2(!showTooltip2)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />{" "}
                </span>
                <span className="fw-normal fontArial  fontExtand">
                  {isDetails?.value || isDetails?.price || 17500000}
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Experience Points:
                  <Tooltip
                    title="Experience Points are accumulated when you play our blockchain games."
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowTooltip(!showTooltip)}
                    >
                      <i class="fas fa-info-circle text-white"></i>
                    </Button>
                  </Tooltip>
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {isDetails?.price || 20}
                  </span>
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Total NFTs:
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {isDetails?.totalNfts
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </span>
              </Typography>
              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial  fontExtand">
                  Available NFTs:
                  <br />
                  <span className="text-light fw-normal fontArial  fontExtand">
                    {availableNftOrigin
                      ? availableNftOrigin
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : isDetails?.totalNfts
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </span>
              </Typography>

              <Typography
                className="pt-1 fontArial  fontExtand"
                variant="subtitle2"
                gutterBottom
                component="div"
              >
                <span className="text-primary fontArial fontExtand">
                  Brief Details:
                </span>
              </Typography>
              <div
                className="spaceIssue fontArial"
                style={{ marginBottom: "0.35em" }}
              // dangerouslySetInnerHTML={{ __html: isDetails?.briefDetails }}
              >
                Buy {isDetails?.name} NFT to stake and win GRT in our
                PLAY TO EARN Blockchain Game.
              </div>

              {/* <span className="text-warning fontArial fontExtand mb-1 mt-2">
                Option 01:
              </span> */}
              <div className="diffrentBack1 ps-0">
                {/* <span className=" pt-1 text-primary mb-2 fontArial fontExtand">
                  Choose how you want to pay: <br /> 
                <span style={{ color: "cyan", fontSize: "13px" }}>
                  (You can purchase BUSD from https://www.binance.com/en)
                </span>
              </span> */}
                <div className="widthDetailsInput mt-1 pb-3">
                  <Select
                    isSearchable={false}
                    value={selectedOption}
                    onChange={handleChoosePayment}
                    options={selectOptions}
                    styles={customStyles}
                    formatOptionLabel={(option) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={option.image}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "100px",
                          }}
                          alt=""
                        />
                        <span>{option.label}</span>
                      </div>
                    )}
                  />
                </div>

                {/* <Typography
                  className="pt-1 pb-1  text-gradient"
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                >
                  <span className="spanDiscount " style={{ fontSize: "13px" }}>
                    Enjoy 10% discount if you have affiliate code.
                  </span>
                </Typography> */}

                {/* <span className="text-primary fontArial fontExtand mb-1">
                  Affiliate Code:
                </span>
                <div class="input-group mb-2 widthDetailsInput">
                  <input
                    type="text"
                    name="affiliateCode"
                    onChange={handleAffiliateCode}
                    class="form-control"
                    placeholder="Enter Affiliate Code"
                    aria-label="Enter Affiliate Code"
                    aria-describedby="button-addon2"
                  />
                  <button
                    className={
                      !gotRefCode ? "btn btn-danger" : "btn btn-success"
                    }
                    type="button"
                    id="button-addon2"
                    style={{ zIndex: "0" }}
                  >
                    {!gotRefCode ? <AiOutlineClose /> : <AiOutlineCheck />}
                  </button>
                  <h6
                    className="text-light mb-0 pb-0 font-weight-normal"
                    style={{ fontWeight: "400", fontSize: "13px" }}
                  >
                    Register and get your affiliate code. You can share with
                    your friends to earn.{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      className="text-primary d-inline"
                      onClick={() =>
                        user?.walletAddress
                          ? navigate("/profile")
                          : openWalletModalProfile()
                      }
                    >
                      Share now!
                    </span>
                  </h6>
                </div> */}

                {/* Saved bucks */}
                {gotRefCode && (
                  <div style={{ textAlign: "start" }}>
                    {token === "bnb" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedBNB4Digit} BNB
                        </span>
                      </Typography>
                    )}
                    {token === "usdsc" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedUSDSC4Digit} USDSC
                        </span>
                      </Typography>
                    )}
                    {token === "dsl" && (
                      <Typography
                        className="pt-1 pb-1  text-gradient"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        <span className="spanDiscount ">
                          You saved {savedDSL4Digit} DSL
                        </span>
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </Box>
            {/* <span className="text-warning fontArial fontExtand mb-1 mt-2">
              Option 02:
            </span>
            <div className="diffBack2">
              <span className="text-primary fontArial fontExtand mb-1 mt-2">
                Free NFT Code:
              </span>
              <div class="input-group widthDetailsInput">
                <input
                  type="text"
                  style={{
                    background: "rgb(104 81 93)",
                    border: "1.5px solid rgb(104 81 93)",
                    color: "white",
                  }}
                  name="freeCode"
                  onChange={handleFreeCode}
                  class="form-control free-nft-code-input"
                  placeholder="Enter one time Free NFT Code here"
                  aria-label="Enter one time Free NFT Code here"
                  aria-describedby="button-addon2"
                />
                <button
                  className={"btn btn-success"}
                  type="button"
                  id="button-addon2"
                  style={{ zIndex: "0" }}
                >
                  Paste
                  {
                    // <i class="fa-regular fa-paste"></i>
                  }
                </button>
              </div>
              <span
                className="text-warning fontArial "
                style={{ fontSize: "13px" }}
              >
                <Link
                  to="/profile"
                  className="text-decoration-none text-warning"
                  rel="profile"
                >
                  You can find the code in your profile.
                </Link>
              </span>
            </div> */}

            {/* <div className="dslDiscountForPayment">
              {token === "dsl" && (
                <p style={{ margin: "0" }}>
                  YOU GET DISCOUNT OF : USD {disUSDTwoDec}
                </p>
              )}
            </div> */}

            <div
              className="d-flex rpv_center details-page widthDetailsInput"
              style={{ alignItems: "flex-end", justifyContent: "start" }}
            >
              {!user?.walletAddress || user?.walletAddress === "undefined" ? (
                <div className="widthDetailsInput">
                  {checkDevice === "mobileWithoutApp" && (
                    <a
                      href={`https://metamask.app.link/dapp/http://testnet.galarace.ai/nft/${mealnId}`}
                      target={"_blank"}
                      className="text-decoration-none w-100 d-block"
                    >
                      <button
                        className="text-uppercase button-metamask  px-5 d-block w-100 me-0"
                        style={{ maxWidth: "none" }}
                      >
                        {/* <img
                         style={{
                           marginLeft: "-15px",
                           marginRight: "10px",
                           // marginTop: "5px",
                         }}
                         src={coin}
                         alt=""
                       />{" "} */}
                        MINT NOW
                      </button>
                    </a>
                  )}
                  {checkDevice === "mobileWithApp" && (
                    <>
                      {!user.walletAddress ||
                        user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase button-metamask px-5 w-100 d-block"
                          style={{ maxWidth: "none" }}
                          onClick={() =>
                            user?.email
                              ? openWalletModal()
                              : navigate("/sign-in", {
                                state: { goBack: true },
                              })
                          }
                        >
                          {/* {" "}
                         <img
                           style={{
                             marginLeft: "-15px",
                             marginRight: "10px",
                             // marginTop: "5px",
                           }}
                           src={coin}
                           alt=""
                         />{" "} */}
                          MINT NOW
                        </button>
                      ) : (
                        <button
                          className="text-uppercase button-metamask px-5 w-100 d-block"
                          onClick={() =>
                            navigate(
                              `${pathname.includes("clone")
                                ? "/clone/profile"
                                : "/profile"
                              }`
                            )
                          }
                        >
                          {/* {" "}
                         <img
                           style={{
                             marginLeft: "-15px",
                             marginRight: "10px",
                             // marginTop: "5px",
                           }}
                           src={coin}
                           alt=""
                         />{" "} */}
                          profile
                        </button>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithExtention" && (
                    <>
                      {!user.walletAddress ||
                        user.walletAddress === "undefined" ? (
                        <button
                          className="text-uppercase button-metamask px-5 w-100 d-block"
                          onClick={() =>
                            user?.email
                              ? openWalletModal()
                              : navigate("/sign-in", {
                                state: { goBack: true },
                              })
                          }
                        >
                          {/* {" "}
                         <img
                           style={{
                             marginLeft: "-15px",
                             marginRight: "10px",
                             // width: "35px",
                           }}
                           src={coin}
                           // src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                           alt=""
                         /> */}
                          MINT NOW
                        </button>
                      ) : (
                        <button
                          className="text-uppercase button-metamask px-5 w-100 d-block"
                          onClick={() =>
                            navigate(
                              `${pathname.includes("clone")
                                ? "/clone/profile"
                                : "/profile"
                              }`
                            )
                          }
                        >
                          {/* {" "}
                         <img
                           style={{
                             marginLeft: "-15px",
                             marginRight: "10px",
                             // width: "35px",
                           }}
                           src={coin}
                           alt=""
                         />{" "} */}
                          profile
                        </button>
                      )}
                    </>
                  )}
                  {checkDevice === "pcWithoutExtention" && (
                    <a
                      href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                      target={"_blank"}
                      className="text-decoration-none w-100 d-block"
                    >
                      <button className="text-uppercase button-metamask px-5 w-100 d-block">
                        {/* <img
                         style={{
                           marginLeft: "-15px",
                           marginRight: "10px",
                           // marginTop: "5px",
                         }}
                         src={coin}
                         alt=""
                       />{" "} */}
                        MINT NOW
                      </button>
                    </a>
                  )}
                </div>
              ) : (
                <>
                  {token === "bnb" && gotFreeCode === false && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl "
                      onClick={() =>
                        // mintCelebrityNft(
                        //   bnbTwoDec,
                        //   "0x0000000000000000000000000000000000000000",
                        //   affiliateWalletAddress,
                        //   mealnId
                        // )
                        checkBalance()
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `MINT NOW`}
                    </button>
                  )}
                  {token === "usdsc" && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl"
                      onClick={() =>
                        mintCelebrityNft(
                          usdsc,
                          // USDSCtokenAddressMainnet,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FOR ${usdsc} USDSC`}
                    </button>
                  )}
                  {token === "dsl" && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl"
                      onClick={() =>
                        mintCelebrityNft(
                          dslTwoDec,
                          // DSLtokenAddressMainnet,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FOR ${dslTwoDec} DSl`}
                    </button>
                  )}
                  {gotFreeCode === true && (
                    <button
                      disabled={
                        (availableNftOrigin
                          ? availableNftOrigin
                          : isDetails.availableNft) < 1
                      }
                      className="card_button button_dtl"
                      onClick={() =>
                        mintCelebrityNft(
                          bnbTwoDec,
                          contractAddressNftTokenMinting,
                          affiliateWalletAddress,
                          mealnId
                        )
                      }
                      href="#!"
                    >
                      {(availableNftOrigin
                        ? availableNftOrigin
                        : isDetails.availableNft) < 1
                        ? "No Nft available"
                        : `BUY THIS NFT FREE`}
                    </button>
                  )}
                </>
              )}
              <br />
            </div>
            <div style={{ color: "#ffffff", textAlign: "start" }}>
              {token === "bnb" && gotFreeCode === false && (
                <div style={{ display: "flex", gap: "10px" }}>
                  {" "}
                  <p style={{ margin: "0" }}>
                    You need to pay {bnbTwoDec} USDT (BEP20) and gas fees.
                  </p>
                </div>
              )}

              <p style={{ margin: "0" }}>Your USDT (BEP20) Balance {busdBalance} </p>

              {token === "usdsc" && (
                <p style={{ margin: "0" }}>You need to pay {usdsc} USDSC</p>
              )}
              {gotFreeCode === true && <p>You need to pay gas fees only.</p>}
            </div>
            <div className="my-3">
              <Button
                variant="danger"
                className="px-3"
                onClick={() => navigate(-1)}
              >
                <span className="">Back</span>
              </Button>
            </div>
          </div>
        </Container>
        {/* <Container>
          <h3
            className="text-white text-start mb-0 mt-5 mb-3 d-grid justify_items_center"
            style={{ fontFamily: "system-ui" }}
          >
            Related NFTs
          </h3>
          <div className="small-border bg-color-2"></div>
          {isSouvenir?.length <= 0 ? (
            <div
              style={{ marginTop: "-40px", marginBottom: "32px" }}
              className="text-gradient text-center fs-4 pt-4"
            >
              No related NFTs for now!
            </div>
          ) : (
            <ReleatedNft
              isSouvenir={isSouvenir}
              setIsClicked={setIsClicked}
              isClicked={isClicked}
              isDetails={isDetails}
              setDetails={setDetails}
              likes={likes}
              setLikes={setLikes}
            ></ReleatedNft>
          )}
          {isSouvenir?.length >= 1 && (
            <div className="d-flex mt-1" style={{ justifyContent: "center" }}>
              {isSouvenir?.length > 0 ? (
                <Typography
                  variant="h6"
                  style={{
                    color: "#d0d7c2",
                    fontSize: "16px",
                    marginTop: "1rem",
                  }}
                >
                  Pay by DSL and get 30% discount.
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    color: "#d0d7c2",
                    fontSize: "16px",
                    marginTop: "1rem",
                  }}
                >
                  Stay Tuned!
                </Typography>
              )}
            </div>
          )}
        </Container> */}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="overflow-hidden text-light"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <Modal.Header
          className="text-light"
          closeButton
          style={{ backgroundColor: "#242435", color: "white" }}
        >
          <Modal.Title className="text-light">Auto Mint</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#242435", color: "white" }}>
          <form onSubmit={onsubAutoMint}>
            <Form.Control
              min="1"
              type="number"
              value={automint}
              onChange={(e) => setAutomint(e.target.value)}
              name="automintcode"
              placeholder="Auto Mint Code"
              required
            />
            <br />
            <InputGroup>
              <Form.Control
                style={{ textTransform: "lowercase" }}
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              <button
                onClick={() => handleVerifyEmail()}
                disabled={
                  email1.length === 0 || disableAfterActivation ? true : false
                }
                type="button"
                className="btn btn-danger"
                id="button-addon2"
              >
                Verify Email
              </button>
            </InputGroup>
            <label className="fs-6 pb-3">
              We will send your NFT to this email
            </label>
            <br />
            <Form.Control
              min="1"
              type="number"
              name="verificationCode"
              placeholder="Verification Code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <Modal.Footer
              style={{ backgroundColor: "#242435", color: "white" }}
            >
              <Button variant="secondary" onClick={handleClose}>
                CLOSE
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  email1.length === 0 ||
                    otp.length === 0 ||
                    automint.length === 0 ||
                    onsubDisable ||
                    otp != otpVerify
                    ? true
                    : false
                }
              >
                SUBMIT
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      {/* <EmailVerifyModal
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        setOpenEmail={setOpenEmail}
        otpVerify={otpVerify}
        setError={setError}
      /> */}
    </div>
  );
};

export default NFTDetails;
