import React from 'react';
import { Link } from 'react-router-dom';
import "./Prizes.css"
import firstPri from "../../Assets/Images/1stprize.png"

const dataSliderImage = [
    {
      id: 1,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "1st Prize",
    },
    {
      id: 2,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "2nd Prize",
    },
    {
      id: 3,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "3rd Prize",
    },
    {
      id: 4,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "4th Prize",
    },
    {
      id: 5,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "5th Prize",
    },
    {
      id: 6,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "6th Prize",
    },
    {
      id: 7,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "7th Prize",
    },
    {
      id: 8,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "8th Prize",
    },
    {
      id: 9,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "9th Prize",
    },
    {
      id: 10,
      image: firstPri,
      name: "Product Name",
      price: "(Worth USD 0000.00)",
      position: "10th Prize",
    },
  ];
  const currentDate = new Date();

  // Get month and year
  const month = currentDate.toLocaleString('default', { month: 'long' });
const year = currentDate.getFullYear();
  
const Prizes = () => {
    return (
        <div className="pb-5" >
        <div className="container">
            <div>
                <h1 className="page-title text-center text-uppercase" style={{ marginTop: '40px', fontSize: "1.75rem" }}>LEADERBOARD PRIZES</h1>
            <p className='text-center text-white pt-2'>{month} {year}</p>
            </div>

            <div className="row mt-5">
          {dataSliderImage.map((item, index) => (
            <div key={item?.id} className=" col-lg-6 col-md-12 col-sm-12 mb-2">
              <div
                class="card "
                style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
              >
                <div className="px-4 pt-4 pb-2">
                  <img src={item?.image} alt="" className="w-100" />
                  <div className="d-flex justify-content-between mt-3 ">
                    <p
                      className="text-light prize-name"
                      style={{ fontSize: "22px", fontWeight: "600" }}
                    >
                      {item.name}{" "}
                      <span
                        className="priceOffer"
                        style={{ fontWeight: "600", color: "red" }}
                      >
                        {item.price}
                      </span>
                    </p>
                    <button className="prize-btn">{item.position}</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>
    </div >
    );
};

export default Prizes;