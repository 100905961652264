import React from "react";
import "./Footer.css";
import social1 from "../../Assets/Images/social 1.png";
import social2 from "../../Assets/Images/social 2.png";
import social3 from "../../Assets/Images/social 3.png";
import social4 from "../../Assets/Images/social 4.png";
import social5 from "../../Assets/Images/social 5.png";
import galaraceLogo from "../../Assets/Images/galarace-logo.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { GalagamesContext } from "../../Context/GalaraceContext";

const Footer = () => {
  const { user } = useContext(GalagamesContext)
  return (
    <div className="footer-container">
      <div className="container pb-3">
        <div className="row gy-5 g-md-0 g-lg-0 justify-content-between">
          <div className="col-md-4 col-lg-4 col-12 footer-first-part">
            <div className="d-flex align-items-center">
              <img style={{ width: "45px" }} src={galaraceLogo} alt="" />{" "}
              <h3 style={{ marginBottom: "0px" }}>GALARACE.AI</h3>
            </div>

            <div
              style={{ maxWidth: "118px" }}
              className="title-bottom-border"
            ></div>
            <p style={{ marginBottom: "16px", fontWeight: "500" }}>
              Galarace.ai is a revolutionary new way to play and earn rewards.
              It is a blockchain-based game that allows players to earn rewards
              for playing and completing tasks.
            </p>
            {/* <p>
              Players can earn rewards in the form of tokens, which can be used
              to purchase in-game items or exchanged for real-world currency.
              The game also features a leaderboard system, allowing players to
              compete against each other for the highest score. Blockchain Play
              to Earn Game is a great way to have fun and earn rewards at the
              same time.
            </p> */}
          </div>
          <div className="col-md-3 col-lg-3 col-12 offset-lg-3 quick-links-container">
            <h3 style={{ marginBottom: "0px" }}>Quick Links</h3>
            <div
              style={{ maxWidth: "118px" }}
              className="title-bottom-border"
            ></div>
             <Link to={"/race"}>
              <p style={{ cursor: "pointer" }}>Race </p>
            </Link>
            <Link to={"/race-channel"}>
              <p style={{ cursor: "pointer" }}>Race Channel</p>
            </Link>
           
            <Link to={"/results"}>
              <p style={{ cursor: "pointer" }}>Results</p>
            </Link>
         
            <Link to={"/leader-board"}>
              <p style={{ cursor: "pointer" }}>Leaderboard</p>
            </Link>
            <Link to={"/nfts"}>
              <p style={{ cursor: "pointer" }}>NFTs</p>
            </Link> 
            <Link to={"/memberships"}>
              <p style={{ cursor: "pointer" }}>Memberships</p>
            </Link>
            {/* {user?.email && <Link to={"/earnings"}>
              <p style={{ cursor: "pointer" }}>Earnings</p>
            </Link>} */}
          </div>
          {/* <div className="col-md-3 col-lg-3 col-12 footer-third-part">
            {/* <h3 style={{ marginBottom: "0px" }}>Social Media</h3>
            <div
              style={{ maxWidth: "80px" }}
              className="title-bottom-border"
            ></div>
            <p>
              <img src={social1} alt="" /> <a href="">Instagram</a>
            </p>
            <p>
              <img src={social2} alt="" />{" "}
              <a href="https://twitter.com/dslsingapore">Twitter</a>
            </p>
            <p>
              <img src={social3} alt="" /> <a href="">Whatsapp</a>
            </p>
            <p>
              <img src={social4} alt="" />{" "}
              <a href="https://www.facebook.com/search/top?q=ds%20legends%20pte%20ltd">
                Facebook
              </a>
            </p>
            <p>
              <img src={social5} alt="" />{" "}
              <a href="https://www.linkedin.com/company/dslsingapore">
                Linked In
              </a>
            </p>
        </div> */}
        </div>

      </div>
      <div style={{ borderTop: "1px solid skyblue" }}>
        <div className="container">
          <p
            className="footer-copy-right-text"
            style={{ padding: "20px 0" }}
          >
            © GALARACE.AI | All rights reserved {new Date().getFullYear()}.
          </p>
        </div>
      </div>
    </div >
  );
};

export default Footer;
