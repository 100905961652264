import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

const Main = () => {
  // THIS IS FOR ADDING OR REMOVING THE FOOTER OR THE NAV ACCORDING TO ROUTES

  const { pathname } = useLocation();

  let content = "";

  if (pathname.includes("login") || pathname.includes("admin-forgot-password") || pathname.includes("admin-forgot-password") || pathname.includes("admin-reset-password") || pathname.includes("admin")) {
    content = (
      <div>
        <Outlet />
      </div>
    );
  } else {
    content = (
      <div>
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    );
  } 

  return <div>{content}</div>;
};

export default Main;
