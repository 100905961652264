import React from 'react';
import moment from "moment";
import "./Transactions.css"
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { GalagamesContext } from '../../Context/GalaraceContext';
import { useEffect } from 'react';
import Pagination from "@mui/material/Pagination";

const Transactions = () => {
  const { user } = useContext(GalagamesContext)
  const [transaction, setTransaction] = useState([])
  useEffect(() => {
    axios.get(`https://backend.galarace.ai/api/v1/transaction/getByEmail/${user?.email}`)
      .then(res => {
        setTransaction(res.data.reverse())
      })
  }, [])

  const getTheTime = (times) => {
    const d = new Date(times);
    const hours = ("0" + (d.getHours() % 12)).slice(-2);
    const minutes = ("0" + d.getMinutes()).slice(-2);
    const seconds = ("0" + d.getSeconds()).slice(-2);
    const time = `${hours}:${minutes}:${seconds}`;

    const day = ("0" + d.getDate()).slice(-2);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const date = `${day}/${month}/${year}`;
    return time
  }
  const getTheDate = (times) => {
    const d = new Date(times);
    const hours = ("0" + (d.getHours() % 12)).slice(-2);
    const minutes = ("0" + d.getMinutes()).slice(-2);
    const seconds = ("0" + d.getSeconds()).slice(-2);
    const time = `${hours}:${minutes}:${seconds}`;

    const day = ("0" + d.getDate()).slice(-2);
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const date = `${day}/${month}/${year}`;
    return date
  }

  const [page1, setPage1] = useState(1);




  const data1 = transaction;
  const itemsPerPage1 = 12;
  const pageCount1 = Math.ceil(data1?.length / itemsPerPage1);

  const handleChange1 = (event, value) => {
    window.scrollTo(0, 0);
    setPage1(value);
  };

  const getPageData = () => {
    const start = (page1 - 1) * itemsPerPage1;
    const end = start + itemsPerPage1;
    return transaction?.slice(start, end);
  };

  return (
    <div>
      <h2 className="text-white text-center mt-4 mb-5 page-title">TRANSACTIONS</h2>
      <div className="container account-container pb-5">
        <div className="table-container">
          <table className="transaction-table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>TIME</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {getPageData()?.map(data => <tr>
                <td>{getTheDate(data?.createdAt)}</td>
                <td>{getTheTime(data?.createdAt)}</td>
                <td>{data?.action}</td>
              </tr>)
              }
            </tbody>
          </table>
          <div className="pagination-btn mx-auto text-center mt-3">
                      <Pagination
                        count={pageCount1}
                        page={page1}
                        onChange={handleChange1}
                      />
                    </div>
        </div>
      </div>
    </div>
  );
};


export default Transactions;