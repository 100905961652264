import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, FormControl, InputLabel, Paper, Slide } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import swal from 'sweetalert';
import Select from '@mui/material/Select';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import './SelectionModal.css'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Nav } from 'react-bootstrap';
import MoneyModal from './MoneyModal/MoneyModal';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { GalagamesContext } from '../../../Context/GalaraceContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        background: 'linear-gradient(to right bottom, #1b1d22, #2A2E35)',
        color: 'white'
    }
}));
const StyledPaper = styled(Paper)`
  max-width:700px!important;
`;

function MyVerticallyCenteredModal(props) {
    const [open, setOpen] = useState(false);
    const { openWalletModal, user, } = useContext(GalagamesContext);
    const [skip, setSkip] = useState(true)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { setWin40, modalShow, setModalShow, dogsImg, setFocus, focus, setBid, bid, email, setEmail, setEmailVerify, biddingDog, handleChange, focusBid, alreadyVeified, setFocusBid, checkEmail, isLoaded, animationPause, setGo, setCountdown, handleDestrcture, setTimerOn, checkInput, validEmail, getStartingInfo, executeScroll, setCatAnim, reset2, reset, setClockTimeTricker, setTime, restartButton, catAnim, start, changeRestart, sendMessage, winPercentage, setChangeRestart, clocktimeTricker, stop, onHide, popUpBtn, setPopUpBtn, takeBackToStartLine, money } = props

    // Token
    const verifiedToken = localStorage.getItem('gotVerifiedToken');
    // select items css style class 
    const classes = useStyles();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            PaperComponent={StyledPaper}
            style={{ color: "white" }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Start Race - Win USDT (BEP20)
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='race-selection-wrapper mt-2 text-center'>
                    <div className='row'>
                        <div className='col-12 col-lg-6 offset-lg-3'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='text-white text-center'>Please double click to select</p>
                                </div>
                                <div className='col-12'>
                                    <Box sx={{ minWidth: 270 }} className="handleBoxWidthForSm">
                                        {/* <label className="fs-6 mb-3" style={{ color: 'antiquewhite' }}>Step 1</label> */}
                                        <FormControl required fullWidth>
                                            {props.focus ? <InputLabel id="demo-simple-select-label" className='text-white'> Horse</InputLabel> : <InputLabel id="demo-simple-select-label" className='text-white  '>Select your horse</InputLabel>}
                                            <Select
                                                onFocus={() => props.setFocus(true)}
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.paper
                                                    }
                                                }}
                                                inputProps={{
                                                    classes: {
                                                        root: classes.input
                                                    }
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={props.biddingDog}
                                                label="Dog"
                                                onChange={(e) => { props.handleChange(e) }}
                                                sx={{
                                                    textAlign: 'left',
                                                    color: "white",
                                                    fontSize: '14px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "white !important",
                                                    }
                                                }}
                                            >
                                                {
                                                    props.dogsImg.map(dog => <MenuItem className='my-2' value={dog.img}>
                                                        <div className='selection-dog d-flex justify-content-start align-items-center'>
                                                            <img className='dog dog-size mb-0' src={dog.img} alt="dog"></img>
                                                            <p className='ps-4 mb-0 text-light' >{dog?.gender}</p>
                                                        </div></MenuItem>)
                                                }
                                            </Select>
                                        </FormControl >
                                    </Box>
                                </div>
                                <div className='col-12'>
                                    <p className='mb-2 mt-3'>Your Stake is : {bid ? <span>USD {bid}</span> : <Link className='text-danger' to='/horsechampsbusd/gamenft'>Click here to stake</Link>}</p>
                                    <p className='mb-2 mt-3'>Winnings For This Race</p>
                                    <p className='mb-0'>1st: {bid * 10} USDT (BEP20)</p>
                                    <p className='mb-0'>2nd: {bid * 6} USDT (BEP20)</p>
                                    <p className='mb-0'>3rd: {bid * 4} USDT (BEP20)</p>
                                    {/* <p className='mb-0'>1st: 100 DSL</p>
                                    <p className='mb-0'>2nd: 60 DSL</p>
                                    <p className='mb-0'>3rd: 40 DSL</p>
                                    <p className='my-3 text-danger'>You can win the above in the race</p> */}
                                    {/* <Box sx={{ minWidth: 270 }} className="handleBoxWidthForSm mt-3">
                                        <label className="fs-6 mb-3" style={{ color: "#9090df" }}>Step 2</label>
                                        <FormControl required fullWidth >
                                            {props.focusBid ? <InputLabel id="demo-simple-select-label" className='text-white'>Stake</InputLabel> : <InputLabel id="demo-simple-select-label" className='text-white'>Select entry fees</InputLabel>}
                                            <Select
                                                onFocus={() => props.setFocusBid(true)}
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.paper
                                                    }
                                                }}
                                                inputProps={{
                                                    classes: {
                                                        root: classes.input
                                                    }
                                                }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={props.bid}
                                                label="Dog"
                                                onChange={(e) => props.setBid(e.target.value)}
                                                labelStyle={{ color: '#ff0000' }}
                                                sx={{
                                                    textAlign: 'left',
                                                    color: "white",
                                                    fontSize: '14px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',

                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',

                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'rgba(228, 219, 233, 0.25)',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "white !important",
                                                    }
                                                }}
                                            >
                                               <MenuItem className='text-light' value={1}>1 DSL - Win up to 10 DSL</MenuItem>
                                                <MenuItem className='text-light' value={10}>10 DSL - Win up to 100 DSL</MenuItem> 
                                                <MenuItem className='text-light' value={10}>10 {money} - Win up to 100 {money}</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div className='text-center'>
                                            <small className='text-white mt-1 fst-italic lh-1'><a href="https://mainnet.celebritygames.net/racing" target="_blank" className='text-decoration-none'>This is a testnet blockchain game. You can't claim the winning DSL. Play at Mainnet to claim DSL.</a></small>
                                        </div>

                                    </Box> */}
                                </div>
                                {/* <div className='col-12 mt-3'>

                                    <Box sx={{ minWidth: 270, position: 'relative' }} className="handleBoxWidthForSm mt-3 mt-lg-0 email-wrapper">

                                        <label className="fs-6 mb-3 text-primary" style={{ color: "#9090df" }}>Step 3</label>
                                        <Box className='email-field'>
                                            <TextField
                                                MenuProps={{
                                                    classes: {
                                                        paper: classes.paper
                                                    }
                                                }}
                                                inputProps={{
                                                    style: { color: "white" },
                                                    classes: {
                                                        root: classes.input
                                                    }
                                                }}
                                                onClick={() => verifiedToken && props.alreadyVeified()}

                                                className='text-white  my-0'
                                                onChange={(e) => { props.setEmail(e.target.value); props.setEmailVerify(false) }}
                                                // autoFocus
                                                disabled={verifiedToken}
                                                margin="dense"
                                                id="name"
                                                label="Enter your email"
                                                labelStyle={verifiedToken ? { color: 'gray' } : { color: 'white' }}
                                                required
                                                value={props.email}
                                                type="email"
                                                fullWidth
                                                InputLabelProps={verifiedToken ? {
                                                    style: { color: 'gray', fontSize: '14px!important' },

                                                } : {
                                                    style: { color: 'white!important', fontSize: '14px!important' },

                                                }}
                                                sx={{
                                                    color: "white",
                                                    fontSize: '14px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',

                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white',

                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'gray',
                                                    },
                                                    '&.Mui-focuse .MuiOutlinedInput': {
                                                        borderColor: 'gray',
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "white !important",
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'white',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'white !important',
                                                    },
                                                }}

                                            />

                                            <Button onClick={() => { props.submitEmail(props.email); props.handleVerifyEmail() }} disabled={verifiedToken} className={!verifiedToken ? 'email-verify-btn' : 'email-verify-btn02'}>{!verifiedToken ? 'Verify' : 'Verified'}</Button>
                                        </Box>

                                    </Box>
                                </div> */}
                                {/* <div className='col-12'>
                                <label className="fs-6 mt-2 mb-2 text-success">Step 3</label>
                                {user1?.walletAddress && <p className='text-white'>Already logged in</p>}
                               {!user1?.walletAddress && <div className=' selection-login'>
                               <Nav.Link className='connectMen'>
                                        <small className='text-white walletFont me-2'>Login With Wallet</small> 
                                        <Button style={{fontSize:"12px"}} className='ColorBg border border-0' onClick={handleClickOpen}  >
              <img style={{ width: "25px", marginRight: '5px' }} src="/dslLogo.jpeg" alt="" />
              LOGIN WITH WALLET
            </Button>
                                             <AccountBalanceWalletIcon className='walletIcon' /> 
                                        </Nav.Link>
                                         <p className='my-0 d-block text-center text-lg-start'><small className='text-white /walletFont'
                                        >Complete this step if you want to claim DSL. <span className='skip-btn' onClick={()=>setSkip(false)}>Skip</span> </small></p>
                                    </div>
                                </div> */}
                                <div className='col-12'>
                                    <div className='button-box-wrapper d-flex justify-content-center align-items-center handleforAlignInSm mt-3'>
                                        {/* {
                    animationRestart ? <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' onClick={() => restartButton()}>RESTART</button> : <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' onClick={() => handleDestrcture()}>START</button>
                } */}
                                        <div className='d-inline pb-3'>
                                            {/* <label className="fs-6 me-3 mb-1 text-danger">Step 3</label> */}
                                            <div>
                                                {
                                                    !animationPause ?
                                                        <button
                                                            // disabled={!user1?.walletAddress}
                                                            id='start'
                                                            className='btn ColorButton py-2 border-0  btn-sm text-white start me-4 text-center '
                                                            style={{ minWidth: '100px' }}
                                                            onClick={() => {
                                                                ((bid && biddingDog) ? (setTimeout(() => {
                                                                    setGo(true)
                                                                    handleDestrcture()
                                                                    setCountdown(true)
                                                                    setTimerOn(true)
                                                                }, 4800)) : checkInput())
                                                                if (bid && biddingDog) {
                                                                    winPercentage()
                                                                    setWin40(true)
                                                                    executeScroll()
                                                                    onHide()
                                                                    setPopUpBtn(true)
                                                                    // getStartingInfo()
                                                                    // sendMessage("GameManager", "WebStartBtnClicked")
                                                                    sendMessage("GameManager", "WebStartBtnClickedForTMP", 8)
                                                                    setCatAnim(setTimeout(() => {
                                                                        setCountdown(false)
                                                                        reset2()
                                                                    }, 500))

                                                                    setTimeout(() => {
                                                                        start();
                                                                        setClockTimeTricker(setTimeout(() => {
                                                                            reset()
                                                                            stop()

                                                                            setTimerOn(false)
                                                                            setTimeout(() => {
                                                                                setTime(0)
                                                                            }, 1000);
                                                                        }, 38000))
                                                                    }, 4800);

                                                                }
                                                            }}
                                                        >START</button>
                                                        : <button

                                                            id='start'
                                                            style={{ minWidth: '100px' }}
                                                            className='btn ColorButton border-0 btn-sm text-white start me-4 text-center ' onClick={() => {
                                                                ((bid && biddingDog) ? (setTimeout(() => {
                                                                    setGo(true)
                                                                    restartButton()
                                                                    setCountdown(true)
                                                                    setTimerOn(true)
                                                                }, 4800)) : checkInput())
                                                                if (bid && biddingDog) {
                                                                    winPercentage()
                                                                    setWin40(true)
                                                                    executeScroll()
                                                                    onHide()
                                                                    takeBackToStartLine()
                                                                    setPopUpBtn(true)
                                                                    // getStartingInfo()
                                                                    sendMessage("GameManager", "WebReStartBtnClickedForTMP", 8)
                                                                    executeScroll()
                                                                    clearTimeout(catAnim);
                                                                    setCatAnim(setTimeout(() => {
                                                                        setTimerOn(false)
                                                                        setCountdown(false)
                                                                        setChangeRestart(false)
                                                                        reset2()
                                                                    }, 500))
                                                                    setTimeout(() => {
                                                                        clearTimeout(clocktimeTricker);
                                                                        reset();
                                                                        start();
                                                                        setClockTimeTricker(setTimeout(() => {
                                                                            reset()
                                                                            stop()
                                                                            setTimerOn(false)
                                                                            setTimeout(() => {
                                                                                setTime(0)
                                                                            }, 1000);
                                                                        }, 38000))
                                                                    }, 4800);
                                                                }
                                                            }}
                                                        >{changeRestart ? "START" : "RESTART"}</button>
                                                }

                                            </div>

                                        </div>

                                        {/* {
                    animationPause ? <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' style={{ width: "100px" }} onClick={() => setAnimationHandle(!animationHandle)}>
                        {
                            animationHandle ? "PAUSE" : "UNPAUSE"
                        }
                    </button> : <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' style={{ width: "100px" }} onClick={() => handleDestrcture()}>START</button>
                } */}
                                        {/* {
                    animationHandle ? <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' onClick={() => setAnimationHandle(!animationHandle)}>PAUSE</button> : <button id='start' className='btn btn-warning btn-sm text-white start me-4 text-center' onClick={() => setAnimationHandle(!animationHandle)}>UNPAUSE</button>
                } */}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <button
                                        onClick={onHide}
                                        style={{ minWidth: '100px' }}
                                        className='btn btn-danger py-2 btn-sm text-white start mt-0 ms-3 me-4 text-center ' >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>




                </div>
            </Modal.Body>

        </Modal>
    );
}
const SelectionModal = ({ modalShow, setWin40, winPercentage, setModalShow, dogsImg, setFocus, focus, setBid, bid, email, setEmail, setEmailVerify, biddingDog, handleChange, focusBid, alreadyVeified, setFocusBid, checkEmail, animationPause, setGo, setCountdown, handleDestrcture, setTimerOn, checkInput, validEmail, getStartingInfo, executeScroll, setCatAnim, reset2, reset, setClockTimeTricker, setTime, restartButton, catAnim, start, changeRestart, sendMessage, setChangeRestart, clocktimeTricker, stop, popUpBtn, setPopUpBtn, takeBackToStartLine, isLoaded, money, setMoney }) => {


    //  const moneyModal=()=>{
    // swal({
    //     title: `Select what you want to win`,
    //     // text: "You will not be able to recover this post again!",
    //     // icon: "warning",
    //     buttons: ["Win DSL (No Staking)", "Win BUSD (NFT Staking)"],
    //     dangerMode: true,
    //     className: "money-modal"
    //   }).then(function (isConfirm) {
    //     if (isConfirm) {
    //         setModalShow(true)
    //         setMoney('BUSD')
    //     }
    //     else{
    //         setModalShow(true)
    //         setMoney("DSL")
    //     }
    //   });
    //  }
    const [moneyOpen, setMoneyOpen] = useState(false)

    const closeMoney = () => {
        setMoneyOpen(false)
    }
    const navigate = useNavigate()
    return (

        <div >
            {!changeRestart ? <Button style={{ padding: "0.40rem 0.25rem" }} disabled={popUpBtn || !isLoaded} className={`selection-modal btn border-0 ${popUpBtn || !isLoaded ? 'btn-secondary' : 'ColorButton '} `} onClick={() => setModalShow(true)}>
                Start New Race
            </Button> :
                <Button disabled={popUpBtn || !isLoaded} className={`selection-modal btn border-0 ${popUpBtn || !isLoaded ? 'btn-secondary' : 'ColorButton '} `} onClick={() => navigate("/horsechamps/gamenft")}>
                    Stake Again
                </Button>
            }

            <MoneyModal
                open={moneyOpen}
                handleClose={closeMoney}
                setMoney={setMoney}
                setModalShow={setModalShow}
            >

            </MoneyModal>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                dogsImg={dogsImg}
                setFocus={setFocus}
                bid={bid}
                focus={focus}
                setBid={setBid}
                email={email}
                setFocusBid={setFocusBid}
                setEmail={setEmail}
                setEmailVerify={setEmailVerify}
                biddingDog={biddingDog}
                handleChange={handleChange}
                focusBid={focusBid}
                alreadyVeified={alreadyVeified}
                isLoaded={isLoaded}
                // submitEmail={submitEmail}
                // handleVerifyEmail={handleVerifyEmail}
                animationPause={animationPause}
                checkEmail={checkEmail}
                setGo={setGo}
                handleDestrcture={handleDestrcture}
                setCountdown={setCountdown}
                setTimerOn={setTimerOn}
                checkInput={checkInput}
                validEmail={validEmail}
                getStartingInfo={getStartingInfo}
                executeScroll={executeScroll}
                setCatAnim={setCatAnim}
                reset2={reset2}
                reset={reset}
                setClockTimeTricker={setClockTimeTricker}
                setTime={setTime}
                restartButton={restartButton}
                catAnim={catAnim}
                start={start}
                changeRestart={changeRestart}
                sendMessage={sendMessage}
                setChangeRestart={setChangeRestart}
                clocktimeTricker={clocktimeTricker}
                stop={stop}
                popUpBtn={popUpBtn}
                setPopUpBtn={setPopUpBtn}
                takeBackToStartLine={takeBackToStartLine}
                setWin40={setWin40}
                winPercentage={winPercentage}
                money={money}
            />
        </div>

    );
};

export default SelectionModal;