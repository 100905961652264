import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUnityContext } from "react-unity-webgl";
import swal from "sweetalert";

import Button from "@mui/material/Button";

import axios from "axios";
import PositionDialog from "./PositionDialog";
import Game3D from "../RacingBusd/Game3D/Game3D";
import "./RaceChannel.css"
import MetaData from "../../Components/MetaData/MetaData";
import { BiLoaderCircle } from "react-icons/bi";
import { CircularProgress } from "@mui/material";

const RaceChannel = () => {
  const [timeOuts, setTimeouts] = useState("");
  const location = useLocation();
  const [number, setNumber] = useState(0);
  const [open, setOpen] = useState(0);
  const [laoder,setLoader]=useState(true)
  const [AllData, setAllData] = useState([]);
  let num = 0;
  const {
    unityProvider,
    isLoaded,
    addEventListener,
    removeEventListener,
    loadingProgression,
    sendMessage,
    requestFullscreen,
  } = useUnityContext({
    loaderUrl: './Build/Galarace 1.6 - Brotli.loader.js',
    dataUrl: "./Build/Galarace 1.6 - Brotli.data.unityweb",
    frameworkUrl: "./Build/Galarace 1.6 - Brotli.framework.js.unityweb",
    codeUrl: "./Build/Galarace 1.6 - Brotli.wasm.unityweb",
  });
  const navigate = useNavigate();

  // position dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // position dialog modal
  const handleClose = () => {
    setOpen(false);
  };

  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    let url = "";
    console.log(location?.state?.title?.title, "location?.state?.title");
    if (location?.state?.title?.title == "busd") {
      console.log("inside busd");
      url = "https://backend.galarace.ai/api/v1/raceBUSD";
    } else {
      console.log("inside dsl");
      url = "https://backend.galarace.ai/api/v1/raceBUSD";
    }
    axios
      .get(url, {
        headers: {
          authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
        },
      })
      .then((res) => {
        // setAllData(res?.data.data.reverse());
        let lastSerialNumber = 1;
        let newSerialNumberObjects = res?.data.data.map((object, index) => {
          let newObject = { ...object };
          let serialNumber = lastSerialNumber + index;
          newObject.sno = serialNumber.toString().padStart(15, "0");
          console.log(newObject, "newObject");
          return newObject;
        });
        setAllData(newSerialNumberObjects.reverse());
        setLoader(false)
      })
      .catch(e=>{
        setLoader(false)
      });
  }, [location?.state?.title]);

  const RaceFinish = useCallback(() => {
    // sendMessage("Bridge", "InstantRestart");
    // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 5);
    // // sendMessage("GameManager", "WebStartBtnClicked");
    // setNumber(number => number + 1)
    // handleClickOpen()
    // setTimeout(() => {
    //     sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[number]] }));
    // }, 2000);
  }, []);




  useEffect(() => {
    addEventListener("RaceFinish", RaceFinish);

    return () => {
      removeEventListener("RaceFinish", RaceFinish);
    };
  }, [addEventListener, removeEventListener, RaceFinish]);

  useEffect(() => {
    window.RaceFinish = () => {
      // sendMessage("Bridge", "InstantRestart");
      // sendMessage("GameManager", "WebReStartBtnClickedForTMP", 5);
      setNumber((number) => number + 1);
      // // setTimeout(() => {
      // //     sendMessage("GameManager", "WebStartBtnClicked");
      // // }, 3000);
      // setTimeout(() => {
      //     sendMessage("Bridge", "FeedJsonData", JSON.stringify({ data: [AllData[number]] }));
      // }, 4000);
      // num = num + 1;
      // setNumber(number => number + 1)
      handleClickOpen();
    };
  }, [unityProvider]);

  useEffect(() => {
    console.log("in the page")
    return () => {
      console.log("outside the page")
      if (unityProvider) {
        sendMessage("GameManager", "QuitGame")
      }
    };
  }, [isLoaded, unityProvider]);

  const raceReplay = () => {
    num = num + 1;
    sendMessage("GameManager", "SwitchCamContinuously");
    handleClose();
    setTimeouts(
      setTimeout(() => {
        sendMessage("Bridge", "InstantRestart");

        setTimeout(() => {
          sendMessage("GameManager", "WebStartBtnClicked");
        }, 1000);
        setTimeout(() => {
          sendMessage(
            "Bridge",
            "FeedJsonData",
            JSON.stringify({ data: [AllData[num]] })
          );
          sendMessage("GameManager", "SwitchCamContinuously");
          if (AllData.length == num) {

            sendMessage("GameManager", "QuitGame");
            clearTimeout(timeOuts);

          }
        }, 2000);
        raceReplay();
      }, 50000)
    );
  };

  const locataion = useLocation();
  useEffect(() => {
    if (isLoaded == true) {
      sendMessage("GameManager", "WebStartBtnClicked");
      setTimeout(() => {
        sendMessage(
          "Bridge",
          "FeedJsonData",
          JSON.stringify({ data: [AllData[number]] })
        );
        console.log(AllData[number], "AllData[number]222", num);
        raceReplay();
      }, 1000);
      // setInterval(() => {
      //     sendMessage("Bridge", "FeedJsonData", JSON.stringify(AllData[number]));
      // }, 100);
    }
  }, [isLoaded]);
  return (
    <>
     <MetaData pageTitle={"Gala Race | Play to earn blockchain games"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
      <div className="container replay-container">
        <div
          style={{ paddingTop: "20px", position: "relative" }}
          className="replays text-center"
        >
          {/* <h5 onClick={() => {
                    navigate("/racing", { state: { name: 'replay', autoOpen: true } });
                    sendMessage("GameManager", "QuitGame"); clearTimeout(timeOuts)
                }} className='text-white mt-2' style={{ position: 'absolute', right: 0, cursor: 'pointer' }}>X</h5> */}
          <h1
            className="text-white text-center pb-2 page-title"
            style={{ fontSize: "2rem" }}
          >
            Race Channel
          </h1>

          <div
          //  className="headers-action-container"
          >
            <button
              className="btn px-4 text-white me-0 me-md-3 me-lg-3"
              onClick={() => navigate("/race")}
              style={{ backgroundColor: "#36698c", minWidth: "130px" }}
            >
              JOIN RACE NOW
            </button>

            <div className="text-center text-white mt-3">
              <p> <span onClick={() => navigate("/results")} style={{ cursor: "pointer" }}>Results</span> | <span onClick={() => navigate('/race-channel')} style={{ cursor: "pointer" }}>Race Channel</span> </p>
            </div>

            {/* <button style={{ minWidth: "130px" }} className="btn btn-warning mb-3 me-0 me-lg-3" onClick={() => navigate('/results')}>Results</button>
            <button style={{ minWidth: "130px" }} className="btn btn-success mb-3" onClick={() => navigate('/race-channel')}>Race Channel</button> */}
          </div>

          <h5 className="text-white text-center pb-4">
            Race Number :{" "}
            {AllData[number]?.sno ? AllData[number]?.sno : "000000000000000"}
          </h5>
          {!laoder?(AllData[number]?.sno?<div>
            {isLoaded && (
              <img
                src="https://img.icons8.com/color/48/null/switch-camera.png"
                onClick={() => sendMessage("Canvas", "ChangeCam")}
                class="fa-expand"
              ></img>
            )}
            <Game3D
              unityProvider={unityProvider}
              isLoaded={isLoaded}
              loadingProgression={loadingProgression}
              sendMessage={sendMessage}
            ></Game3D>
          </div>:<p className="text-center d-flex justify-content-center align-items-center" style={{height:"30vh",color:"red"}}>No Data Found</p>):<p className="text-center text-white d-flex justify-content-center align-items-center" style={{height:"50vh"}}><CircularProgress className="text-center text-white" color="inherit" /></p>}
        </div>
      </div>
      {AllData[number]?.sno && <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          {/* <Button
            onClick={() => {
              sendMessage("GameManager", "QuitGame");
              clearTimeout(timeOuts);
              navigate(-1);
            }}
            className="btn px-4 bg-danger text-white"
          >
            Close
          </Button> */}

          <button
            className="btn btn-info mx-auto text-white"
            style={{ background: "#006e7a" }}
            onClick={() => { window.location.reload(); console.log("restarted"); }}
          >
            RESTART
          </button>
          <PositionDialog
            handleClickOpen={handleClickOpen}
            open={open}
            title={
              // location?.state?.title?.title
              //   ? location?.state?.title?.title
              //   : 
              "busd"
            }
            sno={AllData[number - 1]?.sno}
            // positions={positions}
            // myDog={myDog}
            raceNumber={number}
            handleClose={handleClose}
          ></PositionDialog>
        </div>
      </div>}
    </>
  );
};

export default RaceChannel;
