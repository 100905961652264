import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BuyNow.css";
import { packages } from "../PackageDetails/packageData";
import { GalagamesContext } from "../../Context/GalaraceContext";
import { useContext } from "react";
import swal from "sweetalert";
import axios from "axios";

const BuyNow = () => {
  const { packNum } = useParams();
  const [currentPack, setCurrentPack] = useState({});
  const { openWalletModal, user } = useContext(GalagamesContext);

  useEffect(() => {
    if (packNum) {
      setCurrentPack(packages.find((pack) => pack.num == packNum));
    }
  }, [packNum]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handlePay = async () => {
    try {
      const walletAamount = (currentPack?.grtWorth * 12) / 100;
      const walletBamount = (currentPack?.grtWorth * 88) / 100;

      const refAmount = (currentPack?.grtWorth * 10) / 100;

      const payData = {
        packageName: currentPack?.name,
        totalPrice: currentPack?.grtWorth,
        date: new Date(),
        walletaddress: user?.walletAddress,
        email: user?.email,
        walletA: "0x15863f0538152fa1c3b0b3cee6562a5ae9cbe7b2",
        walletB: "0x109b991618f6594465ac208007fc9d0c432f8c3d",
        refAddress: "ref@gmail.com",
        refAmount: refAmount,
        walletAamount: walletAamount,
        walletBamount: walletBamount,
      };

      const response = await axios.post(
        "https://backend.galarace.ai/api/v1/membership",
        payData
      );
      if (response) {
        axios.post('https://backend.galarace.ai/api/v1/makepayment', {
          walletAddress: user?.walletAddress,
          paymentName: "busd",
          email: user?.email,
          paymentAmount: currentPack?.grtWorth,
          transactionId: "4x0146s5",
          remarks: "Membership Bought",
        })
        axios
          .post(`https://backend.galarace.ai/api/v1/bonus`, {
            email: user?.email, walletAddress: user?.walletAddress, amount: currentPack?.grtWorth, date: new Date()
          }, {
            headers: {
              authorization: `Bearer ${localStorage.getItem("galagames")}`,
            },
          })

        swal({
          text: "Payment Successful",
          icon: "success",
          className: "modal_class_success",
          button: "Ok",
        });
      }
    } catch (error) {
      console.log("Error White paying", error);
      swal({
        text: "Something went wrong while paying. Please try again later.",
        icon: "error",
        className: "modal_class_success",
        button: "Ok",
      });
    }
  };

  return (
    <div
      className="py-5"
      style={{
        paddingInline: "30px",
      }}
    >
      <div
        className="mx-auto "
        style={{
          maxWidth: "400px",
        }}
      >
        <div className="list-container">
          <ul className="list-group list-body-bg list-group-flush text-uppercase w-100 pt-0 rounded">
            <li className="list-group-item text-light">
              <span style={{ fontWeight: "500" }}>Package Name:</span>{" "}
              {currentPack?.name}
            </li>
            <li className="list-group-item text-light">
              <span style={{ fontWeight: "500" }}>Package Price:</span> USD
              {currentPack?.grtWorth}
            </li>

            <li className="list-group-item text-light text-center">
              {!user?.walletAddress ? (
                <button
                  className="btn btn-success btn-sm w-100"
                  onClick={openWalletModal}
                >
                  <img
                    className="me-2"
                    width="18px"
                    src="https://i.ibb.co/CBj8VVQ/af0137fd-42ad-4ca5-9e6c-3e8595fa77e2.jpg"
                    alt=""
                  />{" "}
                  WALLET LOGIN
                </button>
              ) : (
                <button
                  className="btn btn-success btn-sm w-100"
                  onClick={handlePay}
                >
                  PAY USD {currentPack?.grtWorth}
                </button>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
