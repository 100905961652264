import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./GameRewards.css"
import DslPayments from './DslPayment';
import BusdPayment from './BusdPayment';
import WithdrawPayment from './WithdrawPayment';

const GameRewards = () => {
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])


    useEffect(() => {
        axios.get("https://backend.galarace.ai/api/v1/admin-payment-busd-record")
            .then(res => {
                const flag = res.data.result
                setData(flag.filter(item => item.claim == false))
                console.log(data, "data")
            })
        axios.get("https://backend.galarace.ai/api/v1/admin-payment-dsl-record")
            .then(res => {
                const flag = res.data.result
                setData1(flag.filter(item => item.claim == false))
                console.log(data, "data")
            })

        axios.get("https://backend.galarace.ai/api/v1/login-reward")
            .then(res => {
                const flag = res.data.result
                setData2(flag.filter(item => item.claim == false))
            })


    }, [])
    return (
        <div className="adminBody">
            <h5 className="dashboard-title">Game Rewards History</h5>
            <div className="productCard pb-2">
                <Tabs
                    defaultActiveKey="profile"
                    id="justify-tab-example"
                    className="mb-3  flex-column flex-md-row"
                    justify
                >
                    <Tab eventKey="profile" className='mt-4' title={`NFT Staking Race (${data.length})`}>
                        <DslPayments />
                    </Tab>
                    <Tab eventKey="longer" className='mt-4' title={`USDT (BEP20) Staking Race (${data1.length})`}>
                        <BusdPayment />
                    </Tab>
                    <Tab eventKey="longer-tab" className='mt-4' title={`GRT Staking Race (${data2.length})`}>
                        <WithdrawPayment />
                    </Tab>
                </Tabs>

            </div>
        </div>
    );
};

export default GameRewards;