import axios from "axios";
import React, { useEffect, useState, createContext } from "react";
import { ethers, Contract, BigNumber } from "ethers";
import { v4 as uuidv4 } from "uuid";
import abi from "../utils/nftAbi.json";
import swal from "sweetalert";
import {
  contractAddressStakeToken,
  testStakeTokenAbi,
  contractAddressStakeTokenVRF,
  testStakeTokenVrfAbi,
  contractAddressMemberShip,
  memberShipAbi,
  contractAddressNftTokenMinting,
  testMintNftTokenAbi,
} from "../utils/constant";

import {
  DSLtokenABITestnet,
  DSLtokenAddressTestnet,
  mintABITestnet,
  mintAddressTestnet,
  mintABITestnet2,
  mintAddressTestnet2,
  USDSCtokenABITestnet,
  USDSCtokenAddressTestnet,
  S39tokenAddressTestnet,
  S39tokenABITestnet,
  QuesttokenAddressTestnet,
  QuesttokenABITestnet,
  RPC,
  chainId,
} from "../utils/constant.js";

export const GalagamesContext = createContext();

const { ethereum } = window;
const S39Address = "0xF3246B49556bc457a757947a0035ac416A564803";
const S39GlobalAddress = "0x120478100118F3d3055475cf30146214C27a4C92";
const MINTNOWADDRESS = "0x0DA624DdAe912152d3BE5163539b482BB88987a3";
const getMintContractTestnet = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const MintNFTContract = new ethers.Contract(
    mintAddressTestnet,
    mintABITestnet,
    signer
  );

  return MintNFTContract;
};

const getTokenContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const Token = new ethers.Contract(
    contractAddressStakeToken,
    testStakeTokenAbi,
    signer
  );
  return Token;
};

const getStakeTokenVRFContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const VRF = new ethers.Contract(
    contractAddressStakeTokenVRF,
    testStakeTokenVrfAbi,
    signer
  );
  return VRF;
};

const getMemberShipContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const memberShip = new ethers.Contract(
    contractAddressMemberShip,
    memberShipAbi,
    signer
  );
  return memberShip;
};

const getTokenMintNftContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const mintTokenNft = new ethers.Contract(
    contractAddressNftTokenMinting,
    testMintNftTokenAbi,
    signer
  );
  return mintTokenNft;
};

const getUSDSCtokenContractTestnet = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(
    USDSCtokenAddressTestnet,
    USDSCtokenABITestnet,
    signer
  );

  return tokenContract;
};

const getDSLtokenContractTestnet = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(
    DSLtokenAddressTestnet,
    DSLtokenABITestnet,
    signer
  );

  return tokenContract;
};

const getS39tokenContractTestnet = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(
    S39tokenAddressTestnet,
    S39tokenABITestnet,
    signer
  );

  return tokenContract;
};
const getQuesttokenContractTestnet = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(
    QuesttokenAddressTestnet,
    QuesttokenABITestnet,
    signer
  );
  return tokenContract;
};

const getAllItemBlockchain = async () => {
  const provider = new ethers.providers.JsonRpcProvider(RPC);
  return {
    provider,
    // deployer: new ethers.Wallet(private_key, provider),
    NFTContract: new Contract(mintAddressTestnet, abi, provider),
  };
};

const genSignature = async (types, voucher, auth) => {
  const domain = {
    name: "NFT-Voucher",
    version: "1",
    verifyingContract: auth.contract,
    chainId: chainId,
  };
  const BuyNFTVoucher = {
    id: voucher.id,
    price: voucher.price,
    tokenAddress: voucher.tokenAddress,
    nonce: voucher.nonce,
  };

  // const signature = await auth.signer._signTypedData(domain, types, BuyNFTVoucher);

  return {
    ...voucher,
    // signature,
  };
};

const signBuyFunction = async (id, price, tokenAddress, refAddress, uri) => {
  const contracts = await getAllItemBlockchain();
  const auth = {
    signer: contracts.deployer,
    contract: contracts.NFTContract.address,
  };

  const types = {
    BuyNFTStruct: [
      { name: "id", type: "string" },
      { name: "price", type: "uint256" },
      { name: "tokenAddress", type: "address" },
      { name: "nonce", type: "string" },
    ],
  };
  console.log("111111111111111: ", id, price, tokenAddress, refAddress, uri);

  // Generate nonce as transaction id
  const nonce = uuidv4();
  const voucher = {
    id: id,
    price: BigNumber.from(price),
    tokenAddress: tokenAddress,
    refAddress:
      refAddress.length !== 0
        ? refAddress
        : "0x0000000000000000000000000000000000000000",
    nonce: nonce,
    uri: uri,
  };
  return {
    ...(await genSignature(types, voucher, auth)),
    price: price.toString(),
  };
};

export default function GalagamesProvider({ children }) {
  const [balanceS39, setS39Balance] = useState(0);
  const [loginModal, setLoginModal] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [Id, setId] = useState();
  const [checkConvert, setCheckConvert] = useState();
  const [chain, setChain] = useState("");
  const [payAmount, setPayAmount] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [metamaskBalance, setMetamaskBalance] = useState({});
  const [metamaskBalanceLoading, setMetamaskBalanceLoading] = useState(false);
  const [coinbaseModal, setCoinbaseModal] = useState(false);
  const [userRefetch, setUserRefetch] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [goToProfile, setGoToProfile] = useState(false);
  const [token, setToken] = useState("");

  window.addEventListener("load", () => {
    setPageLoading(false);
  });

  const openWalletModal = () => {
    (!user?.walletAddress ||
      user?.walletAddress === "walletAddress undefined") &&
      setWalletModal(true);
  };
  const closeWalletModal = () => {
    setWalletModal(false);
  };

  const openCoinbaseModal = () => {
    // (!user?.walletAddress || user?.walletAddress === "undefined") &&
    setCoinbaseModal(true);
  };
  const closeCoinbaseModal = () => setCoinbaseModal(false);

  const openLoginModal = () => setLoginModal(true);
  const closeLoginModal = () => setLoginModal(false);

  useEffect(() => {
    checkIfWalletIsConnect();
  }, []);

  const getBalanceTestnet = async () => {
    const USDSCtokenContract = getUSDSCtokenContractTestnet();
    const DSLtokenContract = getDSLtokenContractTestnet();
    const S39tokenContract = getS39tokenContractTestnet();
    const QuestTokenContract = getQuesttokenContractTestnet();
    const USDSCbalance = await USDSCtokenContract.balanceOf(currentAccount);
    const USDSCamount = ethers.utils.formatEther(USDSCbalance);
    const DSLbalance = await DSLtokenContract.balanceOf(currentAccount);
    const DSLamount = ethers.utils.formatEther(DSLbalance);
    const S39balance = await S39tokenContract.balanceOf(currentAccount);
    const S39amount = ethers.utils.formatEther(S39balance);
    const Questbalance = await QuestTokenContract.balanceOf(currentAccount);
    const Questamount = ethers.utils.formatEther(Questbalance);
    const provider = new ethers.providers.Web3Provider(ethereum);
    const balance1 = await provider.getBalance(currentAccount);

    console.log("usdsc: " + USDSCamount);
    console.log("dsl: " + DSLamount);
    console.log("s39: " + S39amount);
    console.log("Quest: " + Questamount);
    console.log("BNB Testnet: " + ethers.utils.formatEther(balance1));
    const wallet = {
      usdsc: USDSCamount,
      bnb: ethers.utils.formatEther(balance1),
      dsl: DSLamount,
      s39: S39amount,
      Quest: Questamount,
    };
    return setMetamaskBalance(wallet);
  };

  window.addEventListener("load", function () {
    if (window.ethereum) {
      // detect Metamask account change
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log("account is Changed", accounts);
      });

      window.ethereum.on("networkChanged", function (networkId) {
        console.log("network is changed: ", networkId);
      });
    } else {
      throw new Error("No ethereum object");
    }
  });

  const logOut = async () => {
    setCurrentAccount(null);
    setUser({});
    localStorage.removeItem("galagames");
  };

  const checkIfWalletIsConnect = async () => {
    try {
      if (!ethereum) {
        return console.log("please use metamask");
      }

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);
        const chainid = await window.ethereum.request({
          method: "eth_chainId",
        });
        setChain(chainid);
      } else {
        console.log("No accounts found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const connectToMetamask = async () => {
    getBalanceTestnet();
    if (typeof window.ethereum === "undefined") {
      // ask the user to install the extension
      return swal({
        title: "Attention",
        text: "Please open this website with wallet browsers",
        icon: "warning",
        button: "OK",
        dangerMode: true,
        className: "modal_class",
      });
    }
    let provider = null;
    if (typeof window.ethereum !== "undefined") {
      let provider = window.ethereum;
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isMetaMask) provider = p;
        });
      }
      try {
        const chainid = await provider.request({
          method: "eth_chainId",
        });
        console.log("This is Chain ID: ", chainid);
        setChain(chainid);
        if (chainid === "0x38") {
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          });
          console.log(accounts[0]);
          setCurrentAccount(accounts[0]);

          const walletAddressData = new FormData();
          walletAddressData.append("walletAddress", accounts[0]);
          if (localStorage.getItem("galagames")) {
            await axios
              .put(
                `https://backend.galarace.ai/api/v1/wallet-user/`,
                walletAddressData,
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem(
                      "galagames"
                    )}`,
                  },
                }
              )
              .then((res) => {
                if (res) {
                  // keep the previous data of user state and add only res.data.user.walletAddress to it
                  setUserRefetch(!userRefetch);
                  // getBalanceMainnet();
                  getBalanceTestnet();

                  setLoading(false);
                  closeWalletModal();
                  // localStorage.setItem("galagames", res.data.token);
                  // setUser(res.data.user)
                  const wrapper = document.createElement("div");
                  wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Binance (Mainnet).</p>`;
                  return swal({
                    // title: "Success",
                    // text: "You have succesfully logged in with Binance Chain.",
                    content: wrapper,
                    icon: "success",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                  }).then((willDelete) => {
                    if (willDelete) {
                      setGoToProfile(true);
                    }
                  });
                }
              });
          } else {
            await axios
              .post(
                `https://backend.galarace.ai/api/v1/wallet-user/`,
                walletAddressData,
                {
                  headers: {
                    authorization: `Bearer ${localStorage.getItem(
                      "galagames"
                    )}`,
                  },
                }
              )
              .then((res) => {
                if (res) {
                  localStorage.setItem("galagames", res.data.token);
                  // keep the previous data of user state and add only res.data.user.walletAddress to it
                  setUserRefetch(!userRefetch);
                  // getBalanceMainnet();
                  getBalanceTestnet();

                  setUser(res.data.user);
                  setLoading(false);
                  axios
                    .put(
                      `https://backend.galarace.ai/api/v1/wallet-user/`,
                      walletAddressData,
                      {
                        headers: {
                          authorization: `Bearer ${localStorage.getItem(
                            "galagames"
                          )}`,
                        },
                      }
                    )
                    .then((ress) => {
                      setUserRefetch(!userRefetch);
                      closeWalletModal();

                      const wrapper = document.createElement("div");
                      wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Binance (Mainnet).</p>`;
                      return swal({
                        // title: "Success",
                        // text: "You have succesfully logged in with Binance Chain.",
                        content: wrapper,
                        icon: "success",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                      }).then((willDelete) => {
                        if (willDelete) {
                          setGoToProfile(true);
                        }
                      });
                    }).catch(error => {
                      // Handle any errors that occur during the request
                      console.error("An error occurred:", error);
                      localStorage.removeItem("galagames");
                    });
                }
              });
          }
        } else {
          console.log("Please Switch to Binance Chain");
          swal({
            title: "Attention",
            text: "Please change to Binance Chain (Mainnet) before connecting.",
            icon: "warning",
            button: "OK",
            dangerMode: true,
            className: "modal_class",
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        throw new Error("User Rejected");
      }
    } else {
      throw new Error("No MetaMask Wallet found");
    }
    console.log("MetaMask provider", provider);
    return provider;
  };

  const setID = async () => {
    try {
      if (ethereum) {
        console.log("No ethereum object");
      }
    } catch (error) {
      console.log(error);
      throw new Error("No ethereum object");
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://backend.galarace.ai/api/v1/wallet-user/all/")
      .then((res) => {
        setAllUsers(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    checkIfWalletIsConnect();
  }, []);

  useEffect(() => {
    if (currentAccount && localStorage.getItem("galagames")) {
      setLoading(true);
      axios
        .get(`https://backend.galarace.ai/api/v1/wallet-user/`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("galagames")}`,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      setUserRefetch(false);
    }
  }, [currentAccount, userRefetch, localStorage.getItem("galagames")]);

  // Loading user info with different condition
  useEffect(() => {
    if (localStorage.getItem("galagames")) {
      console.log("GOT THE GRT");

      setLoading(true);
      axios
        .get(`https://backend.galarace.ai/api/v1/wallet-user/`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("galagames")}`,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      setUserRefetch(false);
    }
  }, [currentAccount, userRefetch, localStorage.getItem("galagames")]);

  useEffect(() => {
    if (requestLoading) {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Please wait, don't exit screen...<b></p> `;
      swal({
        content: wrapper,
        button: false,
        className: "modal_class_success",
      });
    }
  }, [requestLoading]);

  //funtion used to mint NFT using BNB
  const mintTicketNFTTestnetBNB = async (data) => {
    try {
      if (ethereum) {
        const chainid = await window.ethereum.request({
          method: "eth_chainId",
        });
        console.log("This is Chain ID: ", chainid);
        if (chainid === "0x38" || chainid === "0x61") {
          const MintNFTContract = getMintContractTestnet();
          console.log(MintNFTContract);
          const provider = new ethers.providers.Web3Provider(ethereum);

          // const parsedAmount = ethers.utils.parseEther(mintPrice);
          const admin = "0x626D20125da6a371aA48023bF9dad94BD66588F7";
          // const payment = await MintNFTContract.charge(admin, {
          //   value: parsedAmount._hex,
          // });
          // let payment_test = await provider.getTransaction(payment.hash);
          // while (payment_test.blockNumber === null) {
          //   console.log("Payment In Progress...");
          //   payment_test = await provider.getTransaction(payment.hash);
          // }
          // console.log(payment_test.blockNumber);
          // let payment_hash = "https://testnet.bscscan.com/tx/" + payment.hash;
          // console.log("Payment link: " + payment_hash);
          // const recipient = currentAccount;
          // console.log(currentAccount);
          // const Val = await MintNFTContract.mint(uriNft, recipient);
          const object = {
            id: data.id,
            price: data.price,
            tokenAddress: data.tokenAddress,
            refAddress: data.refAddress,
            nonce: data.nonce,
            uri: data.uri,
            signature: data.signature,
          };
          console.log("valueeee", object);

          const Val = await MintNFTContract.buyNFT(object, {
            value: BigNumber.from(object.price),
          });
          await Val.wait();
          let txn_test = await provider.getTransaction(Val.hash);
          while (txn_test.blockNumber === null) {
            console.log("Minting...");
            txn_test = await provider.getTransaction(Val.hash);
          }
          console.log("txn_test.blockNumber: " + txn_test.blockNumber);
          let mint_hash = "https://testnet.bscscan.com/tx/" + Val.hash;
          console.log("Mint link: " + mint_hash);
          const ID = await MintNFTContract.totalSupply();
          console.log("Token ID: ", ID.toString());
          console.log("this is Token ID: 10000" + ID.toString());
          console.log("this is Contract Address: : " + mintAddressTestnet);

          let details = { mint: mint_hash, Id: ID };
          console.log(details);

          if (ID.toString() < 10) {
            return {
              mint_hash: mint_hash,
              ID: "100000" + ID.toString(),
              mintPrice: data.price,
            };
          } else {
            return {
              mint_hash: mint_hash,
              ID: "10000" + ID.toString(),
              mintPrice: data.price,
            };
          }
        } else {
          console.log("No ethereum object");
        }
      }
    } catch (error) {
      console.log(error);
      throw new Error("No ethereum object");
    }
  };

  ethereum &&
    ethereum.on("accountsChanged", async (account) => {
      console.log(
        "🚀 ~ file: GalaraceContext.js:690 ~ ethereum.on ~ account:",
        account[0]
      );
      setCurrentAccount(account[0]);
      getBUSDTokenBalance();
      const start = account[0]?.substring(0, 6);
      const end = account[0]?.substring(account[0].length - 4);
      return swal({
        title: "success",
        text: `Account changed to ${start}...${end} successfully`,
        icon: "success",
        button: "OK",
        dangerMode: true,
        className: "modal_class_success",
      });
    });

  // const getPriceOfCategory = async (price) => {
  //   try {
  //     const res = await getMintingContract().getPrice(price);
  //     var data = ethers.utils.formatEther(res.toString());
  //     return data;
  //   } catch (err) {
  //     console.log("Error in Category", err);
  //   }
  // };

  // const getCategory = async (id) => {
  //   try {
  //     const res = await getMintingContract().categories(id);
  //     var data = res.toString();
  //     return data;
  //   } catch (err) {
  //     console.log("Error in Category", err);
  //   }
  // };

  const getCategoryPrice = async (id) => {
    try {
      const res = await getTokenMintNftContract().getOptionPrice(id);
      var data = res.toString();
      // var data = ethers.utils.formatEther(res.toString());
      console.log(
        "🚀 ~ file: GalaraceContext.js:695 ~ getCategoryPrice ~ data:",
        data
      );
      return data;
    } catch (err) {
      console.log("Error in Category", err);
    }
  };

  const mintTokenNftTest = async (data, categoryId) => {
    let state = { status: "", message: "", return: "", data: "" };

    try {
      let CategoryPrice = await getCategoryPrice(categoryId);
      let provider = new ethers.providers.Web3Provider(ethereum);

      var temp = data.uri.replace(
        "https://backend.galarace.ai/assets/json/",
        ""
      );

      let transaction;

      try {
        const estimatedGasLimit =
          await getTokenMintNftContract().estimateGas.buyNFT(
            temp,
            categoryId,
            CategoryPrice,
            {
              from: currentAccount,
            }
          );

        transaction = await getTokenMintNftContract().buyNFT(
          temp,
          categoryId,
          CategoryPrice,
          {
            from: currentAccount,
            gasLimit: estimatedGasLimit,
            gasPrice: null,
          }
        );
      } catch (error) {
        console.log("mint failed", error.code);
        if (error.code === "ACTION_REJECTED") {
          console.log("User rejected the transaction.");
          state.status = "warning";
          state.message = "User rejected the transaction.";
          state.return = false;
          return state;
        } else if (error.code === -32603) {
          console.log("insufficient funds for transfer");
          state.status = "warning";
          state.message = "Insufficient funds for transfer.";
          state.return = false;
          return state;
        } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
          console.log("🚀 ~ cant estimate gas :");
          transaction = await getTokenMintNftContract().buyNFT(
            temp,
            categoryId,
            CategoryPrice,
            {
              from: currentAccount,
              gasLimit: 1000000,
              gasPrice: null,
            }
          );
        } else {
          console.log("mint failed", error);
          state.status = "warning";
          state.message = "Transaction failed.";
          state.return = false;
          return state;
        }
      }

      transaction.wait();
      let txn_test = await provider.getTransaction(transaction.hash);
      while (txn_test.blockNumber === null) {
        console.log("Minting...");
        txn_test = await provider.getTransaction(transaction.hash);
      }

      const receipt = await provider.getTransactionReceipt(txn_test.hash);
      if (receipt.status === 1) {
        let mint_hash = "https://bscscan.com/tx/" + transaction.hash;
        let ID = await getTokenMintNftContract().totalSupply();
        let IdNumber = 10000 + parseInt(ID);

        let object = {
          mint_hash,
          IdNumber,
          mintPrice: data.price,
        };
        state.status = "Success";
        state.message = "Transaction successful";
        state.data = object;
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = "warning";
        state.message = "Transaction unsuccessful";
        state.return = false;
        return state;
      }
    } catch (error) {
      console.log("mint failed", error.code);
      if (error.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  // const mintTokenNft = async (data, categoryId) => {
  //   try {
  //     let CategoryPrice = await getCategoryPrice(categoryId);

  //     if (ethereum) {
  //       const chainid = await window.ethereum.request({
  //         method: "eth_chainId",
  //       });
  //       console.log("This is Chain ID: ", chainid);
  //       if (chainid === "0x38" || chainid === "0x61") {
  //         const provider = new ethers.providers.Web3Provider(ethereum);

  //         const object = {
  //           id: data.id,
  //           price: data.price,
  //           tokenAddress: data.tokenAddress,
  //           walletAddress: data.walletAddress,
  //           refAddress: data.refAddress,
  //           nonce: data.nonce,
  //           uri: data.uri,
  //           signature: data.signature,
  //         };

  //         var temp = data.uri.replace(
  //           "https://backend.galarace.ai/assets/json/",
  //           ""
  //         );

  //         const Val = await getTokenMintNftContract().buyNFT(
  //           temp,
  //           categoryId,
  //           CategoryPrice,
  //           {
  //             from: currentAccount,
  //             gasLimit: 210000,
  //             // gasLimit: 3000000,
  //             gasPrice: null,
  //           }
  //         );
  //         await Val.wait();
  //         let txn_test = await provider.getTransaction(Val.hash);
  //         while (txn_test.blockNumber === null) {
  //           console.log("Minting...");
  //           txn_test = await provider.getTransaction(Val.hash);
  //         }
  //         console.log("txn_test.blockNumber: " + txn_test.blockNumber);
  //         let mint_hash = "https://testnet.bscscan.com/tx/" + Val.hash;
  //         console.log("Mint link: " + mint_hash);
  //         const ID = await getTokenMintNftContract().totalSupply();
  //         console.log("Token ID: ", ID.toString());
  //         console.log("this is Token ID: 1000" + ID.toString());
  //         // console.log("this is Contract Address: : " + mintAddressTestnet);

  //         let details = { mint: mint_hash, Id: ID };
  //         console.log(details);

  //         return {
  //           mint_hash: mint_hash,
  //           ID: 10000 + parseInt(ID),
  //           mintPrice: data.price,
  //           address: "0x0000000000000000000000000000000000000000",
  //         };
  //       } else {
  //         console.log("No ethereum object");
  //         console.log("enter8");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw new Error("No ethereum object");
  //   }
  // };

  // const mintNft = async (data, categoryId, bool) => {
  //   let _idAdj;
  //   if (bool) {
  //     _idAdj = categoryId + 1;
  //   } else {
  //     _idAdj = categoryId;
  //   }
  //   console.log("🚀 ~ file: GalaraceContext.js:565 ~ mintNft ~ data:", data);
  //   try {
  //     let categoryNumber = parseInt(categoryId);
  //     let getCategoryinUsd = await getCategory(categoryNumber);
  //     let priceOfCategory = await getPriceOfCategory(getCategoryinUsd);

  //     if (ethereum) {
  //       const chainid = await window.ethereum.request({
  //         method: "eth_chainId",
  //       });
  //       console.log("This is Chain ID: ", chainid);
  //       if (chainid === "0x38" || chainid === "0x61") {
  //         const provider = new ethers.providers.Web3Provider(ethereum);

  //         const object = {
  //           id: data.id,
  //           price: data.price,
  //           tokenAddress: data.tokenAddress,
  //           walletAddress: data.walletAddress,
  //           refAddress: data.refAddress,
  //           nonce: data.nonce,
  //           uri: data.uri,
  //           signature: data.signature,
  //         };
  //         console.log("valueeee", object);

  //         var temp = data.uri.replace(
  //           "https://backend.galarace.ai/assets/json/",
  //           ""
  //         );

  //         const Val = await getMintingContract().buyNFT(temp, _idAdj, {
  //           from: currentAccount,
  //           value: ethers.utils.parseEther(`${priceOfCategory}`),
  //           gasLimit: 9000000,
  //           gasPrice: null,
  //         });
  //         await Val.wait();
  //         let txn_test = await provider.getTransaction(Val.hash);
  //         while (txn_test.blockNumber === null) {
  //           console.log("Minting...");
  //           txn_test = await provider.getTransaction(Val.hash);
  //         }
  //         console.log("txn_test.blockNumber: " + txn_test.blockNumber);
  //         let mint_hash = "https://testnet.bscscan.com/tx/" + Val.hash;
  //         console.log("Mint link: " + mint_hash);
  //         const ID = await getMintingContract().totalSupply();
  //         console.log("Token ID: ", ID.toString());
  //         console.log("this is Token ID: 1000" + ID.toString());
  //         // console.log("this is Contract Address: : " + mintAddressTestnet);

  //         let details = { mint: mint_hash, Id: ID };
  //         console.log(details);

  //         return {
  //           mint_hash: mint_hash,
  //           ID: 10000 + parseInt(ID),
  //           mintPrice: data.price,
  //           address: "0x0000000000000000000000000000000000000000",
  //         };
  //       } else {
  //         console.log("No ethereum object");
  //         console.log("enter8");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     throw new Error("No ethereum object");
  //   }
  // };

  const getWalletAccountAddress = async () => {
    // const accounts = await ethereum.request({ method: 'eth_accounts' });
    // if (accounts.length) {
    //   return accounts[0];
    // } else {
    //   openWalletModal();
    //   const accounts = await ethereum.request({ method: 'eth_accounts' });
    //   if (accounts.length) {
    //     getBUSDTokenBalance()
    //   }
    // }
    await window.ethereum.request({ method: "eth_accounts" });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    setCurrentAccount(address);
    return address;
  };

  const [busdBalance, setBusdBalance] = useState(null);
  const [claimReward, setClaimReward] = useState(null);

  const getBUSDTokenBalance = async () => {
    try {
      let accountAddress = await getWalletAccountAddress();
      const res = await getTokenContract().balanceOf(accountAddress);
      let data = ethers.utils.formatEther(res.toString());
      console.log(
        "🚀 ~ file: GalaraceContext.js:869 ~ getBUSDTokenBalance ~ data:",
        data
      );
      setBusdBalance(data);
      return data;
    } catch (err) {
      console.log("Balance Error", err);
    }
  };

  const getApproveTest = async (_address, value) => {
    let state = { status: "", message: "", return: "" };
    try {
      let address;

      if (_address == 1) {
        address = contractAddressStakeTokenVRF;
      } else if (_address == 2) {
        address = contractAddressMemberShip;
      } else if (_address == 3) {
        address = contractAddressNftTokenMinting;
      }

      let payment_test;
      let approve;
      const amount = ethers.utils.parseEther(value);
      const provider = new ethers.providers.Web3Provider(ethereum);

      try {
        const estimatedGasLimit = await getTokenContract().estimateGas.approve(
          address,
          amount,
          {
            from: currentAccount,
            gasPrice: ethers.utils.parseUnits("20", "gwei"),
          }
        );

        approve = await getTokenContract().approve(address, amount, {
          from: currentAccount,
          gasLimit: estimatedGasLimit,
          gasPrice: null,
        });
      } catch (error) {
        console.log("mint failed", error);
        if (error.code === "ACTION_REJECTED") {
          console.log("User rejected the transaction.");
          state.status = "warning";
          state.message = "User rejected the transaction.";
          state.return = false;
          return state;
        } else if (error.code === -32603) {
          console.log("insufficient funds for transfer");
          state.status = "warning";
          state.message = "Insufficient funds for transfer.";
          state.return = false;
          return state;
        } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
          console.log("🚀 ~ can't estimate gas");
          approve = await getTokenContract().approve(address, amount, {
            from: currentAccount,
            gasLimit: 1000000,
            gasPrice: null,
          });
        } else {
          console.log("mint failed", error);
          state.status = "warning";
          state.message = "Transaction failed.";
          state.return = false;
          return state;
        }
      }

      approve.wait();
      payment_test = await provider.getTransaction(approve.hash);
      while (payment_test.blockNumber === null) {
        console.log("Approve In Progress...");
        payment_test = await provider.getTransaction(approve.hash);
      }

      const receipt = await provider.getTransactionReceipt(approve.hash);

      if (receipt.status === 1) {
        console.log("Transaction successful");
        state.status = "Success";
        state.message = "Transaction successful";
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = "warning";
        state.message = "Transaction unsuccessful";
        state.return = false;
        return state;
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: GalaraceContext.js:1114 ~ getApproveTest ~ err:",
        err
      );
      if (err.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else {
        console.log("approve failed", err);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  // const getApprove = async (_address, value) => {
  //   try {
  //     let address;
  //     if (_address == 1) {
  //       address = contractAddressStakeTokenVRF;
  //     } else if (_address == 2) {
  //       address = contractAddressMemberShip;
  //     } else if (_address == 3) {
  //       address = contractAddressNftTokenMinting;
  //     }
  //     let payment_test;
  //     const amount = ethers.utils.parseEther(value);
  //     const provider = new ethers.providers.Web3Provider(ethereum);
  //     const approve = await getTokenContract().approve(address, amount);
  //     approve.wait();
  //     payment_test = await provider.getTransaction(approve.hash);
  //     while (payment_test.blockNumber === null) {
  //       console.log("Approve In Progress...");
  //       payment_test = await provider.getTransaction(approve.hash);
  //     }
  //     console.log(payment_test.blockNumber);
  //     return payment_test.blockNumber;
  //   } catch (err) {
  //     console.log("approve failed", err);
  //     const wrapper = document.createElement("div");
  //     wrapper.innerHTML = `<p>User rejected transaction.</p>`;
  //     swal({
  //       title: "Warning",
  //       content: wrapper,
  //       icon: "warning",
  //       button: "OK",
  //       className: "modal_class_success",
  //     });
  //   }
  // };

  const stakeBusd = async (value, number) => {
    let state = { status: "", message: "", return: "", data: "" };

    try {
      let transaction;
      const amount = ethers.utils.parseEther(value);
      const provider = new ethers.providers.Web3Provider(ethereum);

      try {
        const estimatedGasLimit =
          await getStakeTokenVRFContract().estimateGas.StakeBusd(
            amount,
            number,
            {
              from: currentAccount,
            }
          );

        transaction = await getStakeTokenVRFContract().StakeBusd(
          amount,
          number,
          {
            from: currentAccount,
            gasLimit: estimatedGasLimit,
            gasPrice: null,
          }
        );
      } catch (error) {
        console.log("stake failed", error.code);
        if (error.code === "ACTION_REJECTED") {
          console.log("User rejected the transaction.");
          state.status = "warning";
          state.message = "User rejected the transaction.";
          state.return = false;
          return state;
        } else if (error.code === -32603) {
          console.log("insufficient funds for transfer");
          state.status = "warning";
          state.message = "Insufficient funds for transfer.";
          state.return = false;
          return state;
        } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
          console.log("🚀 ~ cant estimate gas :");
          transaction = await getStakeTokenVRFContract().StakeBusd(
            amount,
            number,
            {
              from: currentAccount,
              gasLimit: 1000000,
              gasPrice: null,
            }
          );
        } else {
          console.log("stake failed", error);
          state.status = "warning";
          state.message = "Transaction failed.";
          state.return = false;
          return state;
        }
      }

      transaction.wait();
      let txn_test = await provider.getTransaction(transaction.hash);
      while (txn_test.blockNumber === null) {
        console.log("Stake In Progress...");
        txn_test = await provider.getTransaction(transaction.hash);
      }

      const receipt = await provider.getTransactionReceipt(txn_test.hash);
      if (receipt.status === 1) {
        let stake_hash = "https://bscscan.com/tx/" + transaction.hash;

        state.status = "Success";
        state.message = "Transaction successful";
        state.data = stake_hash;
        state.return = true;
        return state;
      } else if (receipt.status === 0) {
        console.log("Transaction unsuccessful");
        state.status = "warning";
        state.message = "Transaction unsuccessful";
        state.return = false;
        return state;
      }
    } catch (error) {
      console.log("mint failed", error.code);
      if (error.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  const getGameId = async () => {
    let state = { status: "", message: "", return: "", data: "" };
    try {
      const res = await getStakeTokenVRFContract().gameId();
      let data = res.toString();
      state.status = "Success";
      state.message = "Transaction successful";
      state.data = data;
      state.return = true;
      return state;
    } catch (error) {
      console.log("mint failed", error.code);
      if (error.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  const getUserRank = async (value) => {
    let state = { status: "", message: "", return: "", data: "" };
    try {
      let parameter = value;
      const res = await getStakeTokenVRFContract().results(parameter);
      let data = res.toString();
      let rank = parseInt(data);

      while (rank == 0) {
        console.log("User Rank In Progress...");
        const res = await getStakeTokenVRFContract().results(parameter);
        data = res.toString();
        rank = parseInt(data);
      }
      state.status = "Success";
      state.message = "Transaction successful";
      state.data = data;
      state.return = true;
      return state;
    } catch (error) {
      console.log("mint failed", error.code);
      if (error.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  const getFundswithdraw = async () => {
    let state = { status: "", message: "", return: "", data: "" };

    try {
      let transaction;
      const provider = new ethers.providers.Web3Provider(ethereum);
      let balance = await getBUSDTokenBalance();
      if (balance >= 1) {
        try {
          const estimatedGasLimit =
            await getStakeTokenVRFContract().estimateGas.withdraw({
              from: currentAccount,
            });

          transaction = await getStakeTokenVRFContract().withdraw({
            from: currentAccount,
            gasLimit: estimatedGasLimit,
            // gasPrice: null,
          });
        } catch (error) {
          console.log("withdraw failed", error.code);
          if (error.code === "ACTION_REJECTED") {
            console.log("User rejected the transaction.");
            state.status = "warning";
            state.message = "User rejected the transaction.";
            state.return = false;
            return state;
          } else if (error.code === -32603) {
            console.log("insufficient funds for transfer");
            state.status = "warning";
            state.message = "Insufficient funds for transfer.";
            state.return = false;
            return state;
          } else if (error.code === "UNPREDICTABLE_GAS_LIMIT") {
            console.log("🚀 ~ cant estimate gas :");
            transaction = await getStakeTokenVRFContract().withdraw({
              from: currentAccount,
              gasLimit: 1000000,
              gasPrice: null,
            });
          } else {
            console.log("withdraw failed", error);
            state.status = "warning";
            state.message = "Transaction failed.";
            state.return = false;
            return state;
          }
        }

        transaction.wait();
        let txn_test = await provider.getTransaction(transaction.hash);
        while (txn_test.blockNumber === null) {
          console.log("withdraw In Progress...");
          txn_test = await provider.getTransaction(transaction.hash);
        }

        gameRecordsBalance();
        const receipt = await provider.getTransactionReceipt(txn_test.hash);
        if (receipt.status === 1) {
          let withdraw_hash = "https://bscscan.com/tx/" + transaction.hash;

          state.status = "Success";
          state.message = "Transaction successful";
          state.data = withdraw_hash;
          state.return = true;
          return state;
        } else if (receipt.status === 0) {
          console.log("Transaction unsuccessful");
          state.status = "warning";
          state.message = "Transaction unsuccessful";
          state.return = false;
          return state;
        }
      } else {
        console.log("You don't have insufficient funds to withdraw.");
        state.status = "warning";
        state.message = "You don't have insufficient funds to withdraw.";
        state.return = false;
        return state;
      }
    } catch (error) {
      gameRecordsBalance();
      console.log("mint failed", error.code);
      if (error.code === "ACTION_REJECTED") {
        console.log("User rejected the transaction.");
        state.status = "warning";
        state.message = "User rejected the transaction.";
        state.return = false;
        return state;
      } else if (error.code === -32603) {
        console.log("insufficient funds for transfer");
        state.status = "warning";
        state.message = "Insufficient funds for transfer.";
        state.return = false;
        return state;
      } else {
        console.log("mint failed", error);
        state.status = "warning";
        state.message = "Transaction failed.";
        state.return = false;
        return state;
      }
    }
  };

  const gameRecordsBalance = async () => {
    try {
      let accountAddress = await getWalletAccountAddress();
      const res = await getStakeTokenVRFContract().balances(accountAddress);
      let data = ethers.utils.formatEther(res.toString());
      console.log("🚀 ~ file: gameRecords ~ data:", data);
      setClaimReward(data);
      return data;
    } catch (error) {
      console.log("Error in gameRecordsBalance", error);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>Something went wrong.</p>`;
      swal({
        title: "Warning",
        content: wrapper,
        icon: "warning",
        button: "OK",
        className: "modal_class_success",
      });
    }
  };

  const getMemberShip = async (_id) => {
    try {
      const res = await getMemberShipContract().memberships(_id);
      var data = res.toString();
      console.log(
        "🚀 ~ file: GalaraceContext.js:983 ~ getMemberShip ~ data:",
        data
      );
      return data;
    } catch (err) {
      console.log("Error in MemberShip", err);
    }
  };

  const buyMembership = async (_option, value) => {
    try {
      let payment_test;
      const amount = ethers.utils.parseEther(value);
      const provider = new ethers.providers.Web3Provider(ethereum);
      const buy = await getMemberShipContract().buyMembership(_option, amount, {
        from: currentAccount,
        gasLimit: 210000,
        // gasLimit: 3000000,
        gasPrice: null,
      });
      buy.wait();
      payment_test = await provider.getTransaction(buy.hash);
      while (payment_test.blockNumber === null) {
        console.log("buy Membership In Progress...");
        payment_test = await provider.getTransaction(buy.hash);
      }
      console.log("txn_test.blockNumber: " + payment_test.blockNumber);
      let buy_hash = "https://testnet.bscscan.com/tx/" + buy.hash;
      console.log("Mint link: " + buy_hash);
      return buy_hash;
    } catch (err) {
      console.log("stakeBusd failed", err);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>Something went wrong.</p>`;
      swal({
        title: "Warning",
        content: wrapper,
        icon: "warning",
        button: "OK",
        className: "modal_class_success",
      });
      return false;
    }
  };

  return (
    <GalagamesContext.Provider
      value={{
        currentAccount,
        loginModal,
        setLoginModal,
        requestLoading,
        setRequestLoading,
        walletModal,
        user,
        setUser,
        logOut,
        loading,
        mintTicketNFTTestnetBNB,
        Id,
        // signBuyFunction,
        setID,
        setUserRefetch,
        userRefetch,
        chain,
        pageLoading,
        payAmount,
        setPayAmount,
        metamaskBalance,
        coinbaseModal,
        metamaskBalanceLoading,
        getBalanceTestnet,
        closeWalletModal,
        closeCoinbaseModal,
        openWalletModal,
        openCoinbaseModal,
        openLoginModal,
        closeLoginModal,
        setMetamaskBalanceLoading,
        connectToMetamask,
        mintAddressTestnet,
        DSLtokenAddressTestnet,
        USDSCtokenAddressTestnet,
        S39tokenAddressTestnet,
        QuesttokenAddressTestnet,
        signBuyFunction,
        goToProfile,
        setGoToProfile,
        allUsers,
        setAllUsers,
        balanceS39,
        setS39Balance,
        token,
        setToken,
        // ----------------------------
        busdBalance,
        claimReward,
        // mintTokenNft,
        getBUSDTokenBalance,
        // getApprove,
        stakeBusd,
        getGameId,
        getUserRank,
        getFundswithdraw,
        gameRecordsBalance,
        setCurrentAccount,
        buyMembership,
        getApproveTest,
        mintTokenNftTest,
        contractAddressNftTokenMinting,
      }}
    >
      {children}
    </GalagamesContext.Provider>
  );
}
