import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from "../../Components/LoginButton/LoginButton";
import { GalagamesContext } from "../../Context/GalaraceContext";
import { faL } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import axios from "axios";

const WinBusdGRTStacking = () => {
  const [stakeAmount, setstakeAmount] = useState(1);

  const navigate = useNavigate();
  const {
    user,
    busdBalance,
    getBUSDTokenBalance,
    getApprove,
    stakeBusd,
    setRequestLoading,
    getUserRank,
    getGameId,
    openWalletModal,
    requestLoading,
  } = useContext(GalagamesContext);

  useEffect(() => {
    getBUSDTokenBalance();
  }, []);

  const StakeBusdFun = async () => {
    axios
      .post(
        `https://backend.galarace.ai/api/v1/payment/grt/${user?.email}`,
        {
          value: -stakeAmount,
        }
      )
      .then((res) => {
        axios
          .post("https://backend.galarace.ai/api/v1/makepayment", {
            walletAddress: user?.walletAddress||"X4serw4dsgfgf42rafsd",
            paymentName: "grt",
            email: user?.email,
            paymentAmount: stakeAmount,
            transactionId: "4x0146s5",
            remarks: "Stake for GRT race",
          })
          .then((res) => {
            navigate("/galarace-grt", {
              state: {
                name: "busdstake",
                stake: stakeAmount,
              },
            });
          });
      });
  };

  const login = "grtstake";
  return (
    <div style={{ minHeight: "100vh" }}>
      <h2 className="text-white text-center mt-4 mb-5 page-title">
        WIN GRT
      </h2>
      <div>
        <p className="text-white text-center">
          You can stake GRT and win GRT.
       
        </p>
        <div className="text-center">
          <img
            src="/grtstake.jpeg"
            width={350}
            height={350}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="winnings-for-this-race">
          <p className="mb-2 fw-bold"> If you stake 1 GRT,<br/> you can win as follows.</p>
          <p className="mb-2 fw-bold">Winnings</p>
          <p style={{ color: "#00C4FF" }} className="mb-0">
            1st: 20 Times
          </p>
          <p style={{ color: "#FF8551" }} className="mb-0">
            2nd: 10 Times
          </p>
          <p style={{ color: "#DD58D6" }} className="mb-0">
            3rd: 5 Times
          </p>
          <p style={{ color: "#C1D0B5" }} className="mb-0">
            4th: 3 Times
          </p>
          <p style={{ color: "#73A9AD" }} className="mb-0">
            5th: 2 Times
          </p>
        </div>
        {/* <p className="text-white text-center">
          {" "}
          If 1 BUSD, if the user comes in the first, can win 20 BUSD.
        </p> */}
        <div>
          <div className="d-flex justify-content-center flex-column">
            <label for={`tentacles`} className="mx-auto text-white text-center">
              {" "}
              Please enter how much GRT <br/> you want to stake.
            </label>
            {user?.email && <label for={`tentacles`} className="mx-auto text-white">
              {" "}
              Your GRT Balance: <span className="text-warning">{user?.grtBalance>0?user?.grtBalance?.toFixed(2):"000"}</span> 
            </label>}
            <input
              type="number"
              value={stakeAmount}
              onChange={(e) => {
                if (/^0\d+/.test(e.target.value)) {
                  const value = e.target.value.replace(/^0/, '1')
                  setstakeAmount(value);
                }
                else {
                  const value =
                    e.target.value == 0
                      ? 1
                      : parseInt(e.target.value.replace(/\./g, ""));
                  if (value > user?.grtBalance) {
                    setstakeAmount(parseInt(user?.grtBalance));
                  }
                  else {
                    setstakeAmount(value);
                  }
                }
              }}
              id={`tentacles`}
              className="mx-auto mt-2 text-dark width-busd-input"
              name={`tentacles`}
              min="1"
              step="1"
              pattern="\d+"
              oninput="validity.valid||(value='');"
            />
          </div>
          <div className="text-center mt-3">
            {!user?.email ? (
              <button
                className="text-uppercase button-metamask px-5"
                // onClick={() =>
                //   user?.email ? openWalletModal() : navigate("/sign-in", { state: { goBack: true } })
                // }
                onClick={() =>
                 navigate("/sign-in", { state: { goBack: true } })
                }
              >
                {/* {" "}
             <img
               style={{
                 marginLeft: "-15px",
                 marginRight: "10px",
                 // marginTop: "5px",
               }}
               src={coin}
               alt=""
             />{" "} */}
                STAKE GRT NOW
              </button>
            ) : (
              <button
                className={`${stakeAmount > user?.grtBalance || stakeAmount < 1
                  ? "btn-danger"
                  : "start-button"
                  }`}
                disabled={stakeAmount > user?.grtBalance || stakeAmount < 1}
                onClick={() => {
                  StakeBusdFun();
                  // navigate("/galarace-busd", {
                  //   state: {
                  //     name: "busdstake",
                  //     stake: stakeAmount,
                  //     position: 12,
                  //   },
                  // })
                }}
                style={{ minWidth: "185px", position: "relative", zIndex: 3 }}
              >
                Stake {stakeAmount} GRT
              </button>
            )}
            {user?.grtBalance < 1 && <div className="text-center mt-3">
              <p className="text-white m-0">You don't have enough GRT.</p>
              <p className="text-white m-0">Purchase our membership</p>
            </div>}
            {user?.grtBalance < 1 && <button
              className="btn btn-warning mt-2"
              onClick={() => {
                navigate("/memberships")
              }}
              style={{ minWidth: "185px", position: "relative", zIndex: 3 }}
            >
              Buy Membership
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinBusdGRTStacking;


