import React from 'react';
import "./AdminGRT.css"
import axios from 'axios';
import swal from 'sweetalert';
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const AdminGRT = () => {
  const form = document.getElementById('my_form');
const navigate=useNavigate()
  const sendGrt = (e) =>{
    e.preventDefault()
    const email = e.target.email.value;
    const grt = e.target.grt.value;

    axios.post(`https://backend.galarace.ai/api/v1/payment/grt/${email}`, {value: grt}).then((res)=> {
      if(res.status === 200){
        let content2 = document.createElement("p");
        content2.innerHTML = `GRT sent to ${email}`;
        swal({
          content: content2,
          icon: "success",
          button: "OK",
          className: "modal_class_success",
        }).then((willDelete)=> {
          if(willDelete){
            form.reset()
          }
        })
      }
    }).catch((err)=>{
      swal({
        text: "Something went wrong!",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    })
  }

    return (
    <div className="adminBody">
        <h5 className="dashboard-title">GRT</h5>
        <div className="">
          <Button
            variant="contained"
            xs={{ size: "large" }}
            sx={{ mt: "1rem", mb: "2rem" }}
            style={{ background: '#6958BE' }}
            onClick={() => navigate("/admin/grt/history")}
          >
            GRT History
          </Button>
        </div>
       <div className='row'>
        <div className='col-12 col-lg-3'>
        <div className="container-card p-5">
          <h6 className='text-white'>Total GRT</h6>
          <h4 className='text-white mb-0 text-bold'>1300</h4>
        </div>
          </div>
          <div className='col-12 col-lg-3'>
          <div className="container-card p-5 mt-3 mt-lg-0">
          <h6 className='text-white'>GRT spend this month</h6>
          <h4 className='text-white mb-0 text-bold'>1300</h4>
        </div>
        </div>
       </div>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <form id='my_form' onSubmit={sendGrt} className="send-grt-form container-card mt-3 p-5">
            <h6 className='text-white mb-3'>Send User GRT</h6>
          <label htmlFor="">Email</label>
          <input type="email" name="email" />
          <label htmlFor="">GRT</label>
          <input min={1} type="number" name="grt" />
          <button className='btn btn-primary btn-sm'>Send</button>
        </form>
          </div>
       </div>
    </div>
    );
};

export default AdminGRT;