import React from "react";
import oppertunityImg from "../../../Assets/Images/opportunities image.png";
import "./Oppertunities.css";
import { useNavigate } from "react-router-dom";

const Oppertunities = () => {
  const navigate = useNavigate();

  return (
    <div className="container mt-4">
      <div className="opppertunities-container">
        <h3>OPPORTUNITIES TO BE THE NEXT MILLIONAIRE</h3>
        <p>
          With our revolutionary program, you can become a millionaire in no time. Our program is designed to help you reach your financial goals and become a millionaire in record time. With our step-by-step guidance, you can achieve financial freedom and live the life you've always dreamed of.
        </p>
        <button onClick={() => navigate("/sign-up")} className="invest-button" style={{ backgroundColor: "rgb(153 81 81)" }}>
        GET STARTED
        </button>
      </div>
    </div>
  );
};

export default Oppertunities;
