import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnityContext } from 'react-unity-webgl';
import swal from 'sweetalert';
import Game3D from '../Game3D/Game3D';
import Button from '@mui/material/Button';

const Replays = () => {
    const [timeOuts, setTimeouts] = useState('')
    let newTimeId;
    const { unityProvider, isLoaded, loadingProgression, sendMessage, requestFullscreen
    } = useUnityContext({
        loaderUrl: './Build/Galarace 1.6 - Brotli.loader.js',
        dataUrl: "./Build/Galarace 1.6 - Brotli.data.unityweb",
        frameworkUrl: "./Build/Galarace 1.6 - Brotli.framework.js.unityweb",
        codeUrl: "./Build/Galarace 1.6 - Brotli.wasm.unityweb",
    });
    const navigate = useNavigate();

    useEffect(() => {
        console.log("in the page")
        return () => {
            console.log("outside the page")
            if (unityProvider) {
                sendMessage("GameManager", "QuitGame")
                clearTimeout(newTimeId)
            }
        };
    }, [isLoaded, unityProvider]);

    const raceReplay = () => {
        newTimeId=  setTimeout(() => {
            swal({
                title: `REPLAY`,
                text: "Do you want to replay again!",
                icon: "warning",
                buttons: ["Yes", "No"],
                className: "your-footer-class",
                dangerMode: true,
            }).then(function (isConfirm) {
                if (!isConfirm) {
                    // navigate("/horsechamps",{state:{name:'replay'}}); 
                    sendMessage("GameManager", "WebReStartBtnClickedForTMP", 8);
                    sendMessage("GameManager", "SwitchCamContinuously");
                    raceReplay()
                }
            });

        }, 48000)
    }
    useEffect(() => {
        if (isLoaded == true) {
            sendMessage("GameManager", "WebReStartBtnClickedForTMP", 8);
            sendMessage("GameManager", "SwitchCamContinuously");
            // sendMessage("GameManager" , "WebReplay" , 2);
            raceReplay();
        }

    }, [isLoaded])
    return (
        <>
            <div className='container replay-container'>
                <div style={{ paddingTop: "40px", position: 'relative' }} className="replays text-center">
                    {/* <h5 onClick={() => {
                    navigate("/racing", { state: { name: 'replay', autoOpen: true } });
                    sendMessage("GameManager", "QuitGame"); clearTimeout(timeOuts)
                }} className='text-white mt-2' style={{ position: 'absolute', right: 0, cursor: 'pointer' }}>X</h5> */}
                    <h1 className='text-white text-center pb-4' style={{ fontSize: "2rem" }}>REPLAY</h1>
                    <div>
                        {isLoaded && <img src='https://img.icons8.com/color/48/null/switch-camera.png' onClick={() => sendMessage("Canvas", "ChangeCam")} class="fa-expand"></img>}
                        <Game3D

                            unityProvider={unityProvider}
                            isLoaded={isLoaded}
                            loadingProgression={loadingProgression}
                            sendMessage={sendMessage}
                        ></Game3D>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <Button
                        onClick={() => {
                            navigate("/horsechamps", { state: { name: 'replay', autoOpen: true } });
                            sendMessage("GameManager", "QuitGame"); clearTimeout(newTimeId)
                        }}
                        className="btn-main bg-danger lead text-white"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Replays;