import React from 'react';
import "./WinBusdDemo.css"
import { Box, FormControl, InputLabel, Paper, } from '@mui/material';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const WinBusdDemo = () => {
  const [focus, setFocus] = useState(false);
  const [focus1, setFocus1] = useState(false);
  const [biddingDog, setBiddingDog] = useState(null);
  const [howMuchToStack, setHowMuchToStack] = useState(100)
  const [bid, setBid] = useState("");
  const [myDog, setMyDog] = useState({});
  const navigate = useNavigate();

  const checkInput = () => {
    swal({
      // title: "S",
      // text: "Select the dog and stake amount!",
      text: "Please select the horse!",
      icon: "warning",
      button: "OK!",
      className: "modal_class_success",
    });
  };

  //  the dog object
  const dogsImg = [
    {
      img: "https://i.ibb.co/RNgCqsp/skyblue-male.png",
      probability: 0,
      strength: 75,
      gender: "Male Sky blue",
      track: "1",
    },
    {
      img: "https://i.ibb.co/vv7f4SL/skyblue-female.png",
      probability: 0,
      strength: 80,
      gender: "Female Sky blue",
      track: "2",
    },
    {
      img: "https://i.ibb.co/yR920bL/red-male.png",
      probability: 0,
      strength: 82,
      gender: "Male Red",
      track: "3",
    },
    {
      img: "https://i.ibb.co/VLLvS5f/red-female.png",
      probability: 0,
      strength: 75,
      gender: "Female Red",
      track: "4",
    },
    {
      img: "https://i.ibb.co/4pL7Wp9/orange-male.png",
      probability: 0,
      strength: 70,
      gender: "Male Yellow",
      track: "5",
    },
    {
      img: "https://i.ibb.co/P4fL15q/orange-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Yellow",
      track: "6",
    },
    {
      img: "https://i.ibb.co/Vw0G5N8/navyblue-male.png",
      probability: 0,
      strength: 79,
      gender: "Male Navy",
      track: "7",
    },
    {
      img: "https://i.ibb.co/rZG1V0s/navyblue-female.png",
      probability: 0,
      strength: 78,
      gender: "Female Navy",
      track: "8",
    },
    {
      img: "https://i.ibb.co/wMHtJKy/magenta-male.png",
      probability: 0,
      strength: 76,
      gender: "Male Magenta",
      track: "9",
    },
    {
      img: "https://i.ibb.co/NZ51yD4/magenta-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Magenta",
      track: "10",
    },
    {
      img: "https://i.ibb.co/mTzs5zN/lime-male.png",
      probability: 0,
      strength: 83,
      gender: "Male Lime",
      track: "11",
    },
    {
      img: "https://i.ibb.co/VND8pfj/lime-female.png",
      probability: 0,
      strength: 71,
      gender: "Female Lime",
      track: "12",
    },
    {
      img: "https://i.ibb.co/KyzjLsq/grey-male.png",
      probability: 0,
      strength: 74,
      gender: "Male White",
      track: "13",
    },
    {
      img: "https://i.ibb.co/vBzh7Zt/grey-female.png",
      probability: 0,
      strength: 78,
      gender: "Female White",
      track: "14",
    },
    {
      img: "https://i.ibb.co/D8kxx8v/green-male.png",
      probability: 0,
      strength: 77,
      gender: "Male Green",
      track: "15",
    },
    {
      img: "https://i.ibb.co/g6nJ1W4/green-female.png",
      probability: 0,
      strength: 75,
      gender: "Female Green",
      track: "16",
    },
    {
      img: "https://i.ibb.co/bR9gM7G/brown-male.png",
      probability: 0,
      strength: 78,
      gender: "Male Orange",
      track: "17",
    },
    {
      img: "https://i.ibb.co/jkK6v6z/brown-female.png",
      probability: 0,
      strength: 73,
      gender: "Female Orange",
      track: "18",
    },
    {
      img: "https://i.ibb.co/KVxW5Fy/aquagreen-male.png",
      probability: 0,
      strength: 75,
      gender: "Male Aqua Green",
      track: "19",
    },
    {
      img: "https://i.ibb.co/4mcBhBF/aquagreen-female.png",
      probability: 0,
      strength: 76,
      gender: "Female Aqua Green",
      track: "20",
    },
  ];

  const handleChange = (event) => {
    setBiddingDog(event.target.value);
    setFocus(false)
    const select = dogsImg.find((dog) => dog.img === event.target.value);
    // console.log(select);
    setMyDog(select);
  };


  const useStyles = makeStyles((theme) => ({
    paper: {
      background: 'linear-gradient(to right bottom, #1b1d22, #2A2E35)',
      color: 'white'
    }
  }));

  const classes = useStyles();

  return (
    <div className='container'>
      <h2 className="text-white text-center mt-4 mb-5 page-title">WIN USDT (BEP20) (DEMO)</h2>

      <div>
        <div className="horse-selection">
          <span className='custom-horse-select-label'> HORSE </span>
          <FormControl fullWidth>
            {/* {focus || biddingDog ? <InputLabel id="demo-simple-select-label" className='text-white'>HORSE</InputLabel> : <InputLabel id="demo-simple-select-label" className='text-white  '>Select the horse you want to stake</InputLabel>} */}
            {!focus && <InputLabel id="demo-simple-select-label" className='text-white  '> Select the horse you want to stake </InputLabel>}
            <Select
              onFocus={() => setFocus(!focus)}
              MenuProps={{
                classes: {
                  paper: classes.paper
                }
              }}
              inputProps={{
                classes: {
                  root: classes.input
                }
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={biddingDog}
              onChange={(e) => { handleChange(e); }}
              sx={{
                textAlign: 'left',
                color: "white",
                fontSize: '14px',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                }
              }}
            >
              {
                dogsImg.map(dog => <MenuItem className='my-2' value={dog.img}>
                  <div className='selection-dog d-flex justify-content-start align-items-center'>
                    <img className='dog dog-size mb-0' src={dog?.img} alt="dog"></img>
                    <p className='ps-4 mb-0 text-light' >{dog?.gender}</p>
                  </div></MenuItem>)
              }
            </Select>
          </FormControl >
        </div>


        <div className="horse-selection mt-4">
          <FormControl fullWidth>
            {focus1 || howMuchToStack ? <InputLabel id="races" htmlFor="race" className='text-white'>USDT (BEP20) </InputLabel> : <InputLabel id="races" htmlFor="race" className='text-white'>Select how much USDT (BEP20) to stake </InputLabel>}
            <Select
             id="race"
             label="USDT (BEP20)"
              onFocus={() => setFocus1(!focus1)}
              MenuProps={{
                classes: {
                  paper: classes.paper
                }
              }}
              inputProps={{
                classes: {
                  root: classes.input
                }
              }}
              value={howMuchToStack}
              onChange={(e) => setHowMuchToStack(e.target.value)}
              sx={{
                textAlign: 'left',
                color: "white",
                fontSize: '14px',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                }
              }}
            >
              <MenuItem className='my-2' value={1}>
                <div className='selection-dog d-flex justify-content-start align-items-center'>
                  <p className='mb-0 text-light' >1 USDT (BEP20)</p>
                </div>
              </MenuItem>
              <MenuItem className='my-2' value={5}>
                <div className='selection-dog d-flex justify-content-start align-items-center'>
                  <p className='mb-0 text-light' >5 USDT (BEP20)</p>
                </div>
              </MenuItem>
              <MenuItem className='my-2' value={10}>
                <div className='selection-dog d-flex justify-content-start align-items-center'>
                  <p className='mb-0 text-light' >10 USDT (BEP20)</p>
                </div>
              </MenuItem>
              <MenuItem className='my-2' value={100}>
                <div className='selection-dog d-flex justify-content-start align-items-center'>
                  <p className='mb-0 text-light' >100 USDT (BEP20)</p>
                </div>
              </MenuItem>
            </Select>
          </FormControl >
        </div>

        <div className="winnings-for-this-race">
          <p className='mb-2 fw-bold'>Winnings For This Race</p>
          <p style={{ color: "#00C4FF" }} className='mb-0'>1st: {howMuchToStack * 20} USDT (BEP20)</p>
          <p style={{ color: "#FF8551" }} className='mb-0'>2nd: {howMuchToStack * 10} USDT (BEP20)</p>
          <p style={{ color: "#DD58D6" }} className='mb-0'>3rd: {howMuchToStack * 5} USDT (BEP20)</p>
          <p style={{ color: "#C1D0B5" }} className='mb-0'>4th: {howMuchToStack * 3} USDT (BEP20)</p>
          <p style={{ color: "#73A9AD" }} className='mb-0'>5th: {howMuchToStack * 2} USDT (BEP20)</p>
        </div>
        <p className="text-white mt-2 text-center">In this race you will not win anything. <span className='text-warning'>This is a demo on how our platform works</span></p>
        <div className="buttons-container">
          <button className='start-button ' style={{ minWidth: "150px", position: "relative", zIndex: 3 }}><button className='linked1' style={{ display: "block", minWidth: "75px" }} onClick={() => { biddingDog ? navigate("/galarace-demo1", { state: { name: "demo", dog: myDog, stake: howMuchToStack } }) : checkInput() }}></button> <button className='linked2' style={{ display: "block", minWidth: "75px" }} onClick={() => { biddingDog ? navigate("/galarace-demo", { state: { name: "demo", dog: myDog, stake: howMuchToStack } }) : checkInput() }}></button>PREPARE FOR RACE</button>
          <button className='cancel-button' style={{ minWidth: "150px", position: "relative", zIndex: 3 }}>CANCEL</button>
        </div>
      </div>
    </div>
  );
};

export default WinBusdDemo;