import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from "../../Components/LoginButton/LoginButton";
import { GalagamesContext } from "../../Context/GalaraceContext";
import { faL } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import axios from "axios";

const WinBusdBUSDStacking = () => {
  const [stakeAmount, setstakeAmount] = useState(1);

  const navigate = useNavigate();
  const {
    user,
    busdBalance,
    getBUSDTokenBalance,
    getApproveTest,
    stakeBusd,
    setRequestLoading,
    getUserRank,
    getGameId,
    requestLoading,
    connectToMetamask,
    walletModal,
    openWalletModal,
  } = useContext(GalagamesContext);

  useEffect(() => {
    getBUSDTokenBalance();
  }, []);

  const errorRepresentationFunction = (Status) => {
    return swal({
      title: "Attention",
      text: Status.message,
      icon: Status.status,
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  const StakeBusdFun = async () => {
    try {
      let busdBalanceNumber = parseInt(busdBalance);
      let stakeAmountNumber = parseInt(stakeAmount);

      console.log("🚀 ~ stakeAmount:", stakeAmount, typeof stakeAmount);
      let stakeAmountString = String(stakeAmount);
      if (busdBalanceNumber >= stakeAmountNumber) {
        console.log("balance checked");
        setRequestLoading(true);

        let approve = await getApproveTest(1, stakeAmountString);
        if (approve.return) {
          let gameId = await getGameId();
          if (gameId.return) {
            gameId.data++;

            const getData = await axios.get(
              "https://backend.galarace.ai/randomNumber"
            );
            if (getData.status === 200) {
              let stake = await stakeBusd(
                stakeAmountString,
                getData.data.randomNumber
              );
              if (stake.return) {
                let userRank = await getUserRank(gameId.data);
                if (userRank.return) {
                  console.log("🚀:", userRank.data, getData.data.randomNumber);
                  getBUSDTokenBalance();
                  setRequestLoading(false);
                  const wrapper = document.createElement("div");
                  wrapper.innerHTML = `
                  <a href=${stake.data} target="_any" className="link_hash">${stake.data}</a>
                  <br/> <p>Your Funds are successfully deposited.</p>`;
                  swal({
                    title: "Success",
                    content: wrapper,
                    icon: "success",
                    button: true,
                    className: "modal_class_success",
                  }).then((willDelete) => {
                    if (willDelete) {
                      axios
                        .post(
                          "https://backend.galarace.ai/api/v1/makepayment",
                          {
                            walletAddress: user?.walletAddress,
                            paymentName: "busd",
                            email: user?.email,
                            paymentAmount: stakeAmountNumber,
                            transactionId: "4x0146s5",
                            remarks: "Stake for USDT (BEP20) race",
                          }
                        )
                        .then((res) => {
                          navigate("/galarace-busd", {
                            state: {
                              name: "busdstake",
                              stake: stakeAmount,
                              position: userRank.data,
                            },
                          });
                        });
                    } else {
                      console.log("ok");
                    }
                  });
                } else {
                  console.log("userRank ==>", userRank.message);
                  setRequestLoading(false);
                  errorRepresentationFunction(userRank);
                  return false;
                }
              } else {
                console.log("stake ==>", stake.message);
                setRequestLoading(false);
                errorRepresentationFunction(stake);
                return false;
              }
            } else {
              console.log("api data failed ==>", getData.status);
              setRequestLoading(false);
              let obj = {
                status: "Failed",
                message: getData.message,
              };
              errorRepresentationFunction(obj);
              return false;
            }
          } else {
            console.log("gameId ==>", gameId.message);
            setRequestLoading(false);
            errorRepresentationFunction(gameId);
            return false;
          }
        } else {
          console.log("approve ==>", approve.message);
          setRequestLoading(false);
          errorRepresentationFunction(approve);
          return false;
        }
      } else {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p>Insufficient Funds.</p>`;
        swal({
          title: "Failed",
          content: wrapper,
          icon: "warning",
          button: "OK",
          className: "modal_class_success",
        });
      }
    } catch (error) {
      setRequestLoading(false);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p>Something went wrong.</p>`;
      swal({
        title: "Failed",
        content: wrapper,
        icon: "warning",
        button: "OK",
        className: "modal_class_success",
      });
    }
  };

  const login = "busdstake";

  return (
    <div style={{ minHeight: "100vh" }}>
      <h2 className="text-white text-center mt-4 mb-5 page-title">
        WIN USDT (BEP20) (USDT (BEP20) Staking)
      </h2>
      <div>
        <p className="text-white text-center">
          You can stake USDT (BEP20) and win USDT (BEP20).
          <br /> If you stake 1 USDT (BEP20), you can win as follows.
        </p>
        <div className="text-center">
          <img
            src="/busdstake.jpeg"
            width={350}
            height={350}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="winnings-for-this-race">
          <p className="mb-2 fw-bold">Winnings</p>
          <p style={{ color: "#00C4FF" }} className="mb-0">
            1st: 20 Times
          </p>
          <p style={{ color: "#FF8551" }} className="mb-0">
            2nd: 10 Times
          </p>
          <p style={{ color: "#DD58D6" }} className="mb-0">
            3rd: 5 Times
          </p>
          <p style={{ color: "#C1D0B5" }} className="mb-0">
            4th: 3 Times
          </p>
          <p style={{ color: "#73A9AD" }} className="mb-0">
            5th: 2 Times
          </p>
        </div>
        {/* <p className="text-white text-center">
          {" "}
          If 1 BUSD, if the user comes in the first, can win 20 BUSD.
        </p> */}
        <div>
          <div className="d-flex justify-content-center flex-column">
            <label for={`tentacles`} className="mx-auto text-white">
              {" "}
              Please enter how much you want to stake.
            </label>
            {/* <label for={`tentacles`} className="mx-auto text-white">
              {" "}
              Your BUSD Balance {busdBalance}
            </label> */}
            <input
              type="number"
              value={stakeAmount}
              onChange={(e) => {
                if (/^0\d+/.test(e.target.value)) {
                  const value = e.target.value.replace(/^0/, "1");
                  setstakeAmount(value);
                } else {
                  const value =
                    e.target.value == 0
                      ? 1
                      : parseInt(e.target.value.replace(/\./g, ""));
                  if (value > busdBalance) {
                    setstakeAmount(parseInt(busdBalance));
                  } else {
                    setstakeAmount(value);
                  }
                }
              }}
              id={`tentacles`}
              className="mx-auto mt-2 text-dark width-busd-input"
              name={`tentacles`}
              min="1"
              step="1"
              pattern="\d+"
              oninput="validity.valid||(value='');"
            />
          </div>
          <div className="text-center mt-3">
            {!user?.walletAddress ? (
              <button
                className="text-uppercase button-metamask px-5"
                onClick={() => openWalletModal()}
              >
                {/* {" "}
              <img
                style={{
                  marginLeft: "-15px",
                  marginRight: "10px",
                  // marginTop: "5px",
                }}
                src={coin}
                alt=""
              />{" "} */}
                STAKE USDT (BEP20) NOW
              </button>
            ) : (
              // <LoginButton
              //   register={login}
              //   onClick={() => navigate("sign-in")}
              // ></LoginButton>
              <button
                className="start-button "
                onClick={() => {
                  StakeBusdFun();
                  // navigate("/galarace-busd", {
                  //   state: {
                  //     name: "busdstake",
                  //     stake: stakeAmount,
                  //     position: 12,
                  //   },
                  // })
                }}
                style={{ minWidth: "185px", position: "relative", zIndex: 3 }}
              >
                Stake {stakeAmount} BUSD
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinBusdBUSDStacking;
