import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useState } from "react";
import { GalagamesContext } from "../../Context/GalaraceContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function WithdrawInputModal({
    open,
    handleClickOpen,
    handleClose,
    stackData,
}) {

    const { user, openWalletModal } = useContext(GalagamesContext);
    const [withdrawAmount, setWithdrawAmount] = useState(20);
    const [error, setError] = useState("");
    console.log("user", user);

    const handleWithdraw = async () => {
        try {
            if (true) {
                setError("");
                axios
                    .post(`https://testnetback.superdapps.net/api/withdrawsssss`, {
                        userId: user?._id,
                        email: user?.email,
                        walletAddress: user?.walletAddress,
                        withdrawValue: withdrawAmount,
                    })
                    .then((res) => {
                        if (res.data) {
                            handleClose();
                            swal({
                                title: "Success",
                                text: "Withdraw request sent successfully. Admin will approve within 48 hours.",
                                icon: "success",
                                button: "Ok",
                                className: "modal_class_success",
                            });
                        }
                    });
            } else {
                setError("You cant withdraw more than your total balance!");
            }
        } catch (error) {
            console.log(error);
            swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                button: "Ok",
                className: "modal_class_success",
            });
        }
    };

    const maxValue = () => {
        setWithdrawAmount(stackData?.balance?.toFixed(2));
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div
                    className="bg-dark withdraw-modal"
                    // style={{
                    //     width: "400px",
                    // }}
                    style={{
                        padding: "10px 20px"
                    }}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        className="text-white"
                    >
                        Withdraw as USDT (BEP20)
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <div className="mb-3">
                            <label
                                htmlFor="formGroupExampleInput"
                                className="form-label text-white"
                            >
                                Enter withdraw amount
                            </label>
                            <div className="position-relative">
                                <input
                                    value={withdrawAmount}
                                    onChange={(e) => setWithdrawAmount(e.target.value)}
                                    type="number"
                                    className="form-control text-dark"
                                    id="formGroupExampleInput"
                                    placeholder=""
                                />

                                <Button
                                    onClick={maxValue}
                                    className="position-absolute bg-secondary text-white"
                                    variant="contained"
                                    size="small"
                                    sx={{ top: 6, right: 6, minWidth: 56, padding: "2px" }}
                                >
                                    Max
                                </Button>
                            </div>
                            <p
                                className="text-danger"
                                style={{
                                    fontSize: "13px",
                                }}
                            >
                                {error}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button
                            autoFocus
                            onClick={handleClose}
                            className="btn btn-danger btn-sm"
                        >
                            CANCEL
                        </button>
                        <button
                            autoFocus
                            onClick={handleWithdraw}
                            className="btn btn-success btn-sm"
                        >
                            SUBMIT
                        </button>
                    </DialogActions>
                </div>
            </BootstrapDialog>
        </div>
    );
}
