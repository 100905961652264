import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Form } from "react-bootstrap";
import './LeaderBoard.css'
import { CircularProgress } from '@mui/material';
import Pagination from "@mui/material/Pagination";
import { Link } from 'react-router-dom';
import MetaData from '../../Components/MetaData/MetaData';

const LeaderBoard = () => {
    const [newData, setNewData] = useState([])
    const [category, setCategory] = useState("All");
    const [load, setLoad] = useState(true);
    const [month, setMonth] = useState("All Time")
    var monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // const datas = [
    //     {
    //         myReferralCode: "5AS51A",
    //         busdWon: 5
    //     },
    // ]

    useEffect(() => {
        setLoad(true)
        axios
            .get("https://backend.galarace.ai/api/v1/wallet-user/all", {
                headers: {
                    authorization: `Bearer bIq7Olx4abs2zDM01DMMEgt33fbEe54fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4`,
                },
            })
            .then((res) => {
                if (category == "All") {
                    setNewData(res.data.result?.filter(res=>res?.busdWon!=0)?.sort(compareObjects))
                }
                else if (category == "Previous") {
                    setNewData(res.data.result?.filter(res=>res?.prevMonth?.busdWon!=0)?.sort(compareObjectsPrev))
                }
                else if (category == "This") {
                    setNewData(res.data.result?.filter(res=>res?.thisMonth?.busdWon!=0)?.sort(compareObjectsThis))
                }
                else {
                    setNewData(res.data.result?.filter(res=>res?.busdWon!=0)?.sort(compareObjects))
                }
                setLoad(false)
            });
    }, [category])
    function compareObjects(a, b) {
        // Sort by prize amount
        if (a.busdWon !== 0 && b.busdWon !== 0) {
            return b.busdWon - a.busdWon;
        } else if (a.busdWon !== 0) {
            return -1;
        } else if (b.busdWon !== 0) {
            return 1;
        } else {
            return b.dslWon - a.dslWon;
        }
    }
    function compareObjectsThis(a, b) {
        // Sort by prize amount
        if (a?.thisMonth?.busdWon !== 0 && b?.thisMonth?.busdWon !== 0) {
            return b?.thisMonth?.busdWon - a?.thisMonth?.busdWon;
        } else if (a?.thisMonth?.busdWon !== 0) {
            return -1;
        } else if (b?.thisMonth?.busdWon !== 0) {
            return 1;
        } else {
            return b?.thisMonth?.dslWon - a?.thisMonth?.dslWon;
        }
    }
    function compareObjectsPrev(a, b) {
        // Sort by prize amount
        if (a?.prevMonth?.busdWon !== 0 && b?.prevMonth?.busdWon !== 0) {
            return b?.prevMonth?.busdWon - a?.prevMonth?.busdWon;
        } else if (a?.prevMonth?.busdWon !== 0) {
            return -1;
        } else if (b?.prevMonth?.busdWon !== 0) {
            return 1;
        } else {
            return b?.prevMonth?.dslWon - a?.prevMonth?.dslWon;
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [pagedData, setPagedData] = useState([]);
    const [page1, setPage1] = useState(1);

    // const handleChange1 = (event) => {
    //     setCategory(event.target.value);
    //     setCurrentPage(1);
    //     setPage1(1);
    // };

    const handleChange = (event) => {
        setCategory(event.target.value);
        setCurrentPage(1);
        setPage1(1);
        if (event.target.value == "This") {
            setMonth(`${monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`)
        }
        else if (event.target.value == "Previous") {
            setMonth(`${monthNames[new Date().getMonth()-1]} ${new Date().getFullYear()}`)
        }
        else {
            setMonth("All Time")
        }
    };

    const itemsPerPage = 20;

    // Use the useState hook to store the current page and an array of data for each page

    // Use the useEffect hook to paginate the data when the component mounts or the data changes
    useEffect(() => {
        // Calculate the number of pages needed

        const numPages = Math.ceil(newData.length / itemsPerPage);

        // Create an array of arrays, with each inner array containing the items for a single page
        const pages = Array.from({ length: numPages }, (_, i) =>
            newData.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
        );

        // Set the paged data to the appropriate page
        setPagedData(pages[currentPage - 1]);
    }, [newData, currentPage, itemsPerPage]);

    // Create a function to handle changing the page
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const data1 = newData;
    const itemsPerPage1 = 20;
    const pageCount1 = Math.ceil(data1.length / itemsPerPage1);

    const handleChange1 = (event, value) => {
        window.scrollTo(0, 0);
        setPage1(value);
    };

    const getPageData = () => {
        const start = (page1 - 1) * itemsPerPage1;
        const end = start + itemsPerPage1;
        return data1.slice(start, end);
    };

    return (
        <div className="pb-5" >
            <MetaData pageTitle={"Gala Race | Play and earn rewards"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div>
                            <h1 className="page-title text-center text-uppercase" style={{ marginTop: '40px', fontSize: "1.75rem" }}>LEADER BOARD</h1>

                            <p style={{ marginBottom: "6px" }} className='text-white text-center mb-4'>You can win attractive prizes if you achieve Top 10 status of the month.</p>
                            <p className='text-white text-center mb-4'>
                                <Link to={"/prizes"}>Check out our monthly prizes.</Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                            <p className="text-dark bg-white px-4 py-1 m-0" style={{ borderRadius: "5px" }}>{month}</p>
                            <Form.Select
                                aria-label="Default select example"
                                value={category}
                                onChange={handleChange}
                                className='leader-dropdown'
                            >
                                <option value="All">All Time</option>
                                <option value="This">This Month</option>
                                <option value="Previous">Previous Month</option>


                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-12">
                        {load ? <div
                            className="d-flex align-items-center justify-content-center "
                            style={{ height: "70vh" }}
                        >
                            <CircularProgress className="text-center text-white" color="inherit" />
                        </div> : !newData?.length > 0 ? <div
                            className="d-flex align-items-center justify-content-center "
                        // style={{ height: "70vh" }}
                        >
                            <p className="text-danger">No Data Found</p>
                        </div> : <div className="text-md-center text-lg-center text-start table-responsive">
                            <table className="table table-borderless ">
                                <thead>
                                    <tr className='text-white mb-4 position-table-header'>
                                        <th scope="col" className='text-warning' style={{ width: "33.33%" }}>Position</th>
                                        <th scope="col" className='text-warning' style={{ width: "33.33%" }}>User Id</th>
                                        <th scope="col" className='text-warning' style={{ width: "33.33%" }}>GRT Won</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        getPageData()?.map((item, index) =>
                                            <tr className='text-white'>
                                                <td scope="row">{index + 1}
                                                </td>
                                                <td>
                                                    {
                                                        item?.myReferralCode
                                                    }
                                                </td>
                                                <td >
                                                    {
                                                        category == "All" &&
                                                        (item?.busdWon ? item?.busdWon : 0)
                                                    }
                                                    {
                                                        category == "Previous" &&
                                                        (item?.prevMonth?.busdWon ? item?.prevMonth?.busdWon : 0)
                                                    }
                                                    {
                                                        category == "This" &&
                                                        (item?.thisMonth?.busdWon ? item?.thisMonth?.busdWon : 0)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>}
                    </div>
                    <div className="col-12">
                        <div className="pagination-btn mx-auto text-center">
                            <Pagination
                                count={pageCount1}
                                page={page1}
                                onChange={handleChange1}
                            />
                        </div>
                    </div>
                </div>

            </div>

        </div >
    );
};

export default LeaderBoard;