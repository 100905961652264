import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import "./GRT.css"
import { GalagamesContext } from "../../Context/GalaraceContext";
const GRT = () => {
  const { user } = useContext(GalagamesContext)
  const [amount, setAmount] = useState([])
  useEffect(() => {
    axios.get('https://backend.galarace.ai/api/v1/makepayment'
    ).then(res => {
      console.log(res?.data?.result, "es?.data?.result")
      setAmount(res?.data?.result.reverse().filter(data => data?.paymentName == "grt" && data?.email == user?.email))
      console.log(res?.data?.result.filter(data => data?.paymentName == "grt" && data?.email == user?.email), "es?.data?.result")
    })
  }, [user?.email])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const date = (data) => {
    const dateObj = new Date(data);
    const formattedDate = dateObj.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return formattedDate
  }
  return (
    <div style={{ minHeight: "80vh" }}>
      <div className="container" style={{ marginTop: "25px" }}>
        <h2 className="text-center text-light mb-5 page-title">GRT</h2>

        <Table className="bg-white text-dark rounded">
          <thead>
            <tr className="align-items-start">
              <th className="busd-th">Date/Time</th>
              <th className="busd-th">Transaction ID</th>
              <th className="busd-th">Amount</th>
              <th className="busd-th">Remarks</th>
            </tr>
          </thead>
          <tbody className="busd-th">
            {amount.length > 0 ? amount?.map(res =>
              <tr className="border-0 ">
                <td>{date(res?.createdAt)}</td>
                <td>{res?.transactionId}</td>
                <td>{res?.paymentAmount}</td>
                <td>
                  <p
                    className={
                      res?.remarks == "Stake for GRT race"
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {res?.remarks}
                  </p>
                </td>
              </tr>
            ) : <tr className="align-items-center"><th className="busd-th text-center" colSpan={4}>No data found</th></tr>}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default GRT;
