import React, { useContext, useState } from "react";
import "./Home.css";
import largeBannerThree from "../../Assets/Images/large-device-banner-bg-three.png";
import largeBannerThreeAftLog from "../../Assets/Images/large-device-banner-bg-three-aft-log.png";
import smallBanner from "../../Assets/Images/small-device-banner-bg-one.png";
import smallBannerAftLog from "../../Assets/Images/small-device-banner-bg-one-aft-log.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Offcanvas from "react-bootstrap/Offcanvas";
import closeIcon from "../../Assets/Images/close-icon.png";
import { Link, useNavigate } from "react-router-dom";
import LoginButton from "../../Components/LoginButton/LoginButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

import Galarace01 from "../../Assets/NFTS/Galarace-01.png";
import Galarace02 from "../../Assets/NFTS/Galarace-02.png";
import Galarace03 from "../../Assets/NFTS/Galarace-03.png";
import Galarace04 from "../../Assets/NFTS/Galarace-04.png";
import Galarace05 from "../../Assets/NFTS/Galarace-05.png";
import Galarace06 from "../../Assets/NFTS/Galarace-06.png";
import Galarace07 from "../../Assets/NFTS/Galarace-07.png";
import Galarace08 from "../../Assets/NFTS/Galarace-08.png";
import Galarace09 from "../../Assets/NFTS/Galarace-09.png";
import Galarace10 from "../../Assets/NFTS/Galarace-10.png";
import Oppertunities from "./Oppertunities/Oppertunities";
import NFTs from "./NFTs/NFTs";
import FAQ from "./FAQ/FAQ";
import investOnNfts from "../../Assets/Images/invest-on-nft-image.png";
import beALeader from "../../Assets/Images/be-a-leader-and-win-image.png";
import earnInMultiple from "../../Assets/Images/earn-in-multiple-ways-image.png";
import stackToWin from "../../Assets/Images/stake the nft to win image.png";
import { GalagamesContext } from "../../Context/GalaraceContext";
import starIcon from "../../Assets/Images/star-icon.png"
import MetaData from "../../Components/MetaData/MetaData";

const Home = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const { user } = useContext(GalagamesContext)
  const navigate = useNavigate();

  const nfts = [
    { img: Galarace01, name: "Galarace 01" },
    { img: Galarace02, name: "Galarace 02" },
    { img: Galarace03, name: "Galarace 03" },
    { img: Galarace04, name: "Galarace 04" },
    { img: Galarace05, name: "Galarace 05" },
    { img: Galarace06, name: "Galarace 06" },
    { img: Galarace07, name: "Galarace 07" },
    { img: Galarace08, name: "Galarace 08" },
    { img: Galarace09, name: "Galarace 09" },
    { img: Galarace10, name: "Galarace 10" },
  ];
  // OPPPORTUNITIES
  return (
    <div className="home-container">
         {/* <MetaData pageTitle={"Gala Race | Play to earn blockchain games"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>  */}
           <MetaData pageTitle={"Galarace.ai"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards for playing and completing tasks."}></MetaData>
      <div className="large-device-banner-container">
        <div className="large-device-banner">
          <img
            className="large-device-banner-img"
            src={!user._id ? largeBannerThree : largeBannerThreeAftLog}
            alt=""
          />
          {user._id && <div className="landing-large-membership-status">
            <div className="membership-status-box">
              <p className="membership-status">MEMBERSHIP STATUS</p>
              <div className="d-flex align-items-center gap-2 mb-3">
                <div className="star-container">
                  <img className="img-fluid" src={starIcon} alt="star-icon" />
                  <div></div>
                </div>
                <span className="text-white">{"01"}</span>
              </div>

              <p className="become-premium">
                <Link to={"/memberships"}>
                  Become our premium member and gain 2 or more stars.
                </Link>
              </p>
              <p className="get-all-stars" style={{ cursor: "pointer" }} onClick={() => navigate("/mysterygift")}>
                Get all your stars for mystery gifts.
              </p>
            </div>
          </div>}
        </div>
      </div>
      <div className="small-device-banner-container">
        <div className="small-device-banner">
          <img className="small-device-banner-img" src={!user._id ? smallBanner : smallBannerAftLog} alt="" />
          {user._id && <div className="landing-small-membership-status">
            <div className="membership-status-box">
              <p className="membership-status">MEMBERSHIP STATUS</p>
              <div className="d-flex align-items-center gap-2 mb-2">
                <div className="star-container">
                  <img className="img-fluid" src={starIcon} alt="star-icon" />
                  <div></div>
                </div>
                <span className="text-white">{"01"}</span>
              </div>

              <p className="become-premium">
                <Link to={"/memberships"}>
                  Become our premium member and gain 2 or more stars.
                </Link>
              </p>
              <p className="get-all-stars">
                Get all your stars for mystery gifts.
              </p>
            </div>
          </div>}
        </div>
      </div>

      {/* INVEST ON NFTs */}
      <div className="invest-on-nft-container">
        <div className="container mx-auto row align-items-center">
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-text-part order-md-1 order-lg-1 order-2 padding">
            <h1>
              {/* INVEST ON <br /> NFT */}
              HOW IT <br /> WORKS
            </h1>
            <p>1. Sign up and Sign in.</p>
<p>2. ⁠Get 10 FREE GRT(BEP20) worth USD 10.</p>
<p>3. ⁠Try our Race using the 10 FREE GRT(BEP20).</p>
<p>4. ⁠Win more GRT(BEP20).</p>
<p>5. ⁠Convert GRT(BEP20) to USDT(BEP20).*</p>
<p className="text-warning" style={{fontSize:"14px"}}>*Terms and Conditions Apply</p>




            {/* <p>
              NFTs (Non-Fungible Tokens) are digital assets that are unique and
              cannot be exchanged for other assets. They are used to represent
              ownership of assets. Investing in NFTs can be a great way
              to diversify your portfolio and gain exposure!
            </p> */}
            <button
              onClick={() => navigate("/nfts")}
              className="invest-on-nft-button"
            >
              TRY NOW
              {/* INVEST */}
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-image-part order-md-2 order-lg-2 order-1 g-0">
            <img className="img-fluid" src={investOnNfts} alt="" />
          </div>
        </div>
      </div>

      {/* EARN IN MULTIPLE WAYS */}
      <div className="invest-on-nft-container gradient-bg-for-sec">
        <div className="container mx-auto row align-items-center">
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-image-part align-self-center g-0">
            <img className="img-fluid" src={earnInMultiple} alt="" />
          </div>
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-text-part padding">
            <h1>EARN IN MULTIPLE WAYS</h1>
            <p>
              With our NFTs, you can trade digital assets with ease. Plus, when
              you buy our NFT, you get 5 different bonuses! Get access to
              exclusive discounts, early access to new products, and more.
              Invest in the futures with NFT today!
            </p>
            <button
              onClick={() => navigate("/nfts")}
              className="invest-on-nft-button"
              style={{ backgroundColor: "#b30000" }}
            >
              EARN
            </button>
          </div>
        </div>
      </div>

      {/* STAKE THE NFT TO WIN */}
      <div className="invest-on-nft-container">
        <div className="container mx-auto row align-items-center">
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-text-part order-md-1 order-lg-1 order-2 padding">
            <h1>STAKE THE NFT TO WIN</h1>
            <p>
              Are you ready to take our blockchain game to the next level? Stake
              your NFT and win! You can stake your NFTs and get rewarded with
              GRT. It's the perfect way to get involved in the NFT craze
              and make some extra money. So what are you waiting for?
            </p>
            <button
              onClick={() => navigate("/race")}
              className="invest-on-nft-button"
              style={{ backgroundColor: "rgb(123 81 197)" }}
            >
              STAKE
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-image-part order-md-2 order-lg-2 order-1 g-0">
            <img className="img-fluid" src={stackToWin} alt="" />
          </div>
        </div>
      </div>

      {/* BE A LEADER AND WIN */}
      <div className="invest-on-nft-container gradient-bg-for-sec">
        <div className="container mx-auto row align-items-center">
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-image-part g-0">
            <img className="img-fluid" src={beALeader} alt="" />
          </div>
          <div className="col-lg-6 col-md-6 col-12 invest-on-nft-text-part padding">
            <h1>BE A LEADER AND WIN</h1>
            <p>
              Are you ready to take your gaming experience to the next level? Our new Play To Earn blockchain games are the perfect way to do just that!  Get your hands on and start winning great prizes now!
            </p>
            <button
              onClick={() => navigate("/leader-board")}
              className="invest-on-nft-button"
              style={{ backgroundColor: "rgb(55 163 66)" }}
            >
              BE A LEADER
            </button>
          </div>
        </div>
      </div>


      <Oppertunities />
      {/* <NFTs /> */}
      <FAQ />

      {/* MENU OFF CANVAS */}
      <Offcanvas
        show={showOffCanvas}
        onHide={() => setShowOffCanvas(!showOffCanvas)}
        placement="end"
        className="menu-offcanvas"
      >
        <Offcanvas.Header className="menu-offcanvas-header">
          <button
            onClick={() => setShowOffCanvas(!showOffCanvas)}
            className="bg-transparent border-0 d-block ms-auto"
          >
            <img style={{ width: "20px" }} src={closeIcon} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body
          onClick={() => setShowOffCanvas(!showOffCanvas)}
          className="menu-offcanvas-body pt-0"
        >
          <li className="each-menu-item">
            <Link to={"/race-channel"}>Race Channel</Link>
          </li>
          <li className="each-menu-item">
            <Link to={"/results"}>Results</Link>
          </li>
          <li className="each-menu-item">
            <Link to={"/race"}>Race</Link>
          </li>
          <li className="each-menu-item">
            <Link to={"/nfts"}>NFTS</Link>
          </li>
          <li className="each-menu-item">
            <Link to={"leader-board"}>Leaderboard</Link>
          </li>
          <li className="each-menu-item">
            <Link to={"/earnings"}>Earnings</Link>
          </li>
          <div className="mt-4">
            <LoginButton register={'login'} />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Home;
