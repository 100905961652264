import Galarace01 from "../../Assets/NFTS/Galarace-01.png";
import Galarace02 from "../../Assets/NFTS/Galarace-02.png";
import Galarace03 from "../../Assets/NFTS/Galarace-03.png";
import Galarace04 from "../../Assets/NFTS/Galarace-04.png";
import Galarace05 from "../../Assets/NFTS/Galarace-05.png";
import Galarace06 from "../../Assets/NFTS/Galarace-06.png";
import Galarace07 from "../../Assets/NFTS/Galarace-07.png";
import Galarace08 from "../../Assets/NFTS/Galarace-08.png";
import Galarace09 from "../../Assets/NFTS/Galarace-09.png";
import Galarace10 from "../../Assets/NFTS/Galarace-10.png";
import Galarace11 from "../../Assets/NFTS/Galarace-11.jpeg";

export const nfts = [
     {
        id: 1,
        img: Galarace01,
        image: "https://i.ibb.co/SKscMT0/Galarace-01.png",
        name: "Luminance Runner",
        price: 100,
        totalNfts: 10000,
        value: 100,
    },
    {
        id: 2, img: Galarace02, name: "Frostfire Ivory", price: 500, totalNfts: 9000,
        image: "https://i.ibb.co/zSX0z5h/Galarace-02.png"
    },
    {
        id: 3, img: Galarace03, name: "Starlight Blaze", price: 1000, totalNfts: 8000,
        image: "https://i.ibb.co/XjG9Lv0/Galarace-03.png"
    },
    {
        id: 4, img: Galarace04, name: "Crystal Hoof", price: 2500, totalNfts: 7000,
        image: "https://i.ibb.co/jv9wqk8/Galarace-04.png"
    },
    {
        id: 5, img: Galarace05, name: "Dark Knight", price: 5000, totalNfts: 6000,
        image: "https://i.ibb.co/KG0XJf3/Galarace-05.png"
    },
    {
        id: 6, img: Galarace06, name: "Starry Raven", price: 7500, totalNfts: 5000,
        image: "https://i.ibb.co/R2dH6r3/Galarace-06.png"
    },
    {
        id: 7,
        img: Galarace07,
        name: "Ebony Rider",
        price: 10000,
        totalNfts: 4000,
        image: "https://i.ibb.co/LCf3zh1/Galarace-07.png"
    },
    {
        id: 8,
        img: Galarace08,
        name: "Starburst Regal",
        price: 15000,
        totalNfts: 3000,
        image: "https://i.ibb.co/n6n4wg5/Galarace-08.png"
    },
    {
        id: 9, img: Galarace09, name: "Golden Runner", price: 20000, totalNfts: 2000,
        image: "https://i.ibb.co/y6S0gDg/Galarace-09.png"
    },
    {
        id: 10,
        img: Galarace10,
        name: "Diamond Regal",
        price: 25000,
        totalNfts: 1000,
        image: "https://i.ibb.co/K7HgRp3/Galarace-10.png"
    },
    {
        id: 11,
        img: Galarace11,
        name: "Test",
        price: 1,
        totalNfts: 100000,
        image: "https://i.ibb.co/p0DW8yg/Whats-App-Image-2023-05-13-at-4-13-11-AM.jpg"
    },

];