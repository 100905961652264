import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { GalagamesContext } from '../../Context/GalaraceContext';



const MintedNft = () => {
    const navigate = useNavigate();
    const {
        user
    } = useContext(GalagamesContext);

    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`https://backend.galarace.ai/api/v1/verifymint/${user?.walletAddress}`, {
            headers: { "authorization": `Bearer fuTBncWdXFhWYOs3CrKZt86atzL3-CJdExpP4bIq7Olx4abs2zDM01DMMEgt33fbEe54` }
        }).then(res => {
            const flag = res?.data?.result
            setData(flag.filter(e => e?.contractAddress != "0x31219e8af07699054B690116675546939a0c362F").reverse())

        });
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert("Copied!")
    }

    const strimeDate = (d) => {
        console.log(d, 'new date has~');
        return d;
    }

    return (
        <div>
            <div>
                <h1 className="page-title text-center pt-5 text-uppercase" style={{ marginTop: '0px', fontSize: "1.75rem" }}>MINTED</h1>
                <div className="small-border bg-color-2"></div>
            </div>
            <div className='container' style={data.length > 10 ? { minHeight: '80vh' } : { height: '80vh' }}>
                {data.length > 0 ? <div className="tableNormal ">
                    <Table className='text-white adminDataTable' style={{ minWidth: '850px' }} responsive>
                        <thead>
                            <tr>
                                <th className='text-start'>Contract Address</th>
                                <th className='text-start'>Token ID</th>
                                {/* <th className='text-start action'>Timestamp</th> */}
                                <th className='text-start action'>BSC Transaction</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map(e => <tr elem={e} key={e?._id} className='tableRow'>
                                    <td className='text-start d-flex align-items-center'>
                                        <span>{e?.contractAddress.slice(0, 22)}<br />{e?.contractAddress.slice(23, e?.walletAddress.length)}</span>
                                        <span className="rounded nft-delete-button ms-1 me-0" onClick={() => copyToClipboard(e?.contractAddress)}>
                                            <button className="saveBtn bg-transparent ms-2"><i className="fa-solid fa-clone"></i></button>
                                        </span>
                                    </td>
                                    <td style={{ textTransform: 'lowercase' }} className='text-start'>{e?.tokenId}<span className="rounded nft-delete-button ms-1 me-0" onClick={() => copyToClipboard(e?.tokenId)}>
                                        <button className="saveBtn bg-transparent ms-2"><i className="fa-solid fa-clone"></i></button>
                                    </span></td>
                                    {/* <td className='action'>
                                        {strimeDate(e?.timeStamp)}
                                    </td> */}
                                    <td className='action'>
                                        <a href={e?.mintHash} className='text-primary' target="_blank" style={{ textDecoration: 'underline' }}>Click here</a>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                    <h4 className="text-center mx-auto d-block mb-3 mt-4">
                        <Link to="/nfts" className="text-decoration-none playtoearn text-center mx-auto">Mint More NFTs</Link>
                    </h4>
                </div> : <div className='text-warning text-center' style={{ fontSize: '16px' }}><p>You have not minted any NFTs yet.</p>
                    <p style={{ cursor: "pointer" }} onClick={() => navigate("/nfts")} className="text-primary cursor-pointer text-decoration-none">Please click here to mint</p>
                </div>}
            </div>
        </div>
    );
};

export default MintedNft;