import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "./cloneusersauth.css";
import { AiOutlineUser } from "react-icons/ai";
import { useTimer } from "react-timer-hook";
import { RiCouponLine } from "react-icons/ri";
import EmailVerificationModal from "../Profile/EmailVerificationModal";
import { GalagamesContext } from "../../Context/GalaraceContext";

const CloneUserSingUp = () => {
  const { token, setToken } = useContext(GalagamesContext);

  const navigate = useNavigate();
  const { code } = useParams();
  const [email, setEmail] = useState("");
  const [affilateCode, setaffilateCode] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [EmailOtpVerify, setEmailOtpVerify] = useState();
  console.log(EmailOtpVerify, "OTP HERE")
  const [openEmail, setOpenEmail] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (S39user?._id) {
  //     navigate("/users/dashboard", { replace: true });
  //   }
  // }, [S39user, token]);

  // Timer here
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);

  const { seconds, minutes, resume, restart } = useTimer({
    time,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };
  // Timer ends

  const handleUpdateEmail = () => {
    console.log("working")
  }

  const handleVerifyEmail = async () => {
    setIsSendingEmail(true);

    if (email.length > 0 && email.includes("@" && ".")) {

      await axios
        .post("https://backend.galarace.ai/api/v1/email/send", { email })
        .then((res) => {
          // console.log(res, '--data results gotten~');
          if (res.status === 200) {
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setEmailOtpVerify(res.data.otp);
          }
          setOpenEmail(true);
          setIsSendingEmail(false);
        })
        .catch((err) => {
          setIsSendingEmail(false);

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white fs-5'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      setIsSendingEmail(false);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const SingUp = async (e) => {
    e.preventDefault();

    if (!emailVerify) {
      swal({
        text: "Please verify your email first.",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
      return;
    }
    let affiliate = "";
    const name = e.target.name.value;
    const password = e.target.password.value;
    if (e.target.affiliate.value) {
      affiliate = e.target.affiliate.value;
    }


    const data = {
      name: name,
      email: email,
      password: password,
      otp: EmailOtpVerify,
      affiliate: affiliate
    };

    console.log(data, "SING UP DATA");

    await axios
      .post(
        "https://backend.galarace.ai/api/v1/wallet-user/signup",
        data
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res, "SIGN UP RESPONSE");
          setToken(res.data.token);
          localStorage.setItem("galagames", res.data.token);
          swal({
            title: "Successful",
            text: `You have successfully signed up with us. You will be forwarded to sign in page shortly.`,
            icon: "success",
            button: "OK",
            className: "modal_class_success",
          }).then(function (isConfirm) {
            if (isConfirm) {
              navigate('/sign-in')

            }
          });

        }
      })
      .catch((error) => {
        console.log(error, "SIGN UP ERROR");
        swal({
          title: "Attention",
          text: `${error.response?.data?.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  useEffect(() => {
    axios
      .get("https://backend.galarace.ai/api/v1/wallet-user/all")
      .then((res) => {
        setAllusers(res?.data?.result)
      })
  }, [])

  return (
    <div className="handleTheLoginBody">
      <div className="container mx-auto">
        <h2 className="text-center text-warning mb-3">Sign Up</h2>
        <div className="pt-5 forCard w-md-50 p-5 rounded mx-auto ">
          <p className="text-center fs-5"> Sign Up and claim your 10 GRTs worth USD 10.<br/>(Terms and Conditions apply)</p>
          <div className="mt-4 pt-2">
            <form onSubmit={SingUp}>
              <InputGroup>
                <InputGroup.Text
                  id="basic-addon1"
                  className={`border-0 text-white ${allusers.find(res => res?.myReferralCode == affilateCode) ? "bg-success" : "bg-dark"}`}
                >
                  <RiCouponLine />

                </InputGroup.Text>
                {code?<Form.Control
                  style={{ textTransform: "none" }}
                  className="inputBackground"
                  placeholder="Affiliate Code"
                  aria-label="Affiliate"
                  type="text"
                  value={code}
                  aria-describedby="basic-addon1"
                  name="affiliate"
                  onChange={(e) => setaffilateCode(e.target.value)}
                />:<Form.Control
                  style={{ textTransform: "none" }}
                  className="inputBackground"
                  placeholder="Affiliate Code"
                  aria-label="Affiliate"
                  type="text"
                  aria-describedby="basic-addon1"
                  name="affiliate"
                  onChange={(e) => setaffilateCode(e.target.value)}
                />}
              </InputGroup>

              <Form.Label className="text-danger mb-0 ms-5" htmlFor="basic-text" style={{ fontSize: '11px' }}>Please enter the affiliate code if you have one.</Form.Label>
              
              <InputGroup className="mt-2">
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-dark border-0 text-white"
                >
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  style={{ textTransform: "none" }}
                  className="inputBackground"
                  placeholder="Name"
                  aria-label="Name"
                  type="text"
                  required
                  aria-describedby="basic-addon1"
                  name="name"
                // onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                />
              </InputGroup>
              <InputGroup className="mt-4">
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-dark border-0 text-white"
                >
                  <FaEnvelope />
                </InputGroup.Text>
                <Form.Control
                  style={{}}
                  className="inputBackground"
                  placeholder="Email"
                  aria-label="Username"
                  type="email"
                  required
                  aria-describedby="basic-addon1"
                  name="email"
                  onChange={(e) => {
                    setEmailVerify(false);
                    setEmail(e.target.value.toLocaleLowerCase());
                  }}
                />

                {!emailVerify && email.length > 0 && (
                  <button
                    onClick={() => handleVerifyEmail()}
                    className="border-0 p-2 px-3 d-flex justify-content-center bg-danger text-white verify-email-sender-button"
                    type="button"
                    disabled={isSendingEmail && true}
                    style={{
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    Verify
                  </button>
                )}

                {emailVerify && (
                  <button
                    disabled
                    className="border-0 p-2 px-3 d-flex justify-content-center text-white"
                    style={{
                      backgroundColor: "green",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    Verified
                  </button>
                )}
              </InputGroup>
              <br />
              {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
              <InputGroup className="mb-3">
                <InputGroup.Text className="bg-dark border-0 text-white">
                  <FaLock />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className="inputBackground"
                  disabled={emailVerify === false}
                  placeholder="Password"
                  type={visiblePassword ? "text" : "password"}
                  required
                  name="password"
                />
                <InputGroup.Text
                  className="bg-dark text-center border-0 cursor-pointer text-white"
                  role="button"
                  type="button"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                >
                  {visiblePassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>

              <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                <p>Already have an account?</p>
                <Link className=" forgetPass ms-1" to="/sign-in">
                  Sign In
                </Link>
                {/* <Link className=" forgetPass ms-1" to="/clone/sign-in">
                  Sign In
                </Link> */}
              </Form.Group>
              <div className="mx-auto text-center">
                <Button
                  style={{ backgroundColor: "#f74545" }}
                  className="button-34 ps-5 pe-5 pt-2 pb-2"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <EmailVerificationModal
        handleUpdateEmail={handleUpdateEmail}
        handleVerifyEmail={handleVerifyEmail}
        minutes={minutes}
        seconds={seconds}
        open={openEmail}
        email={email}
        setOpenEmail={setOpenEmail}
        emailOtpVerify={EmailOtpVerify}
        // setForEnable={setEmailForEnable}
        // setError={setEmailError}
        setEmailOtpVerify={setEmailOtpVerify}
        setEmailVerify={setEmailVerify}
      />
    </div>
  );
};

export default CloneUserSingUp;
