import { Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FiSend } from "react-icons/fi";
import { AiOutlineLogin, AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import "./AdminLogin.css";
import MailIcon from "@mui/icons-material/Mail";
import { FaEnvelope } from "react-icons/fa";

const AdminForgotPassword = () => {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/login");
  };

  const sendResetLink = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;

    await axios
      .post(
        "https://testnetback.superdapps.net/api/dashboard/user_signup-signin/send-reset-password-link",
        { email }
      )
      .then((res) => {
        console.log("FORGOT PASSWORD RESPONSE", res);
        if (res.status === 200) {
          swal({
            text: `${res.data.message}`,
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
          navigate("/login");
          // navigate("/clone/sign-in");
        }
      })
      .catch((err) => {
        console.log("FORGOT PASSWORD RESPONSE", err);
        // alert(err.response.data.message);
        swal({
          title: "Attention",
          text: `${err.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div>
      <div
        style={{ minHeight: "80vh", marginTop: "-92px", paddingTop: "92px" }}
        className="handleTheLoginBody background-gradient"
      >
        <div className="container mx-auto">
          <div className="pt-5 forCard w-md-50 p-5 rounded mx-auto ">
            {/* <div className='mx-auto text-center py-3 w-50' style={{background: '#000A17'}}>
                        <img src={coin} className='' width={150} alt="logo" />
                    </div>
                    <hr /> */}
            <p className="text-center fs-5">Forget Password</p>
            <div className="mt-4 pt-2">
              <form onSubmit={sendResetLink}>
                <InputGroup>
                  <InputGroup.Text
                    id="basic-addon1"
                    className="bg-dark border-0 text-white"
                  >
                    <FaEnvelope />
                  </InputGroup.Text>
                  <Form.Control
                    style={{ textTransform: "lowercase" }}
                    className="inputBackground"
                    placeholder="Email"
                    aria-label="Username"
                    type="email"
                    required
                    aria-describedby="basic-addon1"
                    name="email"
                    // onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                  />
                </InputGroup>
                <br />
                <div
                  className="mx-auto text-center"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    style={{ backgroundColor: "#f74545", height: "28px" }}
                    className="button-34 px-4"
                    type="submit"
                    // onClick={handleGoToLogin}
                  >
                    <FiSend></FiSend> Send
                  </Button>
                  <Button
                    style={{ backgroundColor: "#f74545", height: "28px" }}
                    className="button-34 px-4"
                    type="button"
                    onClick={handleGoToLogin}
                  >
                    <AiOutlineLogin /> Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminForgotPassword;
