export const packages = [
  {
    num: 1,
    name: "BEGINNER",
    grtWorth: 50,
    // grtWorth: 50,
    nftWorth: 50,
    immediately: {
      grt: "A NFT WORTH",
      welcomeBonus: "GRT worth ",
    },
    daily: {
      stakeBonus: "$0.136 Per day for 24 months",
      playEarn: "Earn daily from winings",
    },
    other: {
      introductionBonus: "Earn 10% in USDT (BEP20)",
      levelBonus: "Earn up to 3 Levels in USDT (BEP20)",
      gameIncome: "Earn up to 5 Levels in GRT",
    },
  },
  {
    num: 2,
    name: "PROFESSIONAL",
    grtWorth: 100,
    nftWorth: 100,
    immediately: {
      grt: "A NFT WORTH",
      welcomeBonus: "GRT worth ",
    },
    daily: {
      stakeBonus: "$0.2739 Per day for 24 months",
      playEarn: "Earn daily from winings",
    },
    other: {
      introductionBonus: "Earn 10% in USDT (BEP20)",
      levelBonus: "Earn up to 5 Levels in USDT (BEP20)",
      gameIncome: "Earn up to 10 Levels in GRT",
    },
  },
  {
    num: 3,
    name: "MASTER",
    grtWorth: 500,
    nftWorth: 500,
    immediately: {
      grt: "A NFT WORTH",
      welcomeBonus: "GRT worth ",
    },
    daily: {
      stakeBonus: "$1.3698 Per day for 24 months",
      playEarn: "Earn daily from winings",
    },
    other: {
      introductionBonus: "Earn 10% in USDT (BEP20)",
      levelBonus: "Earn up to 8 Levels in USDT (BEP20)",
      gameIncome: "Earn up to 15 Levels in GRT",
    },
  },
  {
    num: 4,
    name: "GRAND MASTER",
    grtWorth: 1000,
    nftWorth: 1000,
    immediately: {
      grt: "A NFT WORTH",
      welcomeBonus: "GRT worth ",
    },
    daily: {
      stakeBonus: "$2.7397 Per day for 24 months",
      playEarn: "Earn daily from winings",
    },
    other: {
      introductionBonus: "Earn 10% in USDT (BEP20)",
      levelBonus: "Earn up to 10 Levels in USDT (BEP20)",
      gameIncome: "Earn up to 20 Levels in GRT",
    },
  },
];
