import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "./../AddBanner/Pagination/Pagination";
import { CopyAll } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";

const GrtHistory = () => {
  const { bannerPerPage } = useParams();

  const [modalShowNewBanner, setModalShowNewBanner] = useState(false);
  const [allBanners, setAllBanners] = useState([]);
  const [editBanner, setEditBanner] = useState('');
  // console.log(allBanners);
  const [editBannerModal, setEditBannerModal] = useState(false);


  //****************************** Pagination Start ******************************/
  const navigate = useNavigate();
  const [getPage, setPage] = useState(1);
  const [show, setShow] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [sliceBanners, setSliceBanners] = useState([]);
  // console.log(sliceProducts)


  useEffect(() => {
    const lastPage = Math.ceil(allBanners?.length / show);
    setLastPage(lastPage);
  }, [allBanners, show]);




  useEffect(() => {
    if (bannerPerPage) {
      const page = parseInt(bannerPerPage);
      const getSlicingBanner = allBanners.slice(
        (page - 1) * show,
        page * show
      );
      setSliceBanners([...getSlicingBanner]);
      setPage(parseInt(page));
    } else {
      const getSlicingProduct = allBanners.slice(0, show);
      setSliceBanners([...getSlicingProduct]);
    }
  }, [allBanners, show, bannerPerPage]);

  const pageHandle = (jump) => {
    navigate(`/admin/banners/${jump}`);
    setPage(parseInt(jump));
  };


  //****************************** Pagination Start ******************************/




  const fetchAllBanners = async () => {

    await axios
      .get("https://backend.galarace.ai/api/v1/payment/grt/all")
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(res);
          setAllBanners(res.data.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {

    fetchAllBanners();
  }, []);

  
  const copyToClipboardAffiliateCode = (copyTextAffiliateCode) => {
    copy(copyTextAffiliateCode);
    if (copyTextAffiliateCode !== "") {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>You have copied "${copyTextAffiliateCode}"</p>`;

      Swal.fire({
        // title: 'Successfully updated your Full Name.',
        html: wrapper,
        icon: "success",
        customClass: "modal_class_success",
      });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Nothing to Copy</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  return (
    <div className="adminBody">
    <h5 className="dashboard-title">GRT History</h5>
      <div className="productCard pb-2">
        {/* <div className="d-flex flex-column flex-row justify-content-lg-between align-items-center"> */}
       

        <div className="tableNormal ">
          <Table
            style={{ minWidth: "650px", maxWidth: "100%" }}
            className="text-white productDataTable "
          >
            <thead>
              <tr>
                <th className="text-left">Receiver</th>
                <th className="text-left">Sender</th>
                <th className="text-left">Amount</th>
                {/* <th className="text-left">Email</th> */}
                <th className="text-left">Date</th>
             
              </tr>
            </thead>
            <tbody>
              {sliceBanners?.map((eachBanner) => (
                <tr className="tableRow">
              
                  <td align="left">
                    {" "}
                          {eachBanner?.email}
                      </td>
                      <td align="left">
                    {" "}
                    Admin
                      </td>
                      <td align="left">
                    {" "}
                    {eachBanner?.amount} GRT
                  </td>
                  <td className="text-left text-capitalize ">
                    {new Date(eachBanner?.createdAt)?.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                  </td>

              
                </tr>
              ))}
            </tbody>
          </Table>
          {sliceBanners?.length ? (
            <Pagination
              lastPage={lastPage}
              page={getPage}
              pageHandle={pageHandle}
            />
          ) : (
            <></>
          )}
        </div>
      </div>



    </div>
  );
};

export default GrtHistory;
