import { Box, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import "./NFTs.css";

import { Link, useNavigate } from "react-router-dom";
import { nfts } from "./nftsApi";
import MetaData from "../../Components/MetaData/MetaData";

const NFTs = () => {
  const [category, setCategory] = useState("All");
  const [totalValue, setTotalValue] = useState(0);
  const [totalNfts, setTotalNfts] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(true);

  console.log(totalValue, totalNfts, "TOTAL VALUE AND NFTS")

  const navigate = useNavigate()

  //  scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let tlNftValue = 0;
    let tlNfts = 0;

    nfts.forEach((eachNft) => {
      const eachNftValue = eachNft.price * eachNft.totalNfts;
      tlNftValue = tlNftValue + eachNftValue;

      tlNfts = tlNfts + eachNft.totalNfts;
    });

    setTotalNfts(tlNfts);
    setTotalValue(tlNftValue);
  }, [nfts]);

  useEffect(() => {
    const image = new Image();
    image.src = 'https://i.ibb.co/K7HgRp3/Galarace-10.png';

    image.addEventListener('load', () => {
      setIsLoading(false);
    });

    // Clean up the event listener when the component unmounts
    return () => {
      image.removeEventListener('load', () => {
        setIsLoading(false);
      });
    };
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      <MetaData pageTitle={"Gala Race | Invest on NFT"} pageDescription={"Galarace.ai is a revolutionary new way to play and earn rewards. It is a blockchain-based game that allows players to earn rewards & Purchase NFTs from Gala race."}></MetaData>
      <Fragment>
        <Box
          className="souvenirNFT_Box"
          id="Meal"
        // style={
        //   allNft.length
        //     ? { paddingBottom: "0" }
        //     : { paddingBottom: "0", height: "100vh" }
        // }
        >

          {/* <div className="nft-info-button position-relative">
            <i onClick={() => setInfoOpen(!infoOpen)} style={{ cursor: "pointer", fontSize: "18px" }} class="text-white fa-solid fa-circle-info"></i>

            {infoOpen && <div className="nft-info-container">
              <p>
                You can use Galarace.ai NFTs as follows:<br /> 1. Stake in our races to win USDT (BEP20) <br /> 2. Sell in Opensea for a profit <br /> 3. Sell Binance NFT for a profit
              </p>
            </div>}
          </div> */}

          <div>
            <h2 className="text-white text-center mt-4 page-title">NFT<span style={{ textTransform: "lowercase" }}>s</span></h2>

            {/* <div className="small-border bg-color-2"></div> */}
          </div>

          <div className="mt-2">
          </div>

          {/* <div className="row mb-0 mb-md-4 g-0">
            <div className="col-12">
              <Form.Select
                style={{ width: "107px", marginInline: "auto" }}
                aria-label="Default select example"
                value={category}
                onChange={handleChange}
              >
                <option value="All">All</option>
                <option value="India">India</option>
                <option value="Malaysia ">Malaysia</option>
                <option value="Philippines">Philippines</option>
                <option value="Thailand">Thailand</option>
                <option value="Others">Others</option>
                {categories?.map((data) => (
                  <option value={data}>{data}</option>
                ))}
              </Form.Select>
            </div>
          </div> */}
          {/* <p className="sighupSmall text-center mt-2 container d-block d-md-none">
            The images generated by AI do not represent any real person or
            entity. The images are created by DALL E and Midjouney with suitable
            prompts. Apps like Prequel are used to enhance.
          </p>
          <p className="sighupSmall text-center mt-2 d-none d-md-block">
            The images generated by AI do not represent any real person or
            entity.
            <br />
            The images are created by DALL E and Midjouney with suitable
            prompts.
            <br />
            Apps like Prequel are used to enhance.
          </p> */}
          {/* {allNft?.length === 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : ( */}
          <>
            {/* <p
                className="text-gradient text-center fs-6 pt-1 mb-lg-4 mb-1"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/nfts")}
              >
                {category} NFTs: {allNft?.length}
              </p> */}

            <div className="text-light mealnft container">
              <div className="row justify-content-center">
                {nfts?.map((data) => (
                  <div className="col-12 col-lg-3 col-md-6">
                    <div key={data?.id} className="d-item1">
                      <div class="card">
                        {/* <CheckLogin register={"code"}>
                          <div
                            onClick={() =>
                              data.likes?.includes(user?.walletAddress)
                                ? unlikeNft(data?._id)
                                : likeNft(data?._id)
                            }
                            className="nft_item_like like_card"
                          >
                            <i
                              className={`fa fa-heart ${
                                data?.likes?.includes(user?.walletAddress) &&
                                "heart-icon"
                              }`}
                            ></i>
                            <span style={{ marginBottom: "2.2px" }}>
                              {data?.likes?.length}
                            </span>
                          </div>
                        </CheckLogin> */}
                        <div
                          class="card-img bg-dark"

                          style={{ backgroundImage: `url(${data.img})`, cursor: "pointer" }}
                          onClick={() => navigate(`/nft/${data?.id}`)}
                        >
                          {isLoading && <div className="w-100 h-100 d-flex align-items-center justify-content-center"><Spinner></Spinner></div>}
                          <div
                            class="overlay d-grid "
                            style={{
                              alignContent: "center",
                              justifyItems: "center",
                            }}
                          >
                            <div className="d-flex card_hover_icon">
                              {/* <Link to={`/nft/${data?.id}`}>
                                <button
                                  className="card_hover_button mt-5"
                                  href="#!"
                                >
                                  BUY THIS NFT!
                                </button>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                        <div class="card-content">
                          <div className="row" style={{ minHeight: "90px" }}>
                            {/* <Typography
                              className="mt-2 slider_nft_text"
                              variant="div"
                            >
                              <span className="text-primary">Category :</span>{" "}
                              {data?.type}
                            </Typography> */}
                            <Typography
                              className="mt-2 slider_nft_text"
                              variant="div"
                            >
                              <span className="text-primary">
                                Name of NFT :
                              </span>{" "}
                              {data?.name}
                            </Typography>

                            <Typography className="mt-2" variant="body2">
                              <span className="text-primary">
                                Price of NFT(USD):
                              </span>{" "}
                              {data.price
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                            <Typography className="mt-2" variant="body2">
                              <span className="text-primary">Total NFTs:</span>{" "}
                              {data?.totalNfts
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                          </div>
                          <hr style={{ margin: "10px 0px 10px 0px" }} />
                          <div
                            className="d-flex card_bottom_btn_main "
                            style={{ margin: "15px 0 8px 0" }}
                          >
                            <div className="col-10 d-grid me-2">
                              <Link to={`/nft/${data?.id}`} className="d-grid">
                                {" "}
                                <button
                                  className={
                                    // ethereum
                                    //   ? "card_button2 bg-success glow_crypto_button fs-6"
                                    //   :
                                    "card_button2 fs-6"
                                  }
                                  href="#!"
                                >
                                  Buy this NFT!
                                </button>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* {!getPageData()?.length > 0 ? (
                    <p className="text-white text-center">
                      No NFTs are available
                    </p>
                  ) : (
                    getPageData()?.map((data) => (
                      <div className="col-12 col-lg-3 col-md-6">
                        <div key={data?._id} className="d-item1">
                          <div class="card">
                            <CheckLogin register={"code"}>
                              <div
                                onClick={() =>
                                  data.likes?.includes(user?.walletAddress)
                                    ? unlikeNft(data?._id)
                                    : likeNft(data?._id)
                                }
                                className="nft_item_like like_card"
                              >
                                <i
                                  className={`fa fa-heart ${
                                    data?.likes?.includes(
                                      user?.walletAddress
                                    ) && "heart-icon"
                                  }`}
                                ></i>
                                <span style={{ marginBottom: "2.2px" }}>
                                  {data?.likes?.length}
                                </span>
                              </div>
                            </CheckLogin>
                            <div
                              class="card-img"
                              style={{ backgroundImage: `url(${data.avatar})` }}
                            >
                              <div
                                class="overlay d-grid "
                                style={{
                                  alignContent: "center",
                                  justifyItems: "center",
                                }}
                              >
                                <div className="d-flex card_hover_icon">
                                  <Link
                                    to={`/nft/${data?._id}/${data?.imageName}`}
                                  >
                                    <button
                                      className="card_hover_button mt-5"
                                      href="#!"
                                    >
                                      BUY THIS NFT!
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div class="card-content">
                              <div
                                className="row"
                                style={{ minHeight: "90px" }}
                              >
                                <Typography
                                  className="mt-2 slider_nft_text"
                                  variant="div"
                                >
                                  <span className="text-primary">
                                    Category :
                                  </span>{" "}
                                  {data?.type}
                                </Typography>
                                <Typography
                                  className="mt-2 slider_nft_text"
                                  variant="div"
                                >
                                  <span className="text-primary">
                                    Name of NFT :
                                  </span>{" "}
                                  {data?.name}
                                </Typography>

                                <Typography className="mt-2" variant="body2">
                                  <span className="text-primary">
                                    Price of NFT(USD):
                                  </span>{" "}
                                  {data.price}
                                </Typography>
                                <Typography className="mt-2" variant="body2">
                                  <span className="text-primary">
                                    Total NFTs:
                                  </span>{" "}
                                  {data?.totalNfts
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                              </div>
                              <hr style={{ margin: "10px 0px 10px 0px" }} />
                              <div
                                className="d-flex card_bottom_btn_main "
                                style={{ margin: "15px 0 8px 0" }}
                              >
                                <div className="col-10 d-grid me-2">
                                  <Link
                                    to={`/nft/${data?._id}/${data?.imageName}`}
                                    className="d-grid"
                                  >
                                    {" "}
                                    <button
                                      className={
                                        ethereum
                                          ? "card_button2 bg-success glow_crypto_button fs-6"
                                          : "card_button2 bg-success fs-6"
                                      }
                                      href="#!"
                                    >
                                      Buy this NFT!
                                    </button>{" "}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )} */}
                <div className="col-12">
                  <div className="pagination-btn mx-auto text-center">
                    {/* <Pagination
                        count={pageCount1}
                        page={page1}
                        onChange={handleChange1}
                      /> */}
                  </div>

                  {/* <div className="text-center py-4 pagination-btn">
                  {currentPage > 1 && (
                    <button
                      style={{ backgroundColor: "#004c55", color: "white" }}
                      className="btn me-1"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prev
                    </button>
                  )}

               
                  {Array.from(
                    { length: Math.ceil(allNft.length / itemsPerPage) },
                    (_, i) => (
                      <button
                        style={{ backgroundColor: "#004c55", color: "white" }}
                        className="btn me-1"
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        disabled={i + 1 === currentPage}
                      >
                        {i + 1}
                      </button>
                    )
                  )}

             
                  {currentPage < Math.ceil(allNft.length / itemsPerPage) && (
                    <button
                      style={{ backgroundColor: "#004c55", color: "white" }}
                      className="btn me-1"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  )}
                </div> */}
                </div>
              </div>
            </div>

            <div>
              {nfts?.length > 0 ? (
                <div>
                  <p
                    className="text-center fs-6 pt-4"
                    style={{ cursor: "pointer", color: "rgb(229 113 4)" }}
                  // onClick={() => navigate("/nfts")}
                  >
                    Total number of NFTs:{" "}
                    {totalNfts
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>

                  <p
                    style={{ fontSize: "0.9rem" }}
                    className="text-white text-center pt-1 mb-0"
                  >
                    Total value of NFTs:{" "}
                    {totalValue
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    USD
                  </p>
                </div>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    color: "#d0d7c2",
                    textAlign: "center",
                    fontSize: "16px",
                    marginTop: "1rem",
                  }}
                >
                  Stay Tuned!
                </Typography>
              )}
            </div>
          </>

          {/* <div>
            {allNft?.length > 0 ? (
              <div>
                <p
                  className="text-gradient text-center fs-6 pt-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/nfts")}
                >
                  Types of NFTs available: {isMeal?.length}
                </p>

                <p
                  style={{ fontSize: "0.9rem" }}
                  className="text-white text-center pt-1 mb-0"
                >
                  Total value of NFTs: {nftValues} USD
                </p>
                <p
                  style={{ fontSize: "0.9rem" }}
                  className="text-white text-center pt-"
                >
                  Total value of NFTs sold: {nftSoldValues} USD
                </p>
              </div>
            ) : (
              <Typography
                variant="h6"
                style={{
                  color: "#d0d7c2",
                  textAlign: "center",
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Stay Tuned!
              </Typography>
            )}
          </div> */}
          <div className="d-flex" style={{ justifyContent: "center" }}></div>
          {/* <Container style={{ marginTop: "16px" }}></Container> */}
        </Box>
      </Fragment>
    </div>
  );
};

export default NFTs;
