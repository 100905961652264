import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { GalagamesContext } from "../../Context/GalaraceContext";

const dataSliderImage = [
    {
        id: 1,
        image: "./1stprize.png",
        name: "MacBook Pro 16 inch",
        price: "(Worth USD 3,499.00)",
        position: "1st Prize",
    },
    {
        id: 2,
        image: "./4rthprize.png",
        name: "iPad 10th generation",
        price: "(Worth USD 1,999.00)",
        position: "2nd Prize",
    },
    {
        id: 3,
        image: "./2ndprize.png",
        name: "iPhone 14 Pro",
        price: "(Worth USD 1,499.00)",
        position: "3rd Prize",
    },
    {
        id: 4,
        image: "./3rdprize.png",
        name: "Apple Watch Series 8",
        price: "(Worth USD 579.00)",
        position: "4th Prize",
    },
];

const MysteryGift = () => {
    const { user } = useContext(GalagamesContext)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ minHeight: "80vh" }}>
            <div className="container" style={{ marginTop: "25px" }}>
                <h2 className="text-center text-light mb-5 page-title">Mystery Gifts</h2>
                <div className="container pt-3 mt-4">
                    <div className="row">
                        {dataSliderImage.map((item, index) => (
                            <div key={item?.id} className=" col-lg-6 col-md-12 col-sm-12 mb-2">
                                <div
                                    class="card "
                                    style={{ borderRadius: "10px", backgroundColor: "#241d2b" }}
                                >
                                    <div className="px-4 pt-4 pb-2">
                                        <img src={item?.image} alt="" className="w-100" />
                                        <div className="d-flex justify-content-between mt-3 ">
                                            <p
                                                className="text-light prize-name"
                                                style={{ fontSize: "22px", fontWeight: "600" }}
                                            >
                                                {item.name}{" "}
                                                <span
                                                    className="priceOffer"
                                                    style={{ fontWeight: "600", color: "red" }}
                                                >
                                                    {item.price}
                                                </span>
                                            </p>
                                            <button className="prize-btn">{item.position}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="my-5">
                    <div className="container">
                        <p
                            className="text-center py-4 text-light close-date closing"
                            style={{ fontSize: "30px", fontWeight: "500" }}
                        >
                            Closing Date - 31 October 2023
                        </p>
                    </div>
                    <p className="text-center text-white fontsizePrizing">
                        Sign up to get our FREE NFT by 31 October 2023 to win the prizes.
                        Anyone from any country can participate.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MysteryGift;
