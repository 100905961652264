import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import { GalagamesContext } from '../../Context/GalaraceContext';
import axios from 'axios';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Spinner } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';

const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
  font-size:12px
`;



const NetworkChart = () => {
    const { user } = useContext(GalagamesContext);
    const [amount, setAmount] = useState([]);
    const [info, setInfo] = useState([]);
    const [load, setLoad] = useState(false)

    const navigate=useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        axios
            .get("https://backend.galarace.ai/api/v1/wallet-user/all")
            .then((res) => {
                console.log(res?.data?.result, "es?.data?.result");
                setInfo(res?.data?.result)
                user?.downlines?.map(resu => {

                    for (let i = 0; i < res?.data?.result?.length; i++) {

                        if (resu == res?.data?.result[i]?.email) {
                            console.log([...amount, res.data.result[i]], "resu == res?.data?.result[i]?.email")
                            amount.push(res.data.result[i])
                        }
                    }
                })
                setLoad(true)

            });
 

        // .get("https://backend.galarace.ai/api/v1/wallet-user/all")
        // .then((res) => {
        //     console.log(res?.data?.result, "es?.data?.result");
        //     user?.referrerHierarchy?.map(resu => {
        //         for (let i = 0; i < res.data.result.length; i++) {
        //             if (resu == res.data.result[i]._id)
        //                 setAmount([...amount, res.data.result[i]])
        //         }
        //     })
        // });
    }, [user]);

    const getUserWithLevel =  (users) => {
        if (!users || !users.downlines || !info) {
            
        return [];
        }
    
        const result = [];
        users.downlines.forEach((resu) => {
        const foundUser = info.find((user) => user.email === resu);
        if (foundUser) {
            result.push(foundUser);
        }
        });
    
        console.log(users, result, "users", result.length);
        return result;
    };



    const renderNestedTreeNodes = (data, level) => {
        return data.map((item) => (
          <TreeNode
            label={
              <StyledNode>
                <span className="text-warning">Level {level} </span> <br /> {item?.myReferralCode}
              </StyledNode>
            }
          >
            {getUserWithLevel(item)?.length > 0 && renderNestedTreeNodes(getUserWithLevel(item), level + 1)}
          </TreeNode>
        ));
      };



      
    return (
        <div className="pb-5" style={{ height: "auto" }}>


            <div>
                <h2 className="text-white text-center mt-4 mb-2 page-title">AFFIlIATE CHART</h2>
                {/* <h6 className='text-end text-white container mb-4'><i class="fas fa-project-diagram cursor-pointer"></i></h6> */}
            </div>

            <div className='container mx-auto  text-light' style={{ fontFamily: "Arial" }}>
                <div className='row mt-4'>
                    <div className='col-12 network-chart' style={{overflowX:"auto"}}>
                        <Tree
                            lineWidth={'2px'}
                            lineColor={'green'}
                            lineBorderRadius={'10px'}
                            label={<StyledNode><span className='text-warning'>Main Level</span> <br /> {user?.myReferralCode}</StyledNode>}
                        >

                            {/* <TreeNode label={<StyledNode>Child 1</StyledNode>}>
                                <TreeNode label={<StyledNode>Grand Child</StyledNode>} />
                            </TreeNode> */}
                            {load ?
                                amount?.map(res=><TreeNode label={<StyledNode><span className='text-warning'>Level 1 </span> <br /> {res?.myReferralCode}</StyledNode>}>
                               {getUserWithLevel(res)?.length > 0 && renderNestedTreeNodes(getUserWithLevel(res), 2)}
                                   
                                </TreeNode>) : <Spinner></Spinner>}

                        </Tree>
                    </div>
                </div>
            </div>
            <div className='text-center pt-5'>
                <button className='btn btn-danger' onClick={()=>navigate(-1)}>Back</button>
</div>

        </div>
    );
};

export default NetworkChart;