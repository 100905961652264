import React, { useEffect, useContext } from "react";
import "./PackageDetails.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import swal from "sweetalert";
import { packages } from "./packageData";
import { GalagamesContext } from "../../Context/GalaraceContext";

const PackageDetails = () => {
  const { packNum } = useParams();
  const navigate = useNavigate();

  const [currentPack, setCurrentPack] = useState({});

  const {
    busdBalance,
    getBUSDTokenBalance,
    getApproveTest,
    setRequestLoading,
    requestLoading,
    buyMembership,
    user,
    openWalletModal,
  } = useContext(GalagamesContext);

  useEffect(() => {
    if (packNum) {
      setCurrentPack(packages.find((pack) => pack.num == packNum));
    }
  }, [packNum]);

  const handelMemberShip = async () => {
    let balance = await getBUSDTokenBalance();
  };

  useEffect(() => {
    getBUSDTokenBalance();
    window.scroll(0, 0);
  }, []);

  const handleNavigate = () => {
    // ask in swal You are going to purchase this membership. You need to pay ‘XX’ BUSD. Do you want to proceed? Yes or No
    swal({
      text: `You are going to purchase this membership. You need to pay ${currentPack?.grtWorth} USDT (BEP20). Do you want to proceed?`,
      icon: "warning",
      buttons: ["No", "Yes"],
      className: "modal_class_success",
    }).then((value) => {
      if (value) {
        navigate(`/buy_now/${packNum}`);
      }
    });
  };

  const buyMemberShip = async () => {
    try {
      let busdBalanceNumber = parseInt(busdBalance);
      let MemberShipAmountNumber = parseInt(currentPack?.grtWorth);
      let currentPackValue = String(currentPack.grtWorth);
      console.log(
        "🚀:",
        typeof currentPackValue,
        currentPack?.grtWorth,
        currentPackValue
      );
      // if (busdBalanceNumber > MemberShipAmountNumber) {
      console.log("kaam ho gea");
      setRequestLoading(true);
      let isApproved = await getApproveTest(2, currentPackValue);
      if (isApproved) {
        let result = await buyMembership(packNum, currentPackValue);
        if (result) {
          console.log(result);
          getBUSDTokenBalance();
          setRequestLoading(false);
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `
          <a href=${result} target="_any" className="link_hash">${result}</a>
          <br/> <p>Your MemberShip has been successfully started.</p>`;
          swal({
            title: "Success",
            content: wrapper,
            icon: "success",
            button: true,
            className: "modal_class_success",
          }).then((willDelete) => {
            if (willDelete) {
              navigate("/dashboard");
            } else {
              console.log("ok");
            }
          });
        } else {
          setRequestLoading(false);
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p>User rejected transaction.</p>`;
          swal({
            title: "Failed",
            content: wrapper,
            icon: "warning",
            button: "OK",
            className: "modal_class_success",
          });
        }
      }
      // } else {
      //   const wrapper = document.createElement("div");
      //   wrapper.innerHTML = `<p>Insufficient Funds.</p>`;
      //   swal({
      //     title: "Failed",
      //     content: wrapper,
      //     icon: "warning",
      //     button: "OK",
      //     className: "modal_class_success",
      //   });
      // }
    } catch (error) {
      console.log(
        "🚀 ~ file: PackageDetails.js:100 ~ buyMemberShip ~ error:",
        error
      );
      setRequestLoading(false);
    }
  };

  const loginCheck = () => {
    swal({
      title: "Attention",
      text: `Please Login First`,
      icon: "warning",
      button: "Sign In",
      className: "modal_class_success",
    }).then(function (isConfirm) {
      if (isConfirm) {
        navigate("/sign-in");
      }
    });
  };
  return (
    <div className="">
      <div
        className="py-5"
        style={{
          background: "#202b52",
        }}
      >
        <button
          onClick={() => {
            user?.email
              ? user?.walletAddress
                ? buyMemberShip()
                : openWalletModal()
              : loginCheck();
          }}
          className="buy-now-button"
        >
          BUY NOW!
        </button>
        <div className="sec-title-container">
          <div>
            <h4 className="sec-title1 underline-style1 text-uppercase">
              What you get immediately <br className="package-br" /> for $
              {currentPack?.grtWorth}
            </h4>
            {/* <p className="sec-title1">
              Your USDT (BEP20) Balance{" "}
              {busdBalance == 0 || busdBalance == undefined
                ? "00.00"
                : busdBalance}
            </p> */}
            {/* <hr className="package-hr" /> */}
          </div>
        </div>
        <div className="net-worth-box-container container mx-auto row gy-3">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="each-box box-1">
              <div className="text-uppercase">
                <h4 style={{ color: "#A0C49D" }} className="box-heading">
                GRT NFT
                </h4>
                <p className="box-sub-text">
                  {currentPack?.immediately?.grt}
                  <br />
                  <span className="box-heading">${currentPack?.nftWorth}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="each-box box-3">
              <div className="text-uppercase">
                <h4 style={{ color: "#A0C49D" }} className="box-heading">
                  Welcome Bonus
                </h4>
                <p className="box-sub-text">
                  {currentPack?.immediately?.welcomeBonus}
                  <br />
                  <span className="box-heading">${currentPack?.grtWorth}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sec-title-container mt-5">
          <div>
            <h4 className="sec-title2 underline-style2 text-uppercase">
              {/* What you get daily for ${currentPack?.grtWorth} */}
              YOUR DAILY EARNINGS
            </h4>
            {/* <hr className="package-hr" /> */}
          </div>
        </div>
        <div className="net-worth-box-container container mx-auto row gy-3">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="each-box box-4">
              <div className="text-uppercase">
                <h4 className="box-heading">Daily Staking Bonus</h4>
                <p className="box-sub-text">
                  {currentPack?.daily?.stakeBonus}
                  <br />
                  <span
                    className="box-heading"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    *Auto Stake of NFT
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="each-box box-2">
              <div className="text-uppercase">
                <h4 className="box-heading">Play & Earn</h4>
                <p className="box-sub-text">{currentPack?.daily?.playEarn}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="sec-title-container mt-5">
          <div>
            <h4 className="sec-title3 underline-style3 text-uppercase">
              Other Bonuses
            </h4>
          </div>
        </div>
        <div className="net-worth-box-container container mx-auto row gy-3">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="each-box box-4">
              <div className="text-uppercase">
                <h4 className="box-heading2">Introduction Bonus</h4>
                <p className="box-sub-text2">
                  {currentPack?.other?.introductionBonus}
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12">
            <div className="each-box box-2">
              <div className="text-uppercase">
                <h4 className="box-heading2">Level bonus</h4>
                <p className="box-sub-text2">
                  {currentPack?.other?.levelBonus}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <div className="each-box box-1">
              <div className="text-uppercase">
                <h4 className="box-heading2">Game Income</h4>
                <p className="box-sub-text2">
                  {currentPack?.other?.gameIncome}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
