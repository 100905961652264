import mint from "./MintNFT.json";
import mint2 from "./MintNFT2.json";
import token from "./BEP20.json";
import dsltestnet from "./DSLTestnet.json";
// import dslmainnet from "./DSLMainnet.json";
// import usdsc from "./USDSC.json";
import S39 from "./S39.json";
import Quest from "./Quest.json";
import testStakeTokenVrf from "./StakeTokenVRF.json";
import testStakeToken from "./StakeToken.json";
import testMemberShip from "./memberShip.json";
import testNFTTokenAbi from "./nftTokenAbi.json";

export const mintAddressTestnet = "0x128481da51fAc2b63B47DA9b4373E243bbc0009e";
export const mintABITestnet = mint.abi;

export const mintAddressTestnet2 = "0x306dB735E92849DA511D6278b34F81ffB44069e0";
export const mintABITestnet2 = mint2.abi;
export const contractAddressStakeTokenVRF =
  "0x72610AdC9DAA2A5248d0D7071A68E32958736961"; //!main vrf stake
// "0x37e373E064Add8329cde0409783f3C4e584dc9C3"; //test vrf stake
export const testStakeTokenVrfAbi = testStakeTokenVrf;
export const contractAddressStakeToken =
  "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"; //!token main
// "0x572F9E1824B7EFc5626dA565a154f600De2a6337"; //token test
export const testStakeTokenAbi = testStakeToken;
export const contractAddressMemberShip =
  // "0xcc2A08510C6b7219f346d71059F73Ae027113e65"; //!Main membership
  "0x1fe0a19cCEe5eb9c41275f2DcaE00934fD60A56A"; //!test price membership
// "0x893c9A0cB051beC22f95BA53e66c182A8075c13d"; //test membership
export const memberShipAbi = testMemberShip;
export const contractAddressNftTokenMinting =
  "0x6407698cfB5D8CFF1A30eF7c888b7483BD191F15"; //!main mint
// "0xfCc7E213737680770226465c631674eF23B2582E"; //test mint
export const testMintNftTokenAbi = testNFTTokenAbi;

export const RPC = "https://data-seed-prebsc-1-s1.binance.org:8545";

export const chainId = "97";

export const USDSCtokenAddressTestnet =
  "0x8d671b650248B4BfaF411CB36Cc1C8bD8429F2A3";
export const USDSCtokenABITestnet = token.abi;

export const DSLtokenAddressTestnet =
  "0x203507762cF4B63bFEF530a87497c7F5FDad7b0B";
export const DSLtokenABITestnet = dsltestnet.abi;

// export const USDSCtokenAddressMainnet =
//   "0x13b852e276f10281C72ccF33EdF81d81DD198Aae";
// export const USDSCtokenABIMainnet = usdsc.abi;

// export const DSLtokenAddressMainnet =
//   "0x4A1530Fb85BdB9250Db2bE251584874179eB8Dc5";
// export const DSLtokenABIMainnet = dslmainnet.abi;

export const S39tokenAddressTestnet =
  "0x80aAeA2450F81a67fcEB2dEDABCbC57ebF346Ee7";
export const S39tokenABITestnet = S39.abi;

export const QuesttokenAddressTestnet =
  "0x5C6e946b94b2c79563b1fB2B73bD60bB7bd0Cf5B";
export const QuesttokenABITestnet = Quest.abi;
