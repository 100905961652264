import React from 'react';
import "./BecomePremium.css"

const BecomePremium = () => {
    return (
        <div style={{minHeight:"100vh"}} className='container'>
          <div>
            <h2 style={{paddingInline:"54px"}} className="text-white text-center mt-4 mb-5 page-title">PREMIUM MEMBERSHIP</h2>
          </div>
        </div>
    );
};

export default BecomePremium;